import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  HourglassOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';

import {
  Avatar,
  Button,
  Collapse,
  Col,
  ConfigProvider as ConfigProvider5,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  FloatButton,
  Flex,
  Form,
  Input,
  Layout,
  List,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from 'antd-v5';
import dayjs from 'dayjs';
import React from 'react';
import Auth from '../../Auth';
import { MobileNav } from './Nav';

const { Content, Sider, Footer } = Layout;
const { Text, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [openCrewBuilder, setOpenCrewBuilder] = React.useState(false);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [form] = Form.useForm();
  const [selector] = Form.useForm();

  const [anchorDate, setAnchorDate] = React.useState();
  const [jobId, setJobId] = React.useState();
  const [timeRequests, setTimeRequests] = React.useState([]);
  const [jobs, setJobs] = React.useState([]);
  const [mode, setMode] = React.useState("day");
  const [dateRange, setDateRange] = React.useState([]);
  const [requestTimeStart, setRequestTimeStart] = React.useState();
  const [requestTimeEnd, setRequestTimeEnd] = React.useState();

  const getCurrentJob = (jobId) => jobs.find(j => j.id == jobId);

  var weekItems = [
    {
      key: "Sunday",
      href: '#/app/crew-builder#sunday',
      title: "Sunday",
    },
    {
      key: 'Monday',
      href: '#/app/crew-builder#monday',
      title: 'Monday',
    },
    {
      key: 'Tuesday',
      href: '#/app/crew-builder#tuesday',
      title: 'Tuesday',
    },
    {
      key: "Wednesday",
      href: "#/app/crew-builder#wednesday",
      title: "Wednesday",
    },
    {
      key: "Thursday",
      href: "#/app/crew-builder#thursday",
      title: "Thursday",
    },
    {
      key: "Friday",
      href: "#/app/crew-builder#friday",
      title: "Friday",
    },
    {
      key: "Saturday",
      href: "#/app/crew-builder#saturday",
      title: "Saturday",
    }
  ]

  const computeDaysOfWeek = () => {
    const weekDates = [];
    const startOfWeek = dayjs(anchorDate).startOf("week");
    for (var i = 0; i < 7; i++) {
      weekDates.push([startOfWeek.add(i, "day").startOf("day"), startOfWeek.add(i, "day").endOf("day")]);
    }

    return weekDates;
  }

  const fetchTimeRequests = () => {
    if (!jobId) return;

    setLoading(true);

    const requestAt = dayjs(anchorDate).startOf("week").toISOString();
    const requestEndsAt = dayjs(anchorDate).endOf("week").toISOString();

    fetch(`/api/jobs/${jobId}/production-requests?requestAt=${requestAt}&requestEndsAt=${requestEndsAt}`)
      .then((response) => response.json())
      .then((response) => {
        setTimeRequests(response);
        setLoading(false);
      })
  }

  const fetchJobs = async () => {
    setLoading(true);
    await fetch(`/api/jobs?includeAssignedUsers=true`)
      .then((response) => response.json())
      .then((jobResult) => {
        setJobs(jobResult);
        setLoading(false);
      })
  }

  const onJobSelect = (item) => {
    setJobId(item);
  }

  const deleteTimeRequests = (productionRequestId) => {
    setLoading(true);
    return new Promise((resolve) => {
      fetch(`api/jobs/production-requests/${productionRequestId}`, {
        method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(() => {
          resolve(null);
          setLoading(false);
        })
        .then(() => {
          fetchTimeRequests();
        })
    });
  }

  const onAnchorDateSelect = (value) => {
    setAnchorDate(value);
  }

  const onPreviousWeekSelect = () => {
    const prev = dayjs(anchorDate).subtract(1, mode);
    setAnchorDate(prev);
  }

  const onNextWeekSelect = () => {
    const next = dayjs(anchorDate).add(1, mode);
    setAnchorDate(next);
  }

  const openSelectorDrawer = () => setOpenSelector(true);
  const closeSelectorDrawer = () => setOpenSelector(false);
  const openCrewBuilderDrawer = () => setOpenCrewBuilder(true);
  const closeCrewBuilderDrawer = () => setOpenCrewBuilder(false);

  const onSelectorFormSubmit = async () => {
    fetchTimeRequests();
    closeSelectorDrawer();
  }

  const onFieldsChange = (values) => {
    const dateStart = form.getFieldValue("dateStart");
    const dateEnd = form.getFieldValue("dateEnd");
    if (dateStart && dateEnd) {
      const dates = generateDateRange(dateStart, dateEnd);
      setDateRange(dates);
    }
  }

  const generateDateRange = (dateStart, dateEnd) => {
    const dDateStart = dayjs(dateStart);
    const dDateEnd = dayjs(dateEnd);
    let start = dDateStart;

    let datesList = [dateStart];

    while (start.isBefore(dDateEnd)) {
      start = start.add(1, "day");
      datesList.push(start.format(`YYYY-MM-DD`));
    }
    return datesList;
  }

  const onCrewBuilderFormSubmit = async () => {
    setLoading(true)

    const values = form.getFieldsValue();
    const { users, job } = values;
    const requestedById = Auth.getCurrentUser()?.id;

    const requests = [];

    for (const date of dateRange) {
      for (const user of users) {
        requests.push({
          userId: user,
          requestAt: dayjs(`${date} ${requestTimeStart}`).toISOString(),
          requestEndsAt: dayjs(`${date} ${requestTimeEnd}`).toISOString(),
          requestedById,
          jobId: job,
        })
      }
    }

    async function processRequest(request) {
      await fetch(`api/jobs/${jobId}/production-requests`, {
        method: 'POST', body: JSON.stringify(request), mode: 'cors', headers: { 'Content-Type': 'application/json' }
      }).then(response => response.json());
    }

    async function processPromisesBatch(
      items,
      limit,
      fn,
    ) {
      let results = [];
      for (let start = 0; start < items.length; start += limit) {
        const end = start + limit > items.length ? items.length : start + limit;

        const slicedResults = await Promise.all(items.slice(start, end).map(fn));

        results = [
          ...results,
          ...slicedResults,
        ]
      }

      return results;
    }

    await processPromisesBatch(requests, 3, processRequest)

    closeCrewBuilderDrawer();
    setLoading(false);
    fetchTimeRequests();
  }

  const onModeChange = (value) => {
    setMode(value);
  }

  React.useEffect(() => {
    fetchTimeRequests()
    fetchJobs()
    if (!anchorDate || !jobId) openSelectorDrawer()
  }, [anchorDate, jobId, mode]);

  return (
    <ConfigProvider5 prefixCls="ant5">

      <Spin spinning={loading} fullscreen={true} />

      <Content style={{
        marginTop: 16,
        maxWidth: 800,
        minWidth: "90vw"
      }}>
        {
          (jobId && anchorDate) &&
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <Text type="secondary">Job</Text>
                <Title level={4} style={{ marginTop: 8 }}>{jobId ? `${getCurrentJob(jobId)?.name} - ${getCurrentJob(jobId)?.description}` : ""}</Title>
              </Col>
            </Row>
            <Segmented
              block
              size="large"
              value={mode}
              options={[
                { label: 'Daily', value: 'day', disabled: false },
                { label: 'Weekly', value: 'week', disabled: false },
              ]}
              onChange={onModeChange}
            />
            {mode == "day" &&
              <div style={{ marginTop: 24 }}>
                <Segmented

                  block
                  value={anchorDate.toISOString()}
                  onChange={value => {
                    setAnchorDate(dayjs(value))
                  }}
                  options={
                    computeDaysOfWeek()?.map(d => {
                      return {
                        label: (
                          <div style={{ margin: "6px 0px" }}>
                            <Avatar style={{ backgroundColor: timeRequests?.find(p => dayjs(p.requestAt).format("YYYY-MM-DD") == d[0].format("YYYY-MM-DD") && !p.productionId) ? '#f56a00' : '#87d068' }}>{d[0].format("dd")}</Avatar>
                            <div>
                              {d[0].format("MM-DD")}
                            </div>

                          </div>
                        ),
                        value: d[0].toISOString(),
                      }
                    })
                  }
                />
              </div>
            }
          </div>
        }
        <Layout
          className="site-layout-background"
          style={{
            borderRadius: 8,
            marginTop: 16,
          }}

        >

          <Content
            style={{
              padding: '12px 12px',
              minHeight: "100vh"
            }}
          >
            {
              (jobId && anchorDate) &&
              <div>
                <Flex justify="space-between" align="flex-end" style={{ marginBottom: 24 }}>
                  <Button type="primary" shape="round" onClick={onPreviousWeekSelect} icon={<ArrowLeftOutlined />} style={{ display: 'flex' }}>
                    Prev {mode.charAt(0).toUpperCase() + mode.slice(1)}
                  </Button>


                  <Button type="primary" shape="round" onClick={onNextWeekSelect} icon={<ArrowRightOutlined />} iconPosition="end">
                    Next {mode.charAt(0).toUpperCase() + mode.slice(1)}
                  </Button>
                </Flex>
                <div style={{ textAlign: "center", marginBottom: 48 }}>
                  <Text type="secondary">{mode.charAt(0).toUpperCase() + mode.slice(1)}</Text>
                  <Title level={4} style={{ marginTop: 8 }}>{
                    anchorDate && mode == "week"
                      ? `${dayjs(anchorDate).startOf(mode).format("MMMM D")} to ${dayjs(anchorDate).endOf(mode).format("MMMM D")}`
                      : dayjs(anchorDate).startOf(mode).format("MMMM D")}
                  </Title>
                </div>
              </div>
            }
            {(!loading && !timeRequests.length && jobId) &&
              <Empty description={"No Crew Schedule Found"}>
                <Button type="primary" onClick={openCrewBuilderDrawer}>Add Crew Members</Button>
              </Empty>
            }

            {(!loading && !timeRequests.length && !jobId) &&
              <Empty description={<Button type="primary" onClick={openSelectorDrawer}>Select Job & Week</Button>}>
              </Empty>
            }

            {!loading && timeRequests.length > 0 && weekItems.map(dayOfWeek => {
              if (mode == "day" && anchorDate && dayOfWeek.title != anchorDate.format("dddd")) return;
              return (
                <>

                  <Divider>
                    {dayOfWeek.title}
                  </Divider>

                  <List
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={timeRequests.filter(x => dayjs(x.requestAt).format("dddd") == dayOfWeek.title)}
                    renderItem={(request) => {
                      const scheduleText = `${dayjs(request.requestAt).format("hh:mm a")} ${request.requestEndsAt ? "to" : ""} ${request.requestEndsAt ? dayjs(request.requestEndsAt).format("HH:mm a") : ""}`
                      return (
                        <List.Item
                          actions={[
                            <Popconfirm
                              title="Delete Time Request"
                              description="Are you sure to delete this time request?"
                              onConfirm={() => deleteTimeRequests(request.id)}
                              onCancel={() => console.log("Cancel")}
                              okText="Yes"
                              cancelText="No"
                              placement="bottomRight"
                            >
                              <Button type="primary" shape="circle" danger icon={<CloseOutlined />} size="small"></Button>
                            </Popconfirm>]
                          }
                        >
                          <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  icon={request.productionId ? <CheckOutlined /> : <HourglassOutlined />}
                                  style={request.productionId ? {
                                    backgroundColor: "#87d068"
                                  } : {}}
                                  onClick={() => window.location.href = `#/app/time-record-form?productionRequestId=${request.id}&jobId=${request.job.id}&userId=${request.user.id}&producedAt=${encodeURIComponent(request.requestAt)}&producedEndsAt=${encodeURIComponent(request.requestEndsAt)}`}
                                />
                              }
                              title={request.user.name}
                              description={
                                scheduleText
                              }
                            />
                          </Skeleton>
                        </List.Item>
                      )
                    }}
                  />

                </>
              )
            })}

          </Content>

        </Layout>

        <Drawer
          title="Build Crew"
          placement="right"
          onClose={closeCrewBuilderDrawer}
          open={openCrewBuilder}
        >
          <Form
            form={form}
            onFinish={onCrewBuilderFormSubmit}
            layout="vertical"
            onFieldsChange={onFieldsChange}
          >
            <Form.Item
              name="job"
              label="Job"
              required
            >
              <Select
                style={{
                  width: "100%"
                }}
                onChange={onJobSelect}
                showSearch
                placeholder="Select a Job"
                optionFilterProp="children"
              >
                {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="requestTimes"
              label="Shift Time"
              required
              style={{ marginBottom: 0 }}
            >
              <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  name="timeStart"

                >
                  <Input
                    type="time"
                    required
                    onChange={(e) => setRequestTimeStart(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="timeEnd"
                  rules={[
                    {
                      required: true,
                      message: 'Please select end range!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {

                        const timeStartValue = getFieldValue("timeStart");


                        if (timeStartValue >= value) return Promise.reject("End time must be after start time!");

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="time"
                    required
                    onChange={(e) => setRequestTimeEnd(e.target.value)}
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item
              name="requestDates"
              label="Dates"
              required
            >
              <Space style={{ display: 'flex' }} align="baseline">
                <Form.Item
                  name="dateStart"
                  style={{ marginBottom: 12 }}

                  rules={[
                    {
                      required: true,
                      message: 'Please select start range!',
                    },
                  ]}
                >
                  <Input
                    type="date"
                    min={dayjs().format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const dateStartValue = e.target.value;
                      const dateEndValue = form.getFieldValue("dateEnd");

                      if (!dateEndValue) form.setFieldValue("dateEnd", e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="dateEnd"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select end range!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {

                        const dateStartValue = getFieldValue("dateStart");

                        const dDateStart = dayjs(dateStartValue);
                        const dDateEnd = dayjs(value);

                        if (dDateEnd.isBefore(dDateStart)) return Promise.reject("End range must be after start date!");

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="date"
                    min={dayjs().format("YYYY-MM-DD")}
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            {
              (dateRange.length > 0 && requestTimeStart && requestTimeEnd) && <Collapse
                size="small"
                items={[{
                  key: "preview",
                  label: "Preview",
                  children: <List
                    size="small"
                    dataSource={dateRange}
                    renderItem={(item) =>
                      <List.Item>
                        {dayjs(item).format("dddd")}, {item} @ {requestTimeStart} - {requestTimeEnd}
                      </List.Item>}
                  />
                }]}
                style={{ marginBottom: 24 }}
              />
            }


            <Form.Item
              name="users"
              label="Crew Members"
              required
            >
              <Select
                mode="multiple"
                placeholder="Crew Members"
                style={{
                  flex: 1,
                }}
              >
                {getCurrentJob(jobId)?.userAssignments.map((u) => <Option value={u.user.id} key={u.user.id} > {u.user.name} </Option>)}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Drawer
          title="Job Schedule"
          placement="right"
          onClose={closeSelectorDrawer}
          open={openSelector}
        >
          <Form
            form={selector}
            onFinish={onSelectorFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="job"
              label="Job"
              required
            >
              <Select
                style={{
                  width: "100%"
                }}
                onChange={onJobSelect}
                showSearch
                placeholder="Select a Job"
                optionFilterProp="children"
              >
                {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="week"
              label="Week"
              required
            >
              <DatePicker
                style={{
                  width: "100%"
                }}
                onChange={onAnchorDateSelect}
                picker="week" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <FloatButton.Group size="large" shape="square" style={{ right: 24, bottom: 120 }} >
          <FloatButton icon={<SyncOutlined />} onClick={openSelectorDrawer} />
          <FloatButton icon={<UsergroupAddOutlined />} onClick={openCrewBuilderDrawer} />
          <FloatButton.BackTop visibilityHeight={300} />
        </FloatButton.Group>
      </Content >
      <MobileNav />
    </ConfigProvider5 >
  )
}

export default App;
import React from 'react';
import { NavLink } from "react-router-dom";
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, DatePicker, Menu, Dropdown, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import exportFromJSON from 'export-from-json';
import moment from 'moment'
import Auth from '../../Auth';

const { RangePicker } = DatePicker;
const { Option } = Select;

export class EquipmentUsageReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        equipmentUsage: [],
        error: null
    };

    componentDidMount() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        fetch(`api/equipment?includeEquipmentUsage=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then(response => response.json())
            .then(
                (result) => {
                    let equipmentUsage = result.map(x => x.equipmentUsage).flat(1);

                    equipmentUsage = equipmentUsage.map(x => {
                        return {
                            ...x,
                            equipment: `${x.equipment?.name} - ${x.equipment?.description}`,
                            usedAt: x.production?.producedAt,
                            category: x.equipment?.equipmentCategory?.name,
                            user: x.production?.user?.name,
                            job: `${x.production?.job?.name} - ${x.production?.job?.description}`,
                            costCode: `${x.production?.costCode?.name} - ${x.production?.costCode?.description}`
                        }
                    })

                    console.log("XXX", equipmentUsage);

                    this.setState({
                        equipmentUsage: equipmentUsage,
                    });

                    console.log(equipmentUsage)

                    setLoading(false);
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        setSelectedKeys([e])
                        console.log("Selected Key", selectedKeys)
                    }
                    }
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [

            {
                title: 'Equipment',
                dataIndex: 'equipment',
                key: 'equipment',
                width: '20%',
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('equipment'),
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                ...this.getColumnSearchProps('category'),
            },
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('user'),
            },
            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('job'),
            },
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                key: 'costCode',
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Hours Used',
                dataIndex: 'hoursUsed',
                key: 'hoursUsed',
                ...this.getColumnSearchProps('hoursUsed'),
            },
            {
                title: 'Liters of Fuel',
                dataIndex: 'litersOfFuel',
                key: 'litersOfFuel',
                ...this.getColumnSearchProps('litersOfFuel'),
            },
            {
                title: 'Date',
                dataIndex: 'usedAt',
                key: 'usedAt',
                sorter: (a, b) => ('' + a.usedAt).localeCompare(b.usedAt),
                sortDirections: ['descend', 'ascend'],
                ...this.getDateColumnSearchProps('usedAt'),
                render: usedAt => moment(usedAt).format("YYYY-MM-DD"),
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                ...this.getColumnSearchProps('notes'),
            },
        ];
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Equipment Usage Report</Breadcrumb.Item>
                </Breadcrumb>

                <Table rowKey={record => `equipment-usage-${record.id}`}
                    columns={columns}
                    scroll={{ x: 1300 }}
                    dataSource={this.state.equipmentUsage}
                    pagination={{
                        defaultPageSize: 50,
                        pageSizeOptions: [50, 100, 500, 1000]
                    }}
                    footer={currentPageData => {

                        let data = this.state.equipmentUsage;

                        function handleMenuClick(e) {
                            if (e.key.includes("filtered")) {
                                data = currentPageData;
                            }

                            let fileType = exportFromJSON.types.txt;

                            switch (e.key) {
                                case "csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                                case "filtered-csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "filtered-xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "filtered-json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "filtered-txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                            }

                            var exportData = data.map(({ key, ...items }) => items);

                            //     equipment: `${x.equipment?.name} - ${x.equipment?.description}`,
                            //     usedAt: x.production?.producedAt,
                            //     user: x.production?.user?.name,
                            //     job: `${x.production?.costCode?.job?.name} - ${x.production?.costCode?.job?.description}`,
                            //     costCode: `${x.production?.costCode?.name} - ${x.production?.costCode?.description}`
                            // }

                            exportFromJSON({
                                data: exportData.map(x => {
                                    return {
                                        Date: moment(x.usedAt).format("YYYY-MM-DD"),
                                        Job: x.job,
                                        CostCode: x.costCode,
                                        Equipment: x.equipment,
                                        HoursUsed: x.hoursUsed,
                                        LitersOfFuel: x.litersOfFuel,
                                        Notes: x.notes ?? "",
                                        User: x.user,
                                    }
                                }),

                                fileName: "equipment-usage-report",
                                exportType: fileType
                            });
                        }

                        const menu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                <Menu.Item key="json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        const filteredMenu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        return (
                            <Space size="small">
                                <Dropdown overlay={menu}>
                                    <Button type="primary" ghost={true}>Download All</Button>
                                </Dropdown>
                                <Dropdown overlay={filteredMenu}>
                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                </Dropdown>
                            </Space>
                        );

                    }}
                />
            </div>
        );
    }
}
import React from 'react';
import { Layout, Menu } from 'antd-v5';
import Auth from '../../Auth';
import { NavLink } from "react-router-dom";
const { Footer } = Layout;


const items = [
  {
    label: (
      <a href="#/app/my-schedule" rel="noopener noreferrer">
        My Schedule
      </a>
    ),
    key: 'my-records',
  },
  {
    label: (
      <a href="#/app/my-records" rel="noopener noreferrer">
        My Records
      </a>
    ),
    key: 'my-schedule',
  },
  {
    label: (
      <a href="#/app/crew-builder" rel="noopener noreferrer">
        Crew Builder
      </a>
    ),
    key: 'crew-builder',
  },
]

export const MobileNav = () => {
  return (
    <Footer style={{ textAlign: 'center', bottom: 0, marginLeft: -16, marginRight: -16, position: "sticky", padding: 0 }}>
      <Menu size="large" mode="horizontal" theme="dark" >
        {
          Auth.hasPermission('web.crew_builder') &&
          <Menu.Item key="/app/crew-builder">
            <NavLink to="/app/crew-builder">
              Crew Builder
            </NavLink>
          </Menu.Item>
        }
        {
          Auth.hasPermission('web.timecards') &&
          <Menu.Item key="/app/my-schedule">
            <NavLink to="/app/my-schedule">
              My Schedule
            </NavLink>
          </Menu.Item>
        }
        {
          Auth.hasPermission('web.timecards') &&
          <Menu.Item key="/app/my-records">
            <NavLink to="/app/my-records">
              My Records
            </NavLink>
          </Menu.Item>
        }
      </Menu>
    </Footer>
  )
}
import moment from 'moment'

const EMPTY = ""
const ZERO = "0"

const SPACE = " "

const formatString = (stringValue, size) => {
    try {
        stringValue = stringValue ? String(stringValue) : ""
        let formatted = stringValue.trim()          // Trim Trailing Whitespaces
        formatted = formatted.substring(0, size)    // Truncate String
        formatted = formatted.padEnd(size, SPACE)   // Add Spaces to End of String
        return formatted
    }
    catch {
        return EMPTY
    }
}

const formatNumber = (numberValue, size) => {
    try {
        let formatted = String(numberValue)
        formatted = formatted.trim(formatted)
        formatted = formatted.substring(0, size)
        formatted = formatted.padStart(size, ZERO)
        return formatted
    }
    catch {
        return ZERO
    }
}

const PayrollFormatter = {
    getHeader: (jobHeader = "Admin", date = "YYMMDD", isTimeRecord = false) => {
        const A = "H"
        const B = formatString(EMPTY, 43)
        const C = formatString("BW", 2)
        const D = formatString(jobHeader, 9)
        const E = formatString(date, 6)
        const F = formatNumber(ZERO, 6)
        const G = formatString("H", 1)
        // const H = formatString(h, 1)

        const H = formatString(isTimeRecord ? "Y" : "N", 1)
        const I = formatString(EMPTY, 3)
        const J = formatString(EMPTY, 3)

        const header = A + B + C + D + E + F + G + H + I + J

        const HeaderLength = 75

        if (HeaderLength != header.length) console.error(`Header: Length mismatched, expected ${HeaderLength} - got ${header.length} instead`)
        else console.log("Header: Formatted successfully!")

        return header
    },

    getTimecard: (timeRecord) => {
        console.log("CC", timeRecord.costCode)
        const isTravelTime = timeRecord.costCode.toLowerCase() == "travel"

        let travelTimeHours = isTravelTime ? timeRecord.hours : ZERO
        let regularTimeHours = isTravelTime ? ZERO : timeRecord.hours

        console.log(travelTimeHours, regularTimeHours)

        travelTimeHours = travelTimeHours * 100
        regularTimeHours = regularTimeHours * 100

        const A = "T" // T
        const B = formatString(timeRecord.user?.employeeCode, 9) // Employee code
        const C = formatString(timeRecord.user?.name, 30) // Employee Name

        const D = moment(timeRecord.producedAt).format("YYMMDD") // Timecard Date

        const E = formatString(EMPTY, 6) // Unused

        const F = formatNumber(regularTimeHours, 6) // Regular Time
        const G = formatNumber(ZERO, 6) // Time and a Half
        const H = formatNumber(ZERO, 6) // Double Time
        const I = formatNumber(ZERO, 6) // Service Time
        const J = formatNumber(travelTimeHours, 6) // Travel Time

        const K = formatString(EMPTY, 1)
        const L = formatString(EMPTY, 9)
        const M = formatString(EMPTY, 3)
        const N = formatString(timeRecord.jobObj?.name, 9)
        const O = formatString(timeRecord.jobObj?.description, 30)
        const P = formatString(timeRecord.costCodeObj?.name, 20)
        const Q = formatString(timeRecord.costCodeObj?.description, 30)

        const R = formatString(timeRecord.productionEquipments[0]?.equipment?.name, 9) // Equipment Number
        const S = formatString(EMPTY, 20)
        const T = formatString(EMPTY, 2)
        const U = formatString(EMPTY, 7)
        const V = formatString(timeRecord.user?.workClass, 9)

        let W = formatString("NON", 9)
        if (timeRecord.user?.union) W = formatString(timeRecord.user?.union, 9); // Union
        
        const X = formatString(EMPTY, 9) // Repair Location

        const Y = formatNumber(ZERO, 13)
        // const Y = formatString(timeRecord.user?.payRate * 100, 13) 

        const Z = formatString(EMPTY, 4)

        const AA = formatNumber(ZERO, 6)
        const AB = formatNumber(ZERO, 6)
        const AC = formatNumber(ZERO, 6)
        const AD = formatNumber(ZERO, 6)
        const AE = formatNumber(ZERO, 6)

        const AF = formatString(EMPTY, 7)
        const AG = formatString(EMPTY, 50)
        const AH = formatString(EMPTY, 30)
        const AI = formatString(EMPTY, 12)
        const AJ = formatString(EMPTY, 50)

        const AK = formatNumber(ZERO, 6)
        const AL = formatNumber(ZERO, 13)

        const AM = formatString(EMPTY, 5)

        const result = A + B + C + D + E + F + G + H + I + J + K + L + M + N + O + P + Q +
            R + S + T + U + V + W + X + Y + Z + AA + AB + AC + AD + AE + AF + AG +
            AH + AI + AJ + AK + AL + AM

        const TimecardLength = 469

        if (result.length != TimecardLength) console.error(`Timecard: Length mismatched, expected ${TimecardLength} - got ${result.length} instead`)
        else console.log("Timecard: Formatted successfully!")

        return result.toString()
    },

    getEquipment: (equipmentUsage, isBio = false) => {
        // if the job is shop
        // cost code is mobilization
        // checked mobilization
        // save that equipment code
        // whenb downloading report 
        // column M should use the equipment code for the one that has a checked mobilization

        const A = "E"
        const B = formatString(equipmentUsage.equipment?.name, 9)
        const C = formatString(equipmentUsage.equipment?.description, 30)
        const D = moment(equipmentUsage.production?.producedAt).format("YYMMDD")

        const hoursUsed = equipmentUsage.hoursUsed * 100

        const E = formatNumber(hoursUsed, 6)
        const F = formatNumber(ZERO, 6)
        const G = formatNumber(ZERO, 6)
        const H = formatString(EMPTY, 1)
        let I = formatString(equipmentUsage.production?.jobObj?.name, 9)

        if (isBio) {
            if (equipmentUsage.equipment?.isRental) {
                I = formatString("BIO2", 9)
            }
        }

        const J = formatString(equipmentUsage.production?.jobObj?.description, 30)
        const K = formatString(equipmentUsage.production?.costCodeObj?.name, 20)
        const L = formatString(equipmentUsage.production?.costCodeObj?.description, 30)
        const M = formatString(equipmentUsage.equipment?.name, 9)

        const N = formatString(EMPTY, 20)
        const O = formatString(EMPTY, 2)
        const P = formatString(EMPTY, 7)
        const Q = formatString(EMPTY, 9)
        const R = formatString(EMPTY, 1)

        const S = formatNumber(ZERO, 6)
        const T = formatNumber(ZERO, 6)
        const U = formatNumber(ZERO, 6)
        const V = formatNumber(ZERO, 6)
        const W = formatNumber(ZERO, 6)
        const X = formatNumber(ZERO, 6)
        const Y = formatNumber(ZERO, 6)

        const Z = formatString(equipmentUsage.production?.user?.employeeCode, 9)
        const AA = formatString(EMPTY, 9)

        const AB = formatNumber(ZERO, 6)
        const AC = formatNumber(ZERO, 6)
        const AD = formatNumber(ZERO, 6)

        const AE = formatString(EMPTY, 7)
        const AF = formatString(EMPTY, 50)
        const AG = formatString(EMPTY, 12)
        const AH = formatString(EMPTY, 50)

        // const TBD = formatString(EMPTY, 236)
        const TBD = N + O + P + Q + R + S + T + U + V + W + X + Y + Z + AA + AB +
            AC + AD + AE + AF + AG + AH

        const result = A + B + C + D + E + F + G + H + I + J + K + L + M + TBD

        const EquipmentUsageRowLength = 399

        if (EquipmentUsageRowLength != result.length) console.error(`Equipment Usage: Length mismatched, expected ${EquipmentUsageRowLength} - got ${result.length} instead`)
        else console.log("Equipment Usage: Formatted successfully!")

        return result.toString()
    },

    getFooter: () => {
        const Z = "Z"
        return Z.toString()
    }
}

export default PayrollFormatter
import React from 'react';
import { NavLink } from "react-router-dom";
import { SearchOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Input, Space, Table, Descriptions, Divider, Modal } from 'antd';
import Highlighter from 'react-highlight-words';

export class ListJobEquipment extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        isLoaded: false,
        error: null
    };

    componentDidMount() {
        console.log(this.props.equipment)
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        var jobCostCodeTotals = [];

        const columns = [
            {
                title: 'Equipment',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (row, data) => {
                    console.log(row, data)
                    const setLoading = this.props.setLoading;

                    return (<Space size="small" wrap={true}>
                        <Button size="small" danger type="primary" onClick={() => {
                            console.log("DATA", data)

                            Modal.confirm({
                                title: 'Confirm',
                                icon: <ExclamationCircleOutlined />,
                                content: 'Are you sure you want to remove this equipment from the job?',
                                okText: 'Remove',
                                cancelText: 'Cancel',
                                onOk: e => {
                                    setLoading(true)

                                    fetch(`api/jobs/${this.props.jobId}/equipment/${data.id}`, {
                                        method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                                    })
                                        .then(response => response.json())
                                        .then(operation => {
                                            console.log(operation);
                                            if (operation) {
                                                Modal.success({
                                                    content: 'Equipment utilization has been deleted.',
                                                });
                                                window.location.reload(false);
                                            }

                                            setLoading(false);
                                        })
                                }
                            })


                        }}>
                            Delete
                        </Button>
                    </Space>)
                }

            }
        ];


        return (
            <>
                <Table rowKey={record => `job-${record.id}`} columns={columns} dataSource={this.props.equipment} pagination={{ position: ["bottomLeft"] }} />
            </>
        );
    }
}
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment';
import { Breadcrumb, Button, Form, Input, InputNumber, Layout, Modal, Select, Card, Divider, Alert, Typography, Switch, DatePicker } from 'antd';
import Auth from '../../Auth';
const { Option } = Select;

const { Text, Link } = Typography;

const employmentTypes = [
    {
        id: "Full Time",
        name: "Full Time"
    },
    {
        id: "Part Time",
        name: "Part Time"
    },
    {
        id: "N/A",
        name: "N/A"
    },
]

const payTypes = [
    {
        id: "Salary",
        name: "Salary"
    },
    {
        id: "Hourly",
        name: "Hourly"
    },
    {
        id: "N/A",
        name: "N/A"
    },
]


const carTypes = [
    {
        id: "Own Car",
        name: "Own Car"
    },
    {
        id: "Timbro's Car",
        name: "Timbro's Car"
    },
    {
        id: "N/A",
        name: "N/A"
    },
]

const workClasses = [
    {
        "name": "ADMIN",
        "description": "Administration & Office Employ"
    },
    {
        "name": "CARPENTER",
        "description": "Carpenter"
    },
    {
        "name": "DRIVER",
        "description": "Driver"
    },
    {
        "name": "FLAG",
        "description": "Flagger"
    },
    {
        "name": "FOREMAN",
        "description": "Site Foreman"
    },
    {
        "name": "GRADE",
        "description": "Grademan"
    },
    {
        "name": "INVENTORY",
        "description": "Inventory Control"
    },
    {
        "name": "LABOUR",
        "description": "Site Labour"
    },
    {
        "name": "MECH",
        "description": "Mechanics"
    },
    {
        "name": "OPERATORS",
        "description": "Equipment Operators"
    },
    {
        "name": "PIPE",
        "description": "Pipe Layer"
    },
    {
        "name": "PM",
        "description": "Project Manager"
    },
    {
        "name": "QC",
        "description": "Quality Control"
    },
    {
        "name": "SAFETY",
        "description": "Safety Officer"
    },
    {
        "name": "SCALE",
        "description": "Scale Operator"
    },
    {
        "name": "SUPER",
        "description": "Superintendant"
    },
    {
        "name": "Survey",
        "description": "Surveyor"
    },
    {
        "name": "WELDER",
        "description": "Welder"
    }
]

export const FormUser = ({ setLoading }) => {
    const [form] = Form.useForm();
    const uriParams = useParams();
    const history = useHistory();
    const [user, setUser] = useState({});
    const [formLayout, setFormLayout] = useState('vertical');
    const [roles, setRoles] = useState([]);
    const [confirmDirty, setConfirmDirty] = useState();
    const [error, setError] = useState("");
    const [workTypes, setWorkTypes] = useState([])
    const [availableManagers, setAvailableManagers] = useState([]);
    const [selectedManagers, setSelectedManagers] = useState([]);
    const [defaultManagers, setDefaultManagers] = useState([]);
    const [isAvailableManagersLoading, setIsAvailableManagersLoading] = useState(false);

    const [isActive, setIsActive] = useState(true);
    const [isOnPayroll, setIsOnPayroll] = useState(false);
    const [isValidating, setIsValidating] = useState("");
    const [users, setUsers] = useState([]);

    const isEditMode = uriParams?.id != null && uriParams.id > 0;

    const fetchUser = async () => {

        setLoading(true)

        await fetch('api/work-types')
            .then(response => response.json())
            .then(result => {
                setWorkTypes(result)

                setLoading(false)
            })


        if (!isEditMode) {
            return;
        }

        setLoading(true)

        await fetch('api/roles')
            .then(response => response.json())
            .then(result => {
                setRoles(result)
                setLoading(false)
            });

        setLoading(true)

        await fetch(`/api/users/${uriParams.id}?includeManagers=true`)
            .then((response) => response.json())
            .then((user) => {

                user.confirmPassword = user.password;
                user.hiredAt = user.hiredAt ? moment(user.hiredAt) : null;
                user.birthdayAt = user.birthdayAt ? moment(user.birthdayAt) : null;
                setIsActive(user.isActive)
                setIsOnPayroll(user.isOnPayroll)
                console.log({ user });
                setUser(user);


                fetch(`/api/users?companyId=${Auth.getCurrentUser().companyId}`)
                    .then((response) => response.json())
                    .then((users) => {
                        console.log(users)
                        setIsAvailableManagersLoading(false);
                        var mappedUsers = users.map(x => {
                            return {
                                label: `${x.name} (${x.employeeCode})`,
                                value: x.id
                            }
                        });

                        setAvailableManagers(mappedUsers);
                        console.log(user.managers?.map(x => x.id))
                        setSelectedManagers(user.managers?.map(x => x.id));
                        form.setFieldsValue({ managerIds: user.managers?.map(x => x.id), ...user });
                        // // form.setField

                    })
                    .catch(error => {

                    });


                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });

    }

    const validateEmployeeCode = (e) => {
        console.log(`Validating Name: ${e?.target?.value ?? e} | ${user.employeeCode}`)
        if ((e?.target?.value ?? e) == user.employeeCode && isEditMode) {
            setIsValidating('success');
            return;
        }

        setIsValidating('validating')
        fetch(`/api/users?employeeCode=${e.target.value}&companyId=${Auth.getCurrentUser().companyId}`)
            .then((response) => response.json())
            .then((existing) => {
                if (existing.length > 0) setIsValidating('error');
                else setIsValidating('success')
            })
            .catch(error => {
                setIsValidating('error');
            });;
    }

    const getUsers = () => {
        fetch(`/api/users?companyId=${Auth.getCurrentUser().companyId}`)
            .then((response) => response.json())
            .then((users) => {
                console.log(users)
                setIsAvailableManagersLoading(false);
                var mappedUsers = users.map(x => {
                    return {
                        label: `${x.name} (${x.employeeCode})`,
                        value: x.id
                    }
                });

                setAvailableManagers(mappedUsers);
                // console.log(user.managers?.map(x => x.id))
                // setSelectedManagers(user.managers?.map(x => x.id));
                // form.setFieldsValue({ managerIds: user.managers?.map(x => x.id), ...user });
                // // form.setField

            })
            .catch(error => {

            });;
    }

    const onFinish = (values) => {

        setLoading(true)

        values.isActive = isActive;
        values.isOnPayroll = isOnPayroll;
        console.log("onFinish", values)

        if (isEditMode) {

            fetch(`api/users/${uriParams.id}`, {
                method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'User has been updated successfully.',
                    });

                    setLoading(false)
                    history.push('/users');
                })
                .catch(error => {
                    console.log(error)
                    Modal.error({
                        content: <>
                            Something went wrong.
                        </>,
                    });
                    setLoading(false)
                });

            return;
        }

        values.companyId = Auth.getCurrentUser()?.companyId

        fetch('api/users', {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'User has been added successfully.',
                });

                setLoading(false)
                history.push('/users');
            })
            .catch(error => {
                console.log(error)
                Modal.error({
                    content: <>
                        Something went wrong.
                    </>,
                });
                setLoading(false)
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const fetchRoles = () => {
        setLoading(true)
        fetch('api/roles')
            .then(response => response.json())
            .then(result => {
                setRoles(result)
                setLoading(false)
            })
    }

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    useEffect(() => {
        fetchUser();
        fetchRoles();

        if (isEditMode) {
            const employeeCode = user.employeeCode;
            console.log("Name", employeeCode)
            validateEmployeeCode(employeeCode);
        }

        if (!isEditMode) {
            getUsers();
        }
    }, []);

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value)
    };


    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();

    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Password did not match.');
        } else {
            callback();
        }
    };

    const handleManagerSelect = (value) => {
        setSelectedManagers(value);
        console.log(selectedManagers);
    }

    return (

        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Users</Breadcrumb.Item>

                <Breadcrumb.Item>{isEditMode ? `Edit ${user?.id ?? ""}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            {error && <Alert
                message={error}
                type="error"
                closable
                banner
                style={{ maxWidth: 500 }}
            />}
            <Card style={{ maxWidth: 500 }}>


                <Form
                    form={form}
                    name='FormUser'
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label='Employee Name'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 30
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Employee Code'
                        name='employeeCode'
                        onBlur={validateEmployeeCode}
                        validateStatus={isValidating}
                        help={isValidating == 'error' ? "Please enter a unique value." : null}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 9,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Employment Type'
                        name='employmentType'
                        initialValue={null}
                        rules={[
                            // {
                            //     required: false,
                            //     message: 'This field is required.'
                            // },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an Employment Type"
                            optionFilterProp="children"
                        >
                            {employmentTypes?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Pay Type'
                        name='payType'
                        initialValue={null}
                        rules={[
                            // {
                            //     required: false,
                            //     message: 'This field is required.'
                            // },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Pay Type"
                            optionFilterProp="children"
                        >
                            {payTypes?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Pay Rate'
                        name='payRate'
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'This field is required.',
                            // },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='Type of Work'
                        name='workTypeId'
                        initialValue={null}
                        rules={[
                            // {
                            //     required: false,
                            //     message: 'This field is required.'
                            // },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Work Type"
                            optionFilterProp="children"
                        >
                            {workTypes?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Work Class'
                        name='workClass'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Work Class"
                            optionFilterProp="children"
                        >
                            {workClasses?.map((c) => <Option value={c.name} key={c.name} > {c.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Union'
                        name='union'
                        rules={[
                            {
                                max: 9,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Car Type'
                        name='carType'
                        initialValue={null}
                        rules={[
                            // {
                            //     required: false,
                            //     message: 'This field is required.'
                            // },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Car Type"
                            optionFilterProp="children"
                        >
                            {carTypes?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Mobile'
                        name='mobile'
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'This field is required.',
                            // },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: 'Please input a valid email.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Address'
                        name='address'
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'This field is required.',
                            // },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Birthday'
                        name='birthdayAt'
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label='Hiring Date'
                        name='hiredAt'
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label='Emergency Contact'
                        name='emergencyContact'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Health'
                        name='health'
                    >
                        <Input />
                    </Form.Item>

                    <Divider orientation="left">Credentials</Divider>
                    <Form.Item
                        label='Username'
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                min: 5,
                                message: 'Value must not be less than 5 characters.'
                            },
                            {
                                max: 24,
                                message: 'Value must not exceed 24 characters.'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        hasFeedback
                        name='password'
                        rules={[
                            {
                                required: !isEditMode,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message: 'Value must not be less than 5 characters.'
                            },
                            {
                                max: 24,
                                message: 'Value must not exceed 24 characters.'
                            }
                        ]} >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        hasFeedback
                        dependencies={['password']}
                        name='confirmPassword'
                        rules={[
                            {
                                required: isEditMode ? false : true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]} >
                        <Input.Password onBlur={handleConfirmBlur} />
                    </Form.Item>

                    <Form.Item
                        label='Role'
                        name='roleId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a role"
                            optionFilterProp="children"
                        >
                            {roles.map((x) => <Option value={x.id} key={x.id} > {x.name} </Option>)}

                        </Select>
                    </Form.Item>

                    <Divider orientation="left"></Divider>

                    <Form.Item
                        label='Is on payroll?'
                        name='isOnPayroll'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            }
                        ]}
                        initialValue={isOnPayroll}
                    >
                        <Switch
                            checked={isOnPayroll}
                            onChange={() => {
                                setIsOnPayroll(!isOnPayroll);
                            }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>

                    <Form.Item
                        label='Status'
                        name='isActive'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            }
                        ]}
                        initialValue={isActive}
                    >
                        <Switch

                            checked={isActive}
                            onChange={() => {
                                setIsActive(!isActive);
                            }}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                        />
                    </Form.Item>

                    <Form.Item
                        label='Manager'
                        name='managerIds'                    >
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="Select a manager"
                            optionFilterProp="label"
                            style={{
                                width: '100%',
                            }}
                            loading={isAvailableManagersLoading}
                            onChange={handleManagerSelect}
                            options={availableManagers.filter(x => x.value != user.id)}
                            value={selectedManagers}
                        >
                            {users.map((x) => <Option value={x.id} key={x.id} > {x.name} </Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' disabled={isValidating != 'success'}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import React from 'react';
import { BrowserRouter as Router, HashRouter, Redirect, withRouter } from "react-router-dom";
import { AppstoreOutlined } from '@ant-design/icons';
import { Layout, Typography, Menu, Select, Space, Form, message, Input, Row, Col, Dropdown, Button, Image } from 'antd';
import { DashboardPaths } from './DashboardPaths';
import { DashboardMenuWithRouter } from './DashboardMenu';
import Login from './Login';
import Auth from './Auth'
import { ToggleCompany } from './contents/companies/ToggleCompany'

const { Content, Sider, Footer, Header } = Layout;
const { Title } = Typography
const { Option } = Select;

export class Dashboard extends React.Component {
    static displayName = Layout.name;

    state = {
        location: window.location,
        storedToken: localStorage.getItem('token'),
        isCollapsed: true,
        isLoading: true,
        isUnderDevelopment: true,
        companies: [],
        token: null,
        company: localStorage.getItem("company") ?? Auth.getCurrentUser().company,
        companyId: localStorage.getItem("companyId") ?? Auth.getCurrentUser().companyId,
        companyLogo: localStorage.getItem("companyLogo") ?? Auth.getCurrentUser().companyLogo
    };

    setCompany = (company, companyId, companyLogo) => {
        localStorage.setItem("company", company);
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("companyLogo", companyLogo);

        if (!localStorage.getItem("companyLogo")) {
            fetch(`api/companies/${companyId}`)
                .then(response => response.json())
                .then(company => {
                    localStorage.setItem("companyLogo", company.logo)
                    this.setState({
                        companyLogo: company.logo
                    })
                })
        }

        this.setState({
            company: company,
            companyId, companyId,
            companyLogo: companyLogo,
            isCollapsed: true
        });

        return <Redirect to="/home" />
    }

    componentDidMount() {
        this.setState({
            token: this.state.storedToken
        })

        setInterval(() => {
            console.log("Check if company has been switched ...")
            Auth.getLoginState();
            if (this.state.company != Auth.getCurrentUser().company) {
                console.log("Company has been changed ...");
                window.location.reload();
            }
        }, 5000)
    }

    setToken = (token) => {
        this.setState({
            token: token,
        });

        localStorage.setItem('token', token);
    }

    onCollapse = collapsed => {
        this.setState({ isCollapsed: collapsed });
    };

    handleMenuClick = (e) => {
        message.info('Click on menu item.');
        console.log('click', e);
    }


    render() {
        const { isCollapsed } = this.state;

        if (this.state.token == null && this.state.storedToken == null) {
            return (
                <HashRouter>
                    <Layout style={{ minHeight: '100vh' }} className="layout">
                        <Content>
                            <Login setToken={this.setToken} />
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>Timbro Contracting Limited © 2024</Footer>
                    </Layout>
                </HashRouter>
            )
        }

        // if (window.location.href.includes("app/crew-builder") ||
        //     window.location.href.includes("app/time-record-form") ||
        //     window.location.href.includes("app/my-schedule") ||
        //     window.location.href.includes("app/my-records")) {
        //     return (
        //         <HashRouter>
        //             <DashboardPaths />
        //         </HashRouter>
        //     )
        // }

        const getLogo = () => {
            if (this.state.companyLogo) {
                return (
                    <Image
                        preview={false}
                        src={this.state.companyLogo}
                    />
                )
            }
        }

        return (
            <HashRouter>
                <Layout
                    style={{
                        minHeight: "100vh"
                    }}>
                    <Sider
                        collapsible
                        collapsed={isCollapsed}
                        onCollapse={this.onCollapse}
                        collapsedWidth={0}
                        trigger={<AppstoreOutlined />}
                        zeroWidthTriggerStyle={{ top: 400 }}
                    >
                        {
                            !isCollapsed &&
                            <Title level={3} style={{ color: 'white', margin: '20px' }}>
                                {getLogo()}
                            </Title>
                        }

                        <DashboardMenuWithRouter />
                    </Sider>

                    <Content style={{ margin: '0 16px' }}>
                        {
                            (Auth.hasPermission('companies.toggle') ||
                                Auth.getCurrentUser()?.role == "Administrator") &&
                            <ToggleCompany currentCompany={this.state.company} setCompany={this.setCompany} />
                        }
                        <DashboardPaths />
                        <Footer style={{ textAlign: 'center' }}>
                            {this.state.company ?? "Timbro Contracting Limited"} © {new Date().getFullYear()}
                        </Footer>
                    </Content>

                </Layout>


            </HashRouter >
        );
    }
}
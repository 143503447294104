import { Breadcrumb, Button, Card, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Select, Upload, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;
export const FormEquipmentCategory = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [isLoading, setIsLoading] = useState(false);

    const [equipment, setEquipment] = useState(false)

    const isEditMode = uriParams?.id != null && uriParams.id > 0;

    const createEquipmentCategory = (newEquipmentCategory) => {
        setLoading(true);

        fetch(`api/equipment/categories`, {
            method: 'POST', body: JSON.stringify(newEquipmentCategory), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Equipment Categories has been added successfully.',
                });
                setLoading(false);
                history.push('/contents/equipment-categories');
            });
    }

    const fetchEquipmentCategory = (id) => {
        setLoading(true)

        fetch(`api/equipment/categories/${id}`)
            .then(response => response.json())
            .then(result => {
                setEquipment(result)
                form.setFieldsValue(result)
                setLoading(false)
            })
    }

    const onFinish = (values) => {
        createEquipmentCategory(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    useEffect(() => {

        if (isEditMode) {
            fetchEquipmentCategory(uriParams.id);
        }

    }, []);

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Equipment Category</Breadcrumb.Item>
                <Breadcrumb.Item>{isEditMode ? `Edit ${uriParams?.id}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>
                <Form
                    form={form}
                    name='EquipmentCategoryForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}

                >
                    <Form.Item
                        label='Equipment Category'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    {/* <Form.Item
                        name='isEquipmentRequired'
                        valuePropName="checked"
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Checkbox>Is Equipment Required?</Checkbox>
                    </Form.Item> */}

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </ >
    );
};
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, GoldOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Badge, Menu, Dropdown, Tag } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { NavLink } from "react-router-dom";
import exportFromJSON from 'export-from-json';
import Auth from '../../Auth';

export class ListJobs extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        jobs: [],
        isLoaded: false,
        error: null
    };

    componentDidMount() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        fetch(`api/jobs?companyId=${Auth.getCurrentUser()?.companyId ?? 1}`)
            .then(response => response.json())
            .then(
                (result) => {
                    const jobs = result.map(job => ({ ...job, key: job.id }));
                    this.setState({
                        jobs: jobs,
                        isLoaded: true,
                    });
                    setLoading(false);
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDelete = job => {
        const self = this;
        const setLoading = this.props.setLoading;

        Modal.confirm({
            title: 'Are you sure delete this job?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setLoading(true);

                const jobId = job.id;
                fetch(`api/jobs/${jobId}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        if (operation.isSuccessful) {
                            self.setState({
                                jobs: self.state.jobs.filter(function (job) {
                                    return job.id !== jobId
                                })
                            });

                            Modal.success({
                                content: 'Job has been deleted.',
                            });
                        }
                        setLoading(false);
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        const columns = [
            {
                title: 'Job',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                // fixed: 'left',
                ...this.getColumnSearchProps('name'),
                render: (id, job) => <>
                    <NavLink to={`/jobs/${job.id}`}> {id}</NavLink>
                </>,
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                width: '30%',
                ...this.getColumnSearchProps('description'),
                render: (description, job) => <span>
                    {
                        job.isMaterialHaulingEnabled && <>
                            <Tag icon={<GoldOutlined />} color="yellow">
                                Material Hauling
                            </Tag>
                            <br />
                        </>
                    }

                    {description?.length > 120 ? `${description.substr(0, 120)} ...` : description}
                </span>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                ...this.getColumnSearchProps('status'),
                sorter: (a, b) => ('' + a.status).localeCompare(b.status),
                sortDirections: ['descend', 'ascend'],
                render: (status) => {
                    var color = "yellow";
                    var text = "Pending";

                    if (status == "in_progress") {
                        color = "blue";
                        text = "In Progress"
                    }

                    if (status == "done") {
                        color = "green";
                        text = "Done"
                    }

                    return (
                        <span>
                            <Badge status={color} />
                            {text}
                        </span>
                    )
                }
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (job) => {
                    return (
                        <Space size="small" wrap={true}>
                            {
                                Auth.hasPermission('jobs.update') &&
                                <Button size="small" type="primary" href={`#/jobs/${job.id}/edit`}>
                                    Edit
                                </Button>
                            }
                            {
                                Auth.hasPermission('jobs.delete') &&
                                <Button size="small" type="primary" danger onClick={() => this.handleDelete(job)}>
                                    Delete
                                </Button>
                            }
                        </Space >

                    )
                },
            },
        ];
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                    <Breadcrumb.Item>Search</Breadcrumb.Item>
                </Breadcrumb>

                <Table rowKey={record => `job-${record.id}`} columns={columns} dataSource={this.state.jobs} scroll={{ x: 1300 }} pagination={{ position: ["bottomLeft"] }}

                    footer={currentPageData => {

                        let data = this.state.jobs;

                        function handleMenuClick(e) {
                            if (e.key.includes("filtered")) {
                                data = currentPageData;
                            }

                            let fileType = exportFromJSON.types.txt;

                            switch (e.key) {
                                case "csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                                case "filtered-csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "filtered-xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "filtered-json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "filtered-txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                            }

                            exportFromJSON({
                                data: data.map(({ job, key, ...items }) => items),
                                fileName: "jobs",
                                exportType: fileType
                            });
                        }
                        const menu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                <Menu.Item key="json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        const filteredMenu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        return (
                            <Space size="small">
                                <Dropdown overlay={menu}>
                                    <Button type="primary" ghost={true}>Download All</Button>
                                </Dropdown>
                                <Dropdown overlay={filteredMenu}>
                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                </Dropdown>
                            </Space>
                        );

                    }}
                />
            </div>
        );
    }
}
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Upload, Popover, Space, Input, Image, InputNumber, Layout, Modal, Select, Card, Divider, Alert, Typography, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


const { Option } = Select;
const { Text, Link } = Typography;
const { Dragger } = Upload;

export const FormCompany = ({ setLoading }) => {
    const [form] = Form.useForm();
    const uriParams = useParams();
    const history = useHistory();
    const [company, setCompany] = useState({});
    const [formLayout, setFormLayout] = useState('vertical');
    const [error, setError] = useState("");
    const [fileList, setFileList] = useState([]);
    const [isUploading, setIsUploading] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [logoBase64, setLogoBase64] = useState();
    const [currentLogo, setCurrentLogo] = useState();
    const [isVisible, setIsVisible] = useState(false);

    const isEditMode = uriParams?.id != null && uriParams.id > 0;

    const fetchCompany = () => {

        if (!isEditMode) {
            return;
        }

        setLoading(true)

        fetch(`/api/companies/${uriParams.id}`)
            .then((response) => response.json())
            .then((company) => {
                setCurrentLogo(company.logo)
                const tmpCompany = company;
                tmpCompany.logo = [];
                form.setFieldsValue(tmpCompany);
                setCompany(company);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const getFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };


    const updateCompany = (values) => {
        values.logo = logoBase64
        console.log(values)
        fetch(`api/companies/${uriParams.id}`, {
            method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Company has been updated successfully.',
                });

                setLoading(false)
                history.push('/companies');
            })
            .catch(error => {
                console.log(error)
                Modal.error({
                    content: <>
                        Something went wrong.
                    </>,
                });
                setLoading(false)
            });
    }

    const createCompany = (values) => {

        if (logoBase64) values.logo = logoBase64
        else values.logo = null

        fetch('api/companies', {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Company has been added successfully.',
                });

                setLoading(false)
                history.push('/companies');
            })
            .catch(error => {
                console.log(error)
                Modal.error({
                    content: <>
                        Something went wrong.
                    </>,
                });
                setLoading(false)
            });
    }

    const onFinish = (values) => {
        setLoading(true)

        if (isEditMode) {
            return updateCompany(values);
        }

        return createCompany(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    useEffect(() => {
        fetchCompany();
    }, []);

    return (

        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Companies</Breadcrumb.Item>

                <Breadcrumb.Item>{isEditMode ? `Edit ${company.name}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            {error && <Alert
                message={error}
                type="error"
                closable
                banner
                style={{ maxWidth: 500 }}
            />}
            <Card style={{ maxWidth: 500 }}>

                {currentLogo &&
                    <Space style={{ marginBottom: 24, padding: 24, backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAAAAACo4kLRAAAAH0lEQVQY02OcxQADZ+AsJgYsYKgIsiD8YTJInEShIAA1NwKQeKc4/QAAAABJRU5ErkJggg==)' }}>
                        <Image src={currentLogo} width={200}></Image>
                    </Space>}

                <Form
                    form={form}
                    name='FormCompany'
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label='Company Name'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        name="logo"
                        label="Logo"
                        valuePropName="fileList"
                        getValueFromEvent={getFile}
                    >

                        <Upload
                            name="logo"
                            listType="picture"
                            beforeUpload={(file) => {
                                setFileList([...fileList, file])
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = function () {
                                    setLogoBase64(reader.result);
                                    console.log(logoBase64)
                                    console.log(reader.result);
                                };
                                reader.onerror = function (error) {
                                    console.log('Error: ', error);
                                };
                                return false;
                            }}
                            onRemove={(file) => {
                                setFileList([]);
                            }}
                            fileList={fileList}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Choose Company Logo</Button>
                        </Upload>

                    </Form.Item>

                    <Form.Item
                        label='Payroll Header'
                        name='payrollHeader'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 9
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

        </div>
    );
};
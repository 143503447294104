import React, { useState } from 'react';
import { Table, Input, Button, Space, Alert, Modal, Breadcrumb, Dropdown, Menu, Card, DatePicker, Divider, Select, Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, FilterOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import Auth from '../../Auth';
const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;



export class MaterialProductionReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        productions: [],
        originalProductions: [],
        costCodes: [],
        isLoaded: false,
        error: null,
        jobs: [],
        selectedCostCode: null,
        dateRange: []
    };

    componentDidMount() {
        this.getProductions()
    }

    handleSelectProduct(costCode) {
        console.log("S", costCode);
        const filtered = this.state.originalProductions?.filter(x => x.costCode?.id == costCode);
        this.setState({
            productions: filtered
        });
    }

    getProductions() {
        this.props.setLoading(true);
        fetch(`api/jobs/?includeJob=true&includeCostCodes=true&includeProductions=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then(response => response.json())
            .then(jobs => {
                let productions = jobs.filter(x => x.isMaterialHaulingEnabled).map(x => x.productions.flat(1));
                let costCodes = productions.flat(1).map(x => x.costCode);
                const key = "id";
                costCodes = [...new Map(costCodes.map(item =>
                    [item[key], item])).values()];

                this.setState({
                    jobs: jobs,
                    costCodes: costCodes
                });

                productions = productions.flat(1).map(x => {
                    return {
                        id: x.id,
                        job: x.costCode?.job?.name,
                        jobId: x.costCode?.job?.id,
                        costCode: x.costCode?.name,
                        costCodeDescription: x.costCode?.description,
                        quantity: x.quantity,
                        unit: x.costCode?.unit,
                        producedAt: moment(x.producedAt).format("YYYY-MM-DD"),
                        type: x.type,
                        costCodeId: x.costCode?.id
                    }
                });


                productions = productions.filter(x => x.type == "Material Hauling" || !x.type)

                this.setState({
                    productions: productions,
                    originalProductions: productions
                });

                this.props.setLoading(false);
            })
    }

    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        setSelectedKeys([e])
                        console.log("Selected Key", selectedKeys)
                    }
                    }
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    handleDelete = record => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this record?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                fetch(`api/jobs/productions/${record.id}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        if (operation.isSuccessful) {
                            // self.fetchProduction();

                            Modal.success({
                                title: 'Success',
                                content: 'Record has been deleted.',
                                icon: <CheckOutlined />,
                                okText: 'Yes',
                            })
                            self.handleSelectProduct(record.costCodeId)
                            // window.location.reload();
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>


                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                ...this.getColumnSearchProps('job'),
            },
            {
                title: 'Product',
                dataIndex: 'costCode',
                key: 'costCode',
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (i, row) => `${row.quantity}`,
                sortDirections: ['descend', 'ascend'],
                // ...this.getColumnSearchProps('quantity'),
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                render: (date) => moment(date).format("YYYY-MM-DD"),
                ...this.getDateColumnSearchProps('producedAt'),
            },
            {
                title: 'Type',
                dataIndex: 'type',
                filters: [
                    {
                        text: <span>Sales</span>,
                        value: 'Material Hauling',
                    },
                    {
                        text: <span>Production</span>,
                        value: null,
                    },
                ],
                onFilter: (value, record) => record.type == value,
                filterSearch: (input, record) => record.type.indexOf(input) > -1,
                render: (type) => type == "Material Hauling" ? "Sales" : "Production",
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    return (
                        <Space size="small" wrap={true}>

                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                        </Space >
                    )
                },
            },
        ];

        const menu = (
            <Select
                placeholder="Select a product"
                style={{ width: 200 }}
                onChange={e => {
                    console.log(e)
                    this.setState({ selectedCostCode: e })
                }}>
                {
                    this.state.jobs.filter(x => x.isMaterialHaulingEnabled).map(x =>
                        <OptGroup key={x.id} label={`${x.name} - ${x.description}`}>
                            {
                                x.costCodes.filter(z => z.isToTrackProduction).map(y =>
                                    <Option key={y.id} value={y.id}>{y.name} - {y.description}</Option>
                                )
                            }
                        </OptGroup>
                    )
                }
            </Select>
        );

        let productions = [];

        const jobIdQuery = this.props.match.params.job_id

        console.log({ params: this.props.match.params.job_id, jobIdQuery, selectedCostCode: this.state.selectedCostCode })
        if (jobIdQuery && !this.selectedCostCode) {
            productions = this.state.productions.filter(x => x.jobId == jobIdQuery)

            const firstCostCode = productions[0].costCodeId;
            console.log({ firstCostCode })
            this.setState({ selectedCostCode: firstCostCode })
        }

        if (this.state.selectedCostCode) {
            productions = this.state.productions.filter(x => x.costCodeId == this.state.selectedCostCode)
            productions = productions.filter(x => x.type == "Material Hauling" || x.type == "Production" || !x.type)
        }


        let dateRange = this.state.dateRange;

        if (dateRange?.length > 0) {
            const start = dateRange[0];
            const end = dateRange[1];
            productions = productions.filter(x => {
                start.set({ hour: 0 })
                end.set({ hour: 10 })

                let current = moment(x.producedAt);
                current.set({ hour: 5 });

                return current.isBetween(start, end, undefined, '[]')
            })
        }

        let productName = '';
        // if (this.state.selectedCostCode) {
        //     productName = `${productions[0].costCode} - ${productions[0].costCodeDescription}`
        // }

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Stockpile Report</Breadcrumb.Item>
                </Breadcrumb>
                <Card title="Stockpile">

                    <Row gutter={[16, 16]}>
                        <Col>
                            <Card size="small" title="Filter by Date Range (Inclusive)">
                                <RangePicker
                                    defaultValue={[moment().subtract(30, 'days'), moment()]}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        this.setState({ dateRange: e })
                                    }} />
                            </Card>
                        </Col>
                        <Col>
                            <Card size="small" title="Filter by Product">
                                {menu}
                            </Card>
                        </Col>
                    </Row>
                    {/* <Divider>{productName}</Divider> */}

                    <Table
                        style={{ marginTop: 10 }}
                        columns={columns}
                        dataSource={productions}
                        pagination={false}
                        bordered
                        summary={(currentPageData) => (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}><strong>{currentPageData.reduce((a, b) => +a + +b.quantity, 0)}</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                    />
                </Card>
            </div>
        );
    }
}
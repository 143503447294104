import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu } from 'antd';
import exportFromJSON from 'export-from-json';
import React from 'react';
import Highlighter from 'react-highlight-words';

import Auth from '../../Auth'
const { confirm } = Modal;

export class ListWorkTypes extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        workTypes: [],
        isLoaded: false,
        error: null,
        paginationSettings: { position: ["bottomLeft"] }
    };

    componentDidMount() {
        this.fetchWorkTypes()
    }

    fetchWorkTypes() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        fetch(`api/work-types`)
            .then(response => response.json())
            .then(
                (result) => {
                    const workTypes = result.map(e => ({
                        name: `${e.name} (${e.id})`,
                        isEquipmentRequired: e.isEquipmentRequired,
                        key: e.id,
                        id: e.id,
                    }));
                    this.setState({
                        workTypes: workTypes,
                    });
                    setLoading(false);
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDelete = workType => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this work type?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                const workTypeId = workType.id;
                fetch(`api/work-types/${workTypeId}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        console.log(operation);
                        if (operation.isSuccessful) {
                            self.setState({
                                workTypes: self.state.workTypes.filter(function (wt) {
                                    return wt.id !== workTypeId
                                })
                            });

                            Modal.success({
                                content: 'Work Type has been deleted.',
                            });
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        const columns = [
            {
                title: 'Work Type',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                // fixed: 'left',

            },
            {
                title: 'Equipment Required',
                dataIndex: 'isEquipmentRequired',
                key: 'isEquipmentRequired',
                render: i => i ? <CheckOutlined /> : null
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (workType) => {
                    return (
                        <Space size="small" wrap={true}>
                            <Button size="small" type="primary" href={`#/contents/work-types/${workType.id}/edit`}>
                                Edit
                            </Button>
                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(workType)}>
                                Delete
                            </Button>
                        </Space >

                    )
                },
            },
        ];

        const self = this;

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Work Types</Breadcrumb.Item>
                    <Breadcrumb.Item>Search</Breadcrumb.Item>
                </Breadcrumb>

                <Table
                    rowKey={record => `work-type-${record.id}`}
                    columns={columns}
                    dataSource={this.state.workTypes}
                    scroll={{ x: 1300 }}
                    pagination={self.state.paginationSettings}
                    footer={currentPageData => {

                        // let data = this.state.workTypes;

                        // function handleMenuClick(e) {
                        //     if (e.key.includes("filtered")) {
                        //         data = currentPageData;
                        //     }

                        //     let fileType = exportFromJSON.types.txt;

                        //     switch (e.key) {
                        //         case "csv":
                        //             fileType = exportFromJSON.types.csv;
                        //             break;
                        //         case "xls":
                        //             fileType = exportFromJSON.types.xls;
                        //             break;
                        //         case "json":
                        //             fileType = exportFromJSON.types.json;
                        //             break;
                        //         case "txt":
                        //             fileType = exportFromJSON.types.txt;
                        //             break;
                        //         case "filtered-csv":
                        //             fileType = exportFromJSON.types.csv;
                        //             break;
                        //         case "filtered-xls":
                        //             fileType = exportFromJSON.types.xls;
                        //             break;
                        //         case "filtered-json":
                        //             fileType = exportFromJSON.types.json;
                        //             break;
                        //         case "filtered-txt":
                        //             fileType = exportFromJSON.types.txt;
                        //             break;
                        //     }

                        //     exportFromJSON({
                        //         data: data.map(({ key, ...items }) => items),
                        //         fileName: "work-types",
                        //         exportType: fileType
                        //     });
                        // }

                        // const menu = (
                        //     <Menu onClick={handleMenuClick}>
                        //         <Menu.Item key="csv">Export as CSV</Menu.Item>
                        //         <Menu.Item key="xls">Export as XLS</Menu.Item>
                        //         <Menu.Item key="json">Export as JSON</Menu.Item>
                        //     </Menu>
                        // );

                        // const filteredMenu = (
                        //     <Menu onClick={handleMenuClick}>
                        //         <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                        //         <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                        //         <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                        //     </Menu>
                        // );

                        // return (
                        //     <Space size="small">
                        //         <Dropdown overlay={menu}>
                        //             <Button type="primary" ghost={true}>Download All</Button>
                        //         </Dropdown>
                        //     </Space>
                        // );

                    }}
                />
            </div>
        );
    }
}
import { Breadcrumb, Button, Card, Form, Input, InputNumber, Modal, Select, DatePicker, Badge, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
import moment from 'moment';
const { Option } = Select;

export const FormMaterialHauling = ({ setLoading, location }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');

    const jobIdQuery = new URLSearchParams(location.search).get('job_id');
    const prefillJobId = new URLSearchParams(location.search).get("jobId") ?? jobIdQuery;
    const prefillCostCodeId = new URLSearchParams(location.search).get("costCodeId");
    const prefillUserId = new URLSearchParams(location.search).get("userId");
    const prefillProducedAt = new URLSearchParams(location.search).get("producedAt");
    const prefillQuantity = new URLSearchParams(location.search).get("quantity");
    const prefillWorkType = new URLSearchParams(location.search).get("workType");
    const prefillDescription = new URLSearchParams(location.search).get("description");

    const [jobs, setJobs] = useState([]);
    const [jobId, setJobId] = useState();

    const [costCodes, setCostCodes] = useState([]);
    const [costCodeId, setCostCodeId] = useState();

    const [userId, setUserId] = useState();
    const [users, setUsers] = useState([]);
    const [unit, setUnit] = useState("N/A");

    const onValuesChange = (changedValues) => {
        console.log(form.getFieldsValue())
    }

    const onJobSelect = (value) => {
        const selectedJob = jobs.find(x => x.id == value)
        const costCodes = selectedJob?.costCodes?.filter(x => x.isToTrackProduction);
        const assignedUsers = selectedJob?.userAssignments?.map(x => { return { ...x } });
        setCostCodes(costCodes)
        setUsers(assignedUsers);

        form.setFieldsValue({ costCodeId: null })
        form.setFieldsValue({ userId: null })
    }

    const onUserSelect = (value) => {
        setUserId(value)
        form.setFieldsValue({ userId: value })
    }

    const onCostCodeSelect = (value) => {
        const selectedCostCode = costCodes.find(x => x.id == value)
        // console.log(selectedCostCode)
        setUnit(selectedCostCode?.unit)
        setCostCodeId(value)
        form.setFieldsValue({ costCodeId: value })
    }

    const onFinish = (values) => {
        values.userId = Auth.getCurrentUser().id;
        values.type = 'Material Hauling';
        values.quantity = values.quantity * -1
        fetch(`api/jobs/${values.jobId}/productions`, {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Material Hauling data has been added successfully.',
                });

                form.resetFields()
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const fetchJobs = () => {
        setLoading(true)

        fetch(`/api/jobs?includeCostCodes=true&includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((jobsList) => {
                setLoading(false)
                return jobsList.filter(x => x.isMaterialHaulingEnabled);
            })
            .then((jobsList) => {
                setJobs(jobsList)
            })
            .catch(error => {
                setLoading(false);
            });

    }

    useEffect(() => {
        fetchJobs();

        if (jobIdQuery) {
            form.setFieldsValue({ jobId: jobIdQuery })
        }

    }, []);



    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <div>

            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Material Hauling</Breadcrumb.Item>
                <Breadcrumb.Item>Add</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }}>
                <Form
                    name='FormMaterialHauling'
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    onValuesChange={onValuesChange}

                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        initialValue={jobId}
                        value={jobId}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                            onChange={onJobSelect}
                            value={jobId}
                        >
                            {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Product'
                        name='costCodeId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Product"
                            optionFilterProp="children"
                            value={costCodeId}
                            onFocus={() => {
                                onJobSelect(form.getFieldValue("jobId"))
                            }}
                            onChange={onCostCodeSelect}
                        >
                            {costCodes?.map((c) => <Option value={c.id} key={c.id} > {c.name} - {c.description}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Date'
                        name='producedAt'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>

                        <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={d => !d || d.isAfter(moment())}
                        />

                    </Form.Item>

                    <Form.Item
                        label={`Quantity (${unit})`}
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='Comments'
                        name='description'
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        label='Invoice'
                        name='invoiceStatus'
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="N/A"
                            defaultValue="N/A"
                        >
                            <Option value="N/A">N/A</Option>
                            <Option value="Not to be Invoiced">Not to be Invoiced</Option>
                            <Option value="Ready for Invoicing">Ready for Invoicing</Option>
                            <Option value="Invoiced">Invoiced</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import { Breadcrumb, Button, Card, Checkbox, Form, Input, InputNumber, Layout, message, Modal, Select, Upload, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;

export const FormCostCode = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const isEditMode = uriParams?.id != null && uriParams.id > 0;
    const [formLayout, setFormLayout] = useState('vertical');
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [jobId, setJobId] = useState(0);
    const [costCode, setCostCode] = useState({});
    const jobIdQuery = new URLSearchParams(location.search).get('job_id');
    const [isDownloadResults, setIsDownloadResults] = useState();
    const [isValidating, setIsValidating] = useState("");

    const fetchJobs = () => {
        setLoading(true);

        return fetch(`/api/jobs?companyId=${Auth.getCurrentUser().companyId}`)
            .then((response) => response.json())
            .then((jobs) => {
                setIsLoading(false);

                setJobs(jobs);
                setJobId(jobIdQuery);

                jobs.map((job) => ({
                    label: `${job.name}`,
                    value: job.id,
                }));

                setLoading(false);
            })
            .catch(error => {
                setIsError(true);
                setLoading(false);
            });
    }

    var fileList = [];

    const fetchCostCode = () => {
        if (!uriParams.id) {
            return;
        }
        setLoading(true);

        const fields = form.getFieldsValue();
        return fetch(`/api/cost-codes/${uriParams.id}`)
            .then((response) => response.json())
            .then((costCode) => {
                setCostCode(costCode);


                form.setFieldsValue(costCode);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setIsError(true);
            });
    }

    useEffect(() => {
        fetchJobs();
        fetchCostCode();

        if (isEditMode) {
            const name = costCode.name;
            console.log("Name", name)
            validateCostCodeName(name);
        }

    }, []);

    const updateCostCode = (values) => {
        setLoading(true);
        const costCodeId = costCode.id;
        fetch(`api/cost-codes/${costCodeId}`, {
            method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Cost Code has been updated successfully.',
                });

                setLoading(false);
                history.push('/cost-codes');

            });
    }

    const createCostCode = (newCostCode) => {
        setLoading(true);
        const jobId = newCostCode.jobId;
        fetch(`api/jobs/${jobId}/cost-codes`, {
            method: 'POST', body: JSON.stringify(newCostCode), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Cost Code has been added successfully.',
                });
                setLoading(false);
                history.push('/cost-codes');
            });
    }

    const handleUpload = () => {
        const formData = new FormData();
        console.log(fileList);
        formData.append('spreadsheet', fileList[0]);
        console.log(formData);
        // You can use any AJAX library you like
        setLoading(true);
        fetch('api/cost-codes/import', {
            method: 'POST',
            body: formData,
            mode: 'cors',

        })
            .then(response => response.json())
            .then(result => {
                setLoading(false);
            })
            .catch(result => {
                setLoading(false)
            });
    };

    const onFinish = (values) => {
        if (costCode.id > 0) {
            updateCostCode(values);
        }
        else {
            createCostCode(values);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const beforeUpload = (file) => {
        fileList = [...fileList, file];
        return false;
    };

    const validateCostCodeName = (e) => {
        console.log(form.getFieldValue('jobId'))
        if (form.getFieldValue('jobId') == null && !isEditMode) {
            return
        }

        // console.log(`Validating Name: ${e?.target?.value ?? e} | ${costCode.name}`)
        if ((e?.target?.value ?? e) == costCode.name && isEditMode) {
            setIsValidating('success');
            return;
        }
        setIsValidating('validating')
        fetch(`/api/cost-codes?name=${e?.target?.value ?? costCode?.name ?? form.getFieldValue('name')}&companyId=${Auth.getCurrentUser().companyId}&jobId=${form.getFieldValue('jobId')}`)
            .then((response) => response.json())
            .then((existing) => {
                if (existing.length > 0) setIsValidating('error');
                else setIsValidating('success')
            })
            .catch(error => {
                setIsValidating('error');
            });;
    }

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Cost Codes</Breadcrumb.Item>
                <Breadcrumb.Item>{costCode?.name ? `Edit ${costCode.name}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>
                <Form
                    form={form}
                    name='CostCodeForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}

                >
                    {!uriParams.id &&

                        <Form.Item
                            label='Job'
                            name='jobId'
                            initialValue={jobIdQuery ? parseInt(jobIdQuery) : null}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                },
                            ]
                            }>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={e => validateCostCodeName(costCode.name)}
                                onFocus={e => console.log(e)}
                                onBlur={e => console.log(e)}
                                onSearch={e => console.log(e)}
                                placeholder="Select a Job"
                                loading={isLoading}>
                                {jobs.map((job) => <Option value={job.id} key={job.id} > {job.name} - {job.description} </Option>)}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        label='Cost Code'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 20
                            }
                        ]}
                        onBlur={validateCostCodeName}
                        validateStatus={isValidating}
                        help={isValidating == 'error' ? "Please enter a unique value." : null}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                            {
                                max: 60
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Quantity'
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: "100%" }} width="100%" />
                    </Form.Item>

                    <Form.Item
                        label='Unit of Measure'
                        name='unit'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name='isToTrackProduction'
                        valuePropName="checked"
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Checkbox>Is it to track production?</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name='isToTrackEmployeeAndEquipmentTime'
                        valuePropName="checked"
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Checkbox onChange={e => e}>Is it to track employee and equipment time?</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name='isLOA'
                        valuePropName="checked"
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Checkbox>Is LOA?</Checkbox>
                    </Form.Item>

                    <Form.Item
                        label='Invoicing Information'
                        name='invoicingInformation'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item>
                        <Button type='primary' htmlType='submit' disabled={isValidating != 'success'}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Card style={{ maxWidth: 500 }}
                title="Batch Upload (Microsoft Excel Sheet .xls)">
                <Upload beforeUpload={beforeUpload}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
                <Button
                    type="primary"
                    onClick={handleUpload}
                    style={{ marginTop: 16 }}
                >
                    Upload
                </Button>
            </Card>
        </ >
    );
};
import { Breadcrumb, Button, Card, Form, Layout, Select, DatePicker, Alert, List, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment'
import Auth from '../../../Auth';
const { Content } = Layout;
const { Option } = Select;

export const PayrollReportCheck = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const [dateQuery, setDateQuery] = useState(moment().startOf('day').toISOString());
    const [timeRequests, setTimeRequests] = useState();
    const [isSuccess, setIsSuccess] = useState(false);

    const [missingTimeCards, setMissingTimeCards] = useState([]);


    const fetchTimeRequests = () => {
        setLoading(true);
        fetch(`/api/jobs/production-requests?companyId=${Auth.getCurrentUser()?.companyId}&isPending=true`)
            .then((response) => response.json())
            .then((response) => {
                setTimeRequests(response);
                const pending = response.filter(x => (x.productionId == null || x.productionId == 0) && moment(x.requestAt).isSame(moment(dateQuery), 'day'))

                const allPending = response;
                setMissingTimeCards(allPending)
                if (pending.length == 0) {
                    setIsSuccess(true)
                }
                else {
                    // setMissingTimeCards(allPending)
                    setIsSuccess(false)
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchTimeRequests()
    }, [dateQuery]);

    // const onFilterChange = () => {
    //     const values = form.getFieldsValue();
    //     if (values.jobId && values.queryDate) {
    //         setDateQuery(moment(values.queryDate).format("YYYY-MM-DD"))
    //         setJobId(values.jobId)
    //         fetchTimeRequests(values.jobId);
    //     }
    // }

    const onFilterChange = () => {
        const values = form.getFieldsValue();
        const queryDate = values.queryDate;

        console.log("Q", moment(queryDate).format("YYYY-MM-DD"))


        if (values.queryDate) {
            setDateQuery(moment(queryDate).startOf('day').toISOString())
            // fetchTimeRequests();
        }
    }

    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {

    };

    const data = [
    ];

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Human Resources</Breadcrumb.Item>
                <Breadcrumb.Item>Payroll Report</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }} bordered={false}>

                <Form
                    form={form}
                    name='PayrollReportCheckForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}
                    onFieldsChange={onFilterChange}

                >
                    <Form.Item
                        label='Date'
                        name='queryDate'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>
                        <DatePicker
                            format="YYYY-MM-DD"
                            defaultValue={moment()}
                            disabledDate={d => !d || d.isAfter(moment())}
                        />
                    </Form.Item>
                </Form>
            </Card>
            {
                (dateQuery) ?
                    // isSuccess ?
                    //     <>
                    //         <Alert
                    //             message="No Pending Time Cards"
                    //             description={`Please click on the "View Report" button to continue.`}
                    //             type="success"
                    //             banner
                    //             action={<Button size="small" type="primary" href={`#/human-resources/payroll-report?date=${dateQuery}`}>View Report</Button>}
                    //             style={{ maxWidth: 500, marginBottom: 8 }}
                    //         />


                    //     </>
                    //     :
                    //     <>
                    //         <Alert
                    //             message="Missing Time Cards"
                    //             description={`Please remind workers to submit time cards.`}
                    //             type="error"
                    //             banner
                    //             style={{ maxWidth: 500 }}
                    //         />

                    //     </>
                    <Alert
                        message={`${isSuccess ? "No pending" : "There are missing"} time cards.`}
                        description={`Please click on the "View Report" button to continue.`}
                        type={isSuccess ? "success" : "warning"}
                        banner
                        action={<Button size="small" type="primary" href={`#/human-resources/payroll-report?date=${dateQuery}`}>View Report</Button>}
                        style={{ maxWidth: 500, marginBottom: 8 }}
                    />

                    :
                    <>
                    </>


            }
            {missingTimeCards.length > 0 &&
                <List
                    style={{ maxWidth: 500, background: "#FFF", border: "none" }}
                    type="error"
                    size="small"
                    bordered
                    dataSource={missingTimeCards}
                    renderItem={item => <List.Item style={{ border: "none", padding: 12, background: moment(item.requestAt).format("YYYY-MM-DD") == dateQuery ? "#ffa39e" : "#ffbb96" }}
                    >
                        <List.Item.Meta
                            title={item.user?.name}
                            description={`${moment(item.requestAt).format("YYYY-MM-DD")}`} />
                    </List.Item>}
                />
            }
        </>
    );
};

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, Layout, Modal, Radio, Card, InputNumber, DatePicker, Switch } from 'antd';
import moment from 'moment';

import Auth from '../../Auth'

const { Content } = Layout;
const { RangePicker } = DatePicker;

const API_KEY = "AIzaSyD-UM0TVKng7yiF59qmKz8f9PKCXIW1GVM"

export const FormJob = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [job, setJob] = useState({});
    const isEditMode = uriParams?.id != null && uriParams.id > 0;
    const rangeFormat = "YYYY-MM-DD";
    const [isBc1Call, setIsBc1Call] = useState(false);
    const [isMaterialHaulingEnabled, setIsMaterialHaulingEnabled] = useState(false);
    const [mapLocation, setMapLocation] = useState("https://maps.google.com/maps?q=Canada&t=&z=13&ie=UTF8&iwloc=&output=embed");
    const [isValidating, setIsValidating] = useState("");

    const fetchJob = () => {
        if (!isEditMode) {
            return;
        }

        setLoading(true);

        fetch(`/api/jobs/${uriParams.id}`)
            .then((response) => response.json())
            .then((job) => {

                if (job.endsAt && job.startsAt) {
                    job.projectRange = [moment(job.startsAt, rangeFormat), moment(job.endsAt, rangeFormat)];
                }
                else {
                    job.projectRange = [undefined, undefined];
                }

                form.setFieldsValue(job);

                if (job.location) {
                    setMapLocation(`https://maps.google.com/maps?q=${job.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`)
                }

                setIsBc1Call(job.bc1Call)
                setIsMaterialHaulingEnabled(job.isMaterialHaulingEnabled)
                setJob(job);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }


    const onFinish = (job) => {
        if (job.status == null) {
            job.status = "in_progress";
        }

        if (job.projectRange?.length > 0) {
            job.startsAt = job.projectRange[0];
            job.endsAt = job.projectRange[1];
        }


        if (isEditMode) {

            fetch(`api/jobs/${uriParams.id}`, {
                method: 'PUT', body: JSON.stringify(job), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'Job has been updated successfully.',
                    });

                    history.push('/jobs');
                });

            return;
        }

        job.companyId = Auth.getCurrentUser()?.companyId
        fetch('api/jobs', {
            method: 'POST', body: JSON.stringify(job), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Job has been added successfully.',
                });

                history.push('/jobs');
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    useEffect(() => {
        fetchJob()

        if (isEditMode) {
            const name = job.name;
            console.log("Name", name)
            validateJobName(name);
        }
    }, []);

    const dateRangeConfig = {
        rules: [
            {
                type: 'array',
            },
        ],
    };

    const validateJobName = (e) => {
        console.log(`Validating Name: ${e?.target?.value ?? e} | ${job.name}`)
        if ((e?.target?.value ?? e) == job.name && isEditMode) {
            setIsValidating('success');
            return;
        }
        setIsValidating('validating')
        fetch(`/api/jobs?name=${e.target.value}`)
            .then((response) => response.json())
            .then((existing) => {
                if (existing.length > 0) setIsValidating('error');
                else setIsValidating('success')
            })
            .catch(error => {
                setIsValidating('error');
            });;
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>{job?.name ? `Edit ${job.name}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>

                <Form
                    name='JobForm'
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label='Job No.'
                        name='name'
                        validateStatus={isValidating}
                        help={isValidating == 'error' ? "Please enter a unique value." : null}
                        hasFeedback
                        onBlur={validateJobName}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 9
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 30
                            }
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        label='Status'
                        name='status'
                        initialValue='pending'
                    >
                        <Radio.Group buttonStyle='outline'>
                            <Radio.Button defaultChecked value='pending'>Pending</Radio.Button>
                            <Radio.Button value='in_progress'>In Progress</Radio.Button>
                            <Radio.Button value='done'>Done</Radio.Button>
                        </Radio.Group>

                    </Form.Item>

                    <Form.Item
                        label='Location'
                        name='location'

                    >

                        <Input
                            onChange={(e) => {
                                let value = "Agassiz, British Columbia, Canada"

                                if (e.target.value) {
                                    setMapLocation(`https://maps.google.com/maps?q=${e.target.value}&t=&z=13&ie=UTF8&iwloc=&output=embed`)
                                }
                                else if (job?.location) {
                                    value = job.location
                                    setMapLocation(`https://maps.google.com/maps?q=${job.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`)
                                }
                            }

                            } />

                    </Form.Item>
                    <Card size="small">

                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe width="100%" height="300" id="gmap_canvas" src={mapLocation} frameBorder={0}>
                                </iframe>
                            </div>
                        </div>
                    </Card>
                    <br />

                    <Form.Item name="projectRange" label="Project Span" {...dateRangeConfig}>
                        <RangePicker format={rangeFormat} />
                    </Form.Item>

                    <Form.Item
                        label='Owner'
                        name='owner'
                    >
                        <Input />

                    </Form.Item>

                    <Form.Item
                        label='Number of Workers'
                        name='numberOfWorkers'
                        initialValue={1}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='Depth Dig (m)'
                        name='depthDig'
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='BC1 Call'
                        name='bc1Call'
                    >
                        <Switch
                            checked={isBc1Call}
                            onChange={() => {
                                setIsBc1Call(!isBc1Call);
                            }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>

                    <Form.Item
                        label='Enable Material Hauling'
                        name='isMaterialHaulingEnabled'
                    >
                        <Switch
                            checked={isMaterialHaulingEnabled}
                            onChange={() => {
                                setIsMaterialHaulingEnabled(!isMaterialHaulingEnabled);
                            }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' disabled={isValidating != 'success'}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
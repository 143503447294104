import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, Button, Space, Breadcrumb, Card, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Auth from '../../Auth';

export class ListRoles extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        roles: [],
        error: null,
    };

    componentDidMount() {
        const setLoading = this.props.setLoading;
        setLoading(true);

        fetch("api/roles")
            .then(response => response.json())
            .then((result) => {
                this.setState({
                    roles: result.map(u => ({ ...u, key: u.id })),
                });
                setLoading(false);
            },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    handleDelete = role => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this cost code?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                self.props.setLoading(true)
                const roleId = role.id;
                fetch(`api/roles/${roleId}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        console.log(operation);
                        if (operation.isSuccessful) {
                            self.setState({
                                roles: self.state.roles.filter(function (role) {
                                    return role.id !== roleId
                                })
                            });

                            Modal.success({
                                content: 'Role has been deleted.',
                            });
                        }
                        self.props.setLoading(false)
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // fixed: 'left',
                width: '20%',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('username'),
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                width: '160px',
                render: (role) => {
                    return (
                        <Space size="small" wrap={true}>
                            {
                                Auth.hasPermission('roles.update') &&
                                <Button size="small" type="primary" href={`#/roles/${role.id}/edit`}>
                                    Edit
                                </Button>
                            }
                            {
                                Auth.hasPermission('roles.update') &&
                                role.name !== "Administrator" &&
                                <Button size="small" type="primary" danger onClick={() => this.handleDelete(role)}>
                                    Delete
                                </Button>
                            }
                        </Space>

                    )
                },
            },
        ];
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Roles</Breadcrumb.Item>
                    <Breadcrumb.Item>Search</Breadcrumb.Item>
                </Breadcrumb>
                <Table columns={columns} dataSource={this.state.roles} scroll={{ x: 1300 }} pagination={{ position: ["bottomLeft"] }} />
            </>
        );
    }
}
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, Layout, Modal, Radio, Card, Select, Collapse, Alert } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

export const AssignUser = ({ location }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const uriParams = useParams();
    const [formLayout, setFormLayout] = useState('vertical');

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState([]);
    const [users, setUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const jobIdQuery = new URLSearchParams(location.search).get('job_id');

    const fetchJobs = async () => {
        await fetch(`/api/jobs?includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((responseJobs) => {
                setJobs(responseJobs)
                console.log("From Jobs Fetch", jobs)
                fetchUsers();

            })
            .catch(error => {
                console.log(error)
                setIsLoading(false);
                setIsError(true);
            });
    }

    const fetchUsers = async () => {
        return fetch(`/api/users?companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((users) => {
                setIsLoading(false);
                console.log("From Users Fetch", jobs)
                setUsers(users);
                form.setFieldsValue({ jobId: jobIdQuery ? parseInt(jobIdQuery) : null });
                getAvailableUsers(parseInt(jobIdQuery));
            })
            .catch(error => {
                console.log(error)
                setIsLoading(false);
                setIsError(true);
            });
    }

    const getAvailableUsers = (jobId) => {
        console.log("From Method", jobs)
        const selectedJob = jobs.find(x => x.id == jobId);
        const assignedUsers = selectedJob.userAssignments;

        const availableUsers = users.filter(u => {
            if (assignedUsers) {
                return assignedUsers.every(a => {
                    return u.id != a.user.id;
                })
            }
        }

        );
        console.log(availableUsers)
        setAvailableUsers(availableUsers);
    }

    const onJobSelect = (job) => {
        getAvailableUsers(job);
    }

    const onFinish = (values) => {
        var jobId = values.jobId;
        const data = { userId: values.userId, jobId: values.jobId, assignment: values.assignment }

        fetch(`api/jobs/${jobId}/assignments`, {
            method: 'POST', body: JSON.stringify(data), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                fetchJobs();
                setIsAssigned(true);
                form.resetFields();
            });
    };




    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    const workTypes = [
        "(S) Buckerman",
        "(S) CUA Faller",
        "(S) Danger Tree Assesor",
        "(S) Faller Apprentice",
        "(S) Faller-Production",
        "(S) Lead Hand",
        "Admin-Office",
        "Carpenter",
        "Clearing-First Aid OFA Level 3",
        "Clearing-Foreman",
        "Clearing-Labour",
        "Clearing-Mechanic",
        "Clearing-PM",
        "Clearing-QC manager",
        "Clearing-Safety Supervisor",
        "Clearing-Site Super",
        "Driver",
        "First Aid",
        "Foreman",
        "Fusing Technician",
        "Grademan",
        "Labour",
        "Mechanic Major Equipment",
        "Mechanic Small tools",
        "Operator",
        "Pipe Layer",
        "Project Management",
        "Quality Control",
        "Safety",
        "Site Superintendent",
        "Surveyor Field",
        "Surveyor Office",
        "Traffic Control",
        "Welder Major Equipment",
        "Welder Various"
    ];

    useEffect(() => {
        fetchJobs();


    }, []);

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>Assign Employee to Job</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>
                {isAssigned && <Alert
                    message={`Successfully assigned employee selected user to job.`}
                    type="success"
                    closable
                    style={{ marginBottom: 20 }}
                />}

                <Form
                    name='NewUserJobAssignment'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}

                >
                    <Form.Item
                        label='Job'
                        name='jobId'

                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                            defaultValue={selectedJob.id}
                            onChange={e => onJobSelect(e)}
                            onSearch={e => console.log(e)}
                        >
                            {jobs.map((job) => <Option value={job.id} key={job.id} > {job.name} - {job.description}</Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Employee'
                        name='userId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}

                    >
                        <Select
                            showSearch
                            placeholder="Select an Available Employee"
                            optionFilterProp="children"
                            onChange={e => console.log(e)}
                            onFocus={e => getAvailableUsers(form.getFieldValue("jobId"))}
                            onBlur={e => console.log(e)}
                            onSearch={e => console.log(e)}

                        >
                            {availableUsers.map((user) => <Option value={user.id} key={user.id} > {user.name} </Option>)}
                        </Select>
                    </Form.Item>


                    {/* <Form.Item
                        label='Work Type'
                        name='assignment'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Work Type"
                            optionFilterProp="children"
                        >
                            {workTypes?.map((c) => <Option value={c} key={c} > {c} </Option>)}
                        </Select>
                    </Form.Item>
 */}

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
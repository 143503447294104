import { Breadcrumb, Button, Card, Form, Layout, Select, DatePicker, Alert, List, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment'
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;

export const ForemanReportCheck = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();

    const [formLayout, setFormLayout] = useState('vertical');
    const [jobs, setJobs] = useState([]);

    const [jobId, setJobId] = useState(null);
    const [dateQuery, setDateQuery] = useState(moment());

    const [timeRequests, setTimeRequests] = useState();
    const [isSuccess, setIsSuccess] = useState(false);

    const [missingTimeCards, setMissingTimeCards] = useState([]);

    const fetchJobs = () => {
        setLoading(true);

        return fetch(`/api/jobs?companyId=${Auth.getCurrentUser().companyId}`)
            .then((response) => response.json())
            .then((jobs) => {
                setJobs(jobs);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const fetchTimeRequests = (date, jobId) => {
        setLoading(true);

        var start = moment(date).startOf("day").toISOString()
        var end = moment(date).endOf("day").toISOString()

        fetch(`/api/jobs/${jobId}/production-requests?companyId=${Auth.getCurrentUser()?.companyId}&isPending=true&requestAt=${start}&requestEndsAt=${end}`)
            .then((response) => response.json())
            .then((response) => {

                const pending = response.filter(x => x.job.id == jobId && (x.productionId == null || x.productionId == 0))
                const allPending = response.filter(x => x.job.id == jobId);
                if (pending.length == 0) {
                    setMissingTimeCards(pending)
                    setIsSuccess(true)
                    console.log("X")
                }
                else {
                    setMissingTimeCards(pending)
                    setIsSuccess(false)
                    console.log("Y")
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchJobs()

    }, [jobId, dateQuery])

    const onFilterChange = (date, jobId) => {
        setJobId(jobId)
        setDateQuery(date)
        fetchTimeRequests(date, jobId)
    }

    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {

    };

    const data = [
        //{
        //    title: 'T',
        //},
    ];

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Foreman Report</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }} bordered={false}>

                <Form
                    form={form}
                    name='ForemanReportCheckForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}
                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]
                        }>
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                            onChange={value => onFilterChange(dateQuery, value)}
                        >
                            {jobs?.map((job) => <Option value={job.id} key={job.id} > {job.name} - {job.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Date'
                        name='queryDate'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>
                        <DatePicker
                            onChange={value => onFilterChange(value, jobId)}
                            defaultValue={dateQuery}
                            format="YYYY-MM-DD"
                            disabledDate={d => !d || d.isAfter(moment())}
                        />
                    </Form.Item>
                </Form>
            </Card>
            {
                (jobId && dateQuery) ?
                    isSuccess ?
                        <>
                            <Alert
                                message="No Pending Time Cards"
                                description={`Please click on the "View Report" button to continue.`}
                                type="success"
                                banner
                                action={<Button size="small" type="primary" href={`#/reports/foreman-report/${jobId}?date=${dateQuery.toISOString()}`}>View Report</Button>}
                                style={{ maxWidth: 500, marginBottom: 8 }}
                            />


                        </>
                        :
                        <>
                            <Alert
                                message="Missing Time Cards"
                                description={`Please remind workers to submit time cards.`}
                                type="error"
                                banner
                                style={{ maxWidth: 500 }}
                            />

                        </>
                    :
                    <>
                    </>


            }
            {missingTimeCards.length > 0 &&
                <List
                    style={{ maxWidth: 500, background: "#FFF", border: "none" }}
                    type="error"
                    size="small"
                    bordered
                    dataSource={missingTimeCards}
                    renderItem={item => <List.Item style={{ border: "none", padding: 12, background: moment(item.requestAt).isSame(dateQuery, "day") ? "#ffa39e" : "#ffbb96" }}
                    >
                        <List.Item.Meta
                            title={`${item.user?.name}`}
                            description={moment(item.requestAt).format("YYYY-MM-DD")} />
                    </List.Item>}
                />
            }
        </ >
    );
};
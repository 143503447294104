import { Breadcrumb, Button, Card, Form, Layout, Select, DatePicker, Alert, List, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment'
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const SpreadReportCheck = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();

    const [formLayout, setFormLayout] = useState('vertical');
    const [jobs, setJobs] = useState([]);

    const [jobId, setJobId] = useState(null);
    const [dateQuery, setDateQuery] = useState([moment().add(-7, 'days').startOf('day'), moment().endOf('day')]);

    const [timeRequests, setTimeRequests] = useState();
    const [isSuccess, setIsSuccess] = useState(false);

    const [missingTimeCards, setMissingTimeCards] = useState([]);

    const fetchJobs = () => {
        setLoading(true);

        return fetch(`/api/jobs?companyId=${Auth.getCurrentUser().companyId}`)
            .then((response) => response.json())
            .then((jobs) => {
                setJobs(jobs);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const fetchTimeRequests = (selectedJobId) => {
        setLoading(true);
        fetch(`/api/jobs/${selectedJobId}/production-requests?companyId=${Auth.getCurrentUser()?.companyId}&isPending=true`)
            .then((response) => response.json())
            .then((response) => {
                setTimeRequests(response);
                console.log("Y", response)
                const pending = response.filter(x => x.job.id == selectedJobId && (x.productionId == null || x.productionId == 0) && moment(x.requestAt).isBetween(dateQuery[0], dateQuery[1], 'days', '[]'))
                const allPending = response.filter(x => x.job.id == selectedJobId);
                if (pending.length == 0) {
                    setMissingTimeCards(allPending)
                    setIsSuccess(true)
                }
                else {
                    setMissingTimeCards(allPending)
                    setIsSuccess(false)
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchJobs();
    }, [dateQuery, jobId]);

    const onFilterChange = () => {
        const values = form.getFieldsValue();
        console.log(form.getFieldsValue())
        if (values.jobId && (values.queryDate ?? dateQuery)) {
            setDateQuery(values.queryDate ?? dateQuery)
            setJobId(values.jobId)
            fetchTimeRequests(values.jobId);
        }
    }

    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {

    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Spread Report</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }} bordered={false}>

                <Form
                    form={form}
                    name='SpreadReportCheckForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}
                    onFieldsChange={onFilterChange}

                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]
                        }>
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                        >
                            {jobs?.map((job) => <Option value={job.id} key={job.id} > {job.name} - {job.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Date Range'
                        name='queryDate'
                        value={dateQuery}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>
                        <RangePicker
                            value={dateQuery}
                            format="YYYY-MM-DD"
                            defaultValue={[moment().add(-7, 'days').startOf('day'), moment().endOf('day')]}
                            disabledDate={d => !d || d.isAfter(moment())}
                        />
                    </Form.Item>
                </Form>

                <Button size="" disabled={!(jobId && dateQuery)} type="primary" href={`#/reports/spread-report/${jobId}?start=${moment(dateQuery[0]).utc().format()}&end=${moment(dateQuery[1]).utc().format()}`}>View Report</Button>
            </Card>


        </>)
}
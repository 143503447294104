import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, Layout, Modal, Radio, Card, Select, Collapse, Alert } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Auth from '../../Auth'
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

export const AssignEquipment = ({ location, setLoading }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');

    const [allEquipment, setAllEquipment] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJobs] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [availableEquipment, setAvailableEquipment] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAssigned, setIsAssigned] = useState(false);
    const jobIdQuery = new URLSearchParams(location.search).get('job_id');

    const fetchJobs = () => {
        setLoading(true)
        return fetch(`/api/jobs?companyId=${Auth.getCurrentUser().companyId}&includeEquipmentUtilization=true`)
            .then((response) => response.json())
            .then((jobs) => {
                setLoading(false)
                setJobs(jobs);
                console.log(jobs)
            })
            .catch(error => {
                setLoading(false)
            });
    }

    const fetchEquipment = () => {
        setLoading(true)
        return fetch(`/api/equipment?companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((equipment) => {
                setLoading(false);
                setEquipment(equipment);
                setAllEquipment(equipment)
            })
            .catch(error => {
                setLoading(false);

            });
    }

    useEffect(() => {
        fetchJobs();
        fetchEquipment();
    }, []);

    const onFinish = (values) => {
        var jobId = values.jobId;
        const data = { equipmentId: values.equipmentId, jobId: values.jobId, description: values.description }

        console.log(data);

        fetch(`api/jobs/${jobId}/equipment`, {
            method: 'POST', body: JSON.stringify(data), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                fetchJobs();
                setIsAssigned(true);
                form.resetFields();
            });
    };


    useEffect(() => {
        fetchJobs();
        fetchEquipment();
    }, []);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;


    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>Add Equipment to Job</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>
                {isAssigned && <Alert
                    message={`Successfully added selected equipment to job.`}
                    type="success"
                    closable
                    style={{ marginBottom: 20 }}
                />}

                <Form
                    name='NewUserJobAssignment'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}

                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        initialValue={jobIdQuery ? parseInt(jobIdQuery) : null}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                            defaultValue={selectedJob.id}
                            onChange={e => console.log(e)}
                            onSearch={e => console.log(e)}
                        >
                            {jobs.map((job) => <Option value={job.id} key={job.id} > {job.name} - {job.description} </Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Equipment'
                        name='equipmentId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}

                    >
                        <Select
                            showSearch
                            placeholder="Select Equipment"
                            optionFilterProp="children"
                            onChange={e => console.log(e)}
                            onFocus={e => {
                                
                                const selectedJob = jobs.find(x => x.id == form.getFieldValue("jobId"));
                                console.log("Selected Job", selectedJob);
                                if (selectedJob) {
                                    setEquipment(allEquipment)
                                    console.log(selectedJob);
                                    const equipmentIds = selectedJob.equipmentUtilization.map(x => x.equipmentId);
                                    console.log("Assigned", equipmentIds)
                                    const availableEquipment = allEquipment.filter(x => !equipmentIds.includes(x.id))
                                    console.log(availableEquipment)
                                    setEquipment(availableEquipment)
                                }
                                else {
                                    setEquipment([])
                                }
                            }}
                            onBlur={e => console.log(e)}
                            onSearch={e => console.log(e)}
                        >
                            {equipment.map((e) => <Option value={e.id} key={e.id} > {e.name} - {e.description} </Option>)}
                        </Select>
                    </Form.Item>


                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};
import jwtDecode from "jwt-decode"
import moment from "moment"

const Auth = {
    getLoginState: () => {
        const hasToken = localStorage.getItem("token") != null
        const token = localStorage.getItem("token")
        let tokenPayload = {};

        try {
            tokenPayload = jwtDecode(token);
        } catch {
            return false;
        }

        if (moment(tokenPayload.exp).isAfter(moment())) {
            localStorage.clear();
            window.location.reload();
            return false;
        }

        return true
    },
    getCurrentUser: () => {
        const token = localStorage.getItem("token")
        let tokenPayload = "";

        try {
            tokenPayload = jwtDecode(token)
        } catch {
            tokenPayload = {};
        }

        return {
            id: tokenPayload?.id,
            username: tokenPayload?.username,
            name: tokenPayload?.name,
            email: tokenPayload?.email,
            permissions: tokenPayload?.scopes,
            role: tokenPayload?.role,
            company: localStorage.getItem("company") ?? tokenPayload?.company,
            companyId: localStorage.getItem("companyId") ?? tokenPayload?.companyId,
            companyLogo: localStorage.getItem("companyLogo") ?? tokenPayload?.companyLogo,
        }
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.clear();
    },
    hasPermission: (permission) => {
        const token = localStorage.getItem("token")
        let tokenPayload = "";

        try {
            tokenPayload = jwtDecode(token)
        } catch {
            tokenPayload = {};
        }

        const scopes = tokenPayload?.scopes || []
        const evaluation = scopes.includes(permission)
        // console.log(`Permission "${permission}"`, evaluation)
        return evaluation;
    }
}

export default Auth
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu } from 'antd';
import exportFromJSON from 'export-from-json';
import React from 'react';
import Highlighter from 'react-highlight-words';

import Auth from '../../Auth'
const { confirm } = Modal;

export class ListCostCodes extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        costCodes: [],
        isLoaded: false,
        error: null,
        paginationSettings: { position: ["bottomLeft"] }
    };

    componentDidMount() {
        this.fetchCostCodes()
    }

    fetchCostCodes() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        fetch(`api/cost-codes?includeJob=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then(response => response.json())
            .then(
                (result) => {
                    const costCodes = result.map(e => ({ ...e, job: e.job?.name, key: e.id }));
                    this.setState({
                        costCodes: costCodes,
                    });
                    setLoading(false);
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDelete = costCode => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this cost code?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                const costCodeId = costCode.id;
                fetch(`api/cost-codes/${costCodeId}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        console.log(operation);
                        if (operation.isSuccessful) {
                            self.setState({
                                costCodes: self.state.costCodes.filter(function (costCode) {
                                    return costCode.id !== costCodeId
                                })
                            });

                            Modal.success({
                                content: 'Cost Code has been deleted.',
                            });
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        const columns = [
            {
                title: 'Cost Code',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                // fixed: 'left',
                width: 120,
            },
            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                ...this.getColumnSearchProps('job')
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                render: description => <span>{description?.length > 120 ? `${description.substr(0, 120)} ...` : description}</span>,
            },
            {
                title: 'Total Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Unit',
                dataIndex: 'unit',
                key: 'unit',
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Is to track production?',
                dataIndex: 'isToTrackProduction',
                key: 'isToTrackProduction',
                render: i => i ? <CheckOutlined /> : null
            },
            {
                title: 'Is to track employee and equipment time?',
                dataIndex: 'isToTrackEmployeeAndEquipmentTime',
                key: 'isToTrackEmployeeAndEquipmentTime',
                render: i => i ? <CheckOutlined /> : null

            },
            {
                title: 'Invoicing Information',
                dataIndex: 'invoicingInformation',
                key: 'invoicingInformation',
                ...this.getColumnSearchProps('invoicingInformation'),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (costCode) => {
                    return (
                        <Space size="small" wrap={true}>
                            {
                                Auth.hasPermission('cost_codes.update') &&
                                <Button size="small" type="primary" href={`#/cost-codes/${costCode.id}/edit`}>
                                    Edit
                                </Button>
                            }
                            {
                                Auth.hasPermission('cost_codes.delete') &&
                                <Button size="small" type="primary" danger onClick={() => this.handleDelete(costCode)}>
                                    Delete
                                </Button>
                            }
                        </Space>

                    )
                },
            },
        ];

        const self = this;

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Cost Codes</Breadcrumb.Item>
                    <Breadcrumb.Item>Search</Breadcrumb.Item>
                </Breadcrumb>

                <Table
                    rowKey={record => `cost-code-${record.id}`}
                    columns={columns}
                    dataSource={this.state.costCodes}
                    scroll={{ x: 1300 }}
                    pagination={self.state.paginationSettings}
                    footer={currentPageData => {

                        let data = this.state.costCodes;

                        function handleMenuClick(e) {
                            if (e.key.includes("filtered")) {
                                data = currentPageData;
                            }

                            let fileType = exportFromJSON.types.txt;

                            switch (e.key) {
                                case "csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                                case "filtered-csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "filtered-xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "filtered-json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "filtered-txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                            }

                            exportFromJSON({
                                data: data.map(({ job, key, ...items }) => items),
                                fileName: "cost-codes",
                                exportType: fileType
                            });
                        }

                        const menu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                <Menu.Item key="json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        const filteredMenu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        return (
                            <Space size="small">
                                <Dropdown overlay={menu}>
                                    <Button type="primary" ghost={true}>Download All</Button>
                                </Dropdown>
                                <Dropdown overlay={filteredMenu}>
                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                </Dropdown>
                            </Space>
                        );

                    }}
                />
            </div>
        );
    }
}
import React from 'react';

import { ExclamationCircleOutlined, GoldOutlined } from '@ant-design/icons';
import { Avatar, Badge, Breadcrumb, Tag, Button, Card, Descriptions, Divider, List, Modal, Space, Tabs, Typography, Collapse } from 'antd';
import { ListProductions } from '../production/ListProduction';
import { ListJobCostCodes } from './ListJobCostCodes';
import { ListJobEquipment } from './ListJobEquipment';
import moment from 'moment'
import Auth from '../../Auth'

const { Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export class ViewJob extends React.Component {

    state = {
        job: {},
        users: [],
        assigned: [],
        equipment: [],
        productions: [],
        timeRecords: [],
        isLoaded: false,
        visible: false,
        isMapVisible: false,
        mapPin: {}
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.setLoading(true);

        fetch(`api/jobs/${id}?includeAssignedUsers=true&includeCostCodes=true&includeEquipmentUtilization=true&includeProductions=true`)
            .then(jobResponse => jobResponse.json())
            .then(
                (jobResult) => {
                    fetch(`api/users?companyId=${Auth.getCurrentUser()?.companyId}`)
                        .then(response => response.json())
                        .then(
                            (result) => {
                                this.setState({
                                    users: result,
                                    isLoaded: true,
                                });

                                this.setState({
                                    job: jobResult,
                                    isLoaded: true,
                                });

                                this.setState({
                                    assigned: jobResult?.userAssignments?.map(x => x.user.id)
                                });

                                this.setState({
                                    equipment: jobResult?.equipmentUtilization?.map(x => { return { id: x.equipment.id, name: x.equipment.name, description: x.equipment.description, createdAt: x.createdAt } })
                                });

                                this.setState({
                                    productions: jobResult?.productions?.map(x => {
                                        return {
                                            key: x.id,
                                            id: x.id,
                                            costCode: `${x.costCode?.name}`,
                                            quantity: x.quantity,
                                            description: x.description,
                                            unit: x.costCode?.unit,
                                            createdAt: x.createdAt,
                                            producedAt: moment(x.producedAt).format("YYYY-MM-DD"),
                                            type: x.type,
                                            user: x.user?.name,
                                            costCodeObj: x.costCode,
                                            isMaterialHaulingEnabled: jobResult.isMaterialHaulingEnabled
                                        }
                                    })
                                })

                                console.log("PR", this.state.productions)

                                this.props.setLoading(false);
                            },
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error
                                });
                            }
                        );
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    removeUserAssignment(userId) {
        var jobId = this.state.job.id;
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to remove this employee from the job?',
            okText: 'Remove',
            cancelText: 'Cancel',
            onOk: e => {
                fetch(`api/jobs/${jobId}/assignments/${userId}`, {
                    method: 'DELETE', mode: 'cors'
                })
                    .then(result => {

                        Modal.success({
                            content: 'Employee has been removed from this job.',
                        });

                        window.location.reload(false);
                    });
            }
        });
    }

    render() {
        const job = this.state.job;

        var color = "yellow";
        var text = "Pending";

        if (job.status == "in_progress") {
            color = "blue";
            text = "In Progress"
        }

        if (job.status == "done") {
            color = "green";
            text = "Done"
        }

        const logsColumns = [
            {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                key: 'name',
                width: '20%',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            }
        ];

        const showModal = () => {
            this.setState({
                isMapVisible: true,
            });
        };

        const hideModal = () => {
            this.setState({
                isMapVisible: false,
            });
        };

        const assignedEmployeesTitle = `Assigned Employees (${this.state.job?.userAssignments?.length})`;
        const jobCostCodesTitle = `Cost Codes (${this.state.job?.costCodes?.length})`;
        const equipmentUtilizationTitle = `Equipment (${this.state.job?.equipmentUtilization?.length})`;

        const mapLocation = `https://maps.google.com/maps?q=${this.state.job?.location}&t=&z=15&ie=UTF8&iwloc=&output=embed`;

        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.job.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Space direction="vertical">
                    <Card title={
                        (
                            <Title level={5}>
                                {job.name} - {job.description}

                                {
                                    job.isMaterialHaulingEnabled &&
                                    <Tag style={{ marginLeft: 12 }} icon={<GoldOutlined />} color="yellow">
                                        Material Hauling
                                    </Tag>
                                }
                            </Title>
                        )
                    } extra={<Badge color={color} text={text} />}>

                        <Descriptions bordered>
                            <Descriptions.Item label="Location" span={1}>
                                {job.location}
                                <br />
                                <Button type="link" size="small" onClick={showModal} style={{ padding: 0, margin: 0 }}>View in Map</Button>
                                <Modal title={job?.location} visible={this.state.isMapVisible} style={{ top: 5 }} footer={null} onCancel={hideModal} width="100vw" height="100vh">
                                    <div class="gmap_canvas">
                                        <iframe width="100%" height="600" id="gmap_canvas" src={mapLocation} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                        </iframe>
                                    </div>
                                </Modal>
                            </Descriptions.Item>
                            <Descriptions.Item label="Owner">{job.owner}</Descriptions.Item>
                            <Descriptions.Item label="Project Span">{moment(job.startsAt).format("YYYY-MM-DD")} - {moment(job.endsAt).format("YYYY-MM-DD")}</Descriptions.Item>
                            <Descriptions.Item label="Project Manager" >{job.userAssignments?.find(x => x.assignment === "Project Management")?.user.name || "Unassigned"}</Descriptions.Item>
                            <Descriptions.Item label="Superintendent">{job.userAssignments?.find(x => x.assignment === "Site Superintendent")?.user.name || "Unassigned"}</Descriptions.Item>
                            <Descriptions.Item label="BC1 Call" span={2}>{job.bc1Call ? "Yes" : "No"}</Descriptions.Item>
                        </Descriptions>



                    </Card>
                    <Card
                        title="Production"
                        extra={
                            <Space>
                                <Button type="primary" href={`/#/jobs/productions/add?job_id=${this.state.job.id}`}>Add Production</Button>
                                {job.isMaterialHaulingEnabled && <Button type="primary" href={`/#/material-hauling/add?job_id=${this.state.job.id}`}>Add Material Hauling</Button>}
                                {job.isMaterialHaulingEnabled && <Button type="ghost" href={`/#/material-hauling/material-production-report?job_id=${this.state.job.id}`}>View Stockpile</Button>}
                            </Space>
                        }
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane
                                tab={
                                    <span>
                                        Productions
                                    </span>
                                }
                                key="1"
                            >
                                <ListProductions productions={this.state.productions} />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        Summary
                                    </span>
                                }
                                key="2"
                            >
                                <ListProductions productions={this.state.productions} isSummary={true} />
                            </TabPane>
                        </Tabs>

                    </Card>

                    <Card
                        title="Time Records"
                        extra={<Button type="primary" href={`/#/jobs/time-records/add?job_id=${this.state.job.id}`}>Add Time Records</Button>}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane
                                tab={
                                    <span>
                                        Time Records
                                    </span>
                                }
                                key="1"
                            >
                                <ListProductions productions={this.state.productions} type="Time Records" />
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        Summary
                                    </span>
                                }
                                key="2"
                            >
                                <ListProductions productions={this.state.productions} isSummary={true} type="Time Records" />
                            </TabPane>
                        </Tabs>

                    </Card>

                    <Card
                        title={assignedEmployeesTitle}
                        extra={<Button type="primary" href={`/#/jobs/users/add?job_id=${this.state.job.id}`}>Add Employee</Button>}


                    >
                        <div style={{
                            height: 400,
                            overflow: 'auto',
                        }}
                            id="scrollableCard">
                            <List
                                itemLayout=""
                                dataSource={this.state.job.userAssignments}
                                scrollableTarget
                                renderItem={assignment => (
                                    <List.Item
                                        actions={[<Button type="danger" onClick={e => this.removeUserAssignment(assignment.user.id)}>Remove</Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar style={{ backgroundColor: "orange", verticalAlign: 'middle' }} size="large"> {assignment.user.name[0]} </Avatar>}
                                            title={<a href="">{assignment.user.name}</a>}
                                            description={`${assignment.assignment ? assignment.assignment : ""}`}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                        {/* <Divider /> */}
                        {/*<AddUserJobAssignment users={this.state.users} jobId={this.state.job.id} assigned={this.state.assigned} />*/}
                    </Card>

                    <Card
                        title={jobCostCodesTitle}
                        extra={<Button type="primary" href={`/#/cost-codes/add?job_id=${this.state.job.id}`}>Add Cost Code</Button>}
                    >
                        <ListJobCostCodes costCodes={this.state.job?.costCodes} />
                        <Divider />
                    </Card>

                    <Card
                        title={equipmentUtilizationTitle}
                        extra={<Button type="primary" href={`/#/jobs/equipment/add?job_id=${this.state.job.id}`}>Add Equipment</Button>}
                    >
                        <ListJobEquipment equipment={this.state.equipment} jobId={this.state.job.id} setLoading={this.props.setLoading} />
                        <Divider />
                    </Card>

                    {/*<Card title="Activity Log">*/}

                    {/*</Card>*/}
                </Space>
            </>
        );
    }
}
import React, { useState } from 'react';
import { Table, Input, Button, Space, Breadcrumb, Card, DatePicker, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import moment from 'moment';
import Auth from '../../Auth';
const { RangePicker } = DatePicker;

export class ListProductions extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        productions: [],
        isLoaded: false,
        job: {},
        error: null,
        filteredProductions: [],
        dateRange: [],
    };

    componentDidMount() {
    }

    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        setSelectedKeys([e])
                        console.log("Selected Key", selectedKeys)
                    }
                    }
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>


                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let productions = this.props.productions;

        const dateRange = this.state.dateRange;
        if (dateRange?.length == 2) {
            const start = dateRange[0];
            const end = dateRange[1];
            productions = this.props.productions.filter(x => {
                start.set({ hour: 0 })
                end.set({ hour: 10 })

                let current = moment(x.producedAt);
                current.set({ hour: 5 });

                console.log("========")
                console.log({ start, end, current })

                console.log("========")

                return current.isBetween(start, end, undefined, '[]')

            })
        }

        let isMaterialHaulingEnabled = productions[0]?.isMaterialHaulingEnabled;

        console.log("Filtered Productions", productions)
        console.log("Productions Original", this.props.productions)


        if (this.props.type == "Time Records") {
            console.log("TR", productions)
            productions = productions.filter(x => x.type == "Time Record")

        }
        else {
            if (isMaterialHaulingEnabled) productions = productions.filter(x => x.type == "Material Hauling" || x.type == "Production" || !x.type)
            else productions = productions.filter(x => x.type == "Production" || !x.type)
        }

        const summarizedProductions = [];

        productions.reduce(function (res, value) {
            if (!res[value.costCode]) {
                res[value.costCode] = { costCode: value.costCode, quantity: 0, unit: value.unit, totalQuantity: value.costCodeObj.quantity };
                summarizedProductions.push(res[value.costCode])
            }
            res[value.costCode].quantity += value.quantity;
            return res;
        }, {});

        let columns = [
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                key: 'costCode',
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                ...this.getColumnSearchProps('quantity'),
            },
            {
                title: 'Employee',
                dataIndex: 'user',
                key: 'user',
                ...this.getColumnSearchProps('user'),
            },
            {
                title: 'UoM',
                dataIndex: 'unit',
                key: 'unit',
                ...this.getColumnSearchProps('unit'),
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                render: (date) => moment(date).format("YYYY-MM-DD"),
                ...this.getDateColumnSearchProps('producedAt'),
            },
            {
                title: 'Notes',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
            },
        ];

        if (this.props.type == "Time Records") {
            columns = [
                {
                    title: 'Cost Code',
                    dataIndex: 'costCode',
                    key: 'costCode',
                    ...this.getColumnSearchProps('costCode'),
                },
                {
                    title: this.props.isSummary ? 'Total Hours' : 'Hours',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    ...this.getColumnSearchProps('quantity'),
                },
                {
                    title: 'Employee',
                    dataIndex: 'user',
                    key: 'user',
                    ...this.getColumnSearchProps('user'),
                },
                {
                    title: 'Date',
                    dataIndex: 'producedAt',
                    key: 'producedAt',
                    render: (date) => moment(date).format("YYYY-MM-DD"),
                    ...this.getDateColumnSearchProps('producedAt'),
                },
                {
                    title: 'Notes',
                    dataIndex: 'description',
                    key: 'description',
                    ...this.getColumnSearchProps('description'),
                },
            ];

        }

        if (this.props.isSummary) {
            columns = columns.filter(x => x.key != 'description')
            columns = columns.filter(x => x.key != 'producedAt')
            columns = columns.filter(x => x.key != 'user')
        }

        if (this.props.isSummary && this.props.type != "Time Records") {

            columns.push({
                title: "Contract Quantity",
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                ...this.getColumnSearchProps('totalQuantity'),
            })

            columns.push({
                title: "Percent Completed",
                key: "percentCompleted",
                render: (p, record) => {
                    const percentCompleted = (record.quantity / record.totalQuantity) * 100
                    return `${parseFloat(percentCompleted).toFixed(2)}%`
                }
            })
        }

        // if (isMaterialHaulingEnabled) {
        //     columns = columns.filter(x => x.key != 'description')
        //     columns = columns.filter(x => x.key != 'totalQuantity')
        //     columns = columns.filter(x => x.key != 'unit')
        //     columns = columns.filter(x => x.key != 'user')
        //     columns = columns.filter(x => x.key != 'percentCompleted')
        // }

        return (
            <>
                {this.props.isSummary &&
                    <>
                        <Card style={{ width: "100%" }}>
                            Filter by Date Range: <RangePicker
                                format="YYYY-MM-DD"
                                onChange={(e) => {
                                    this.setState({ dateRange: e })
                                }} />
                        </Card>
                    </>}

                <Table columns={columns} dataSource={!this.props.isSummary ? productions : summarizedProductions} pagination={{ position: ["bottomLeft"] }} />
            </>
        );
    }
}
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Card, ConfigProvider as ConfigProvider5, DatePicker, Form, Input, InputNumber, Modal, Select, Row, Col, Typography, Space } from 'antd-v5';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
import { MobileNav } from './Nav';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;

export const FormTimeRequests = ({ setLoading, location }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [equipment, setEquipment] = useState([])
    const [value, setValue] = useState();

    const prefillJobId = new URLSearchParams(location.search).get("jobId");
    const prefillUserId = new URLSearchParams(location.search).get("userId");
    const prefillProducedAt = new URLSearchParams(location.search).get("producedAt");
    const prefillProducedEndsAt = new URLSearchParams(location.search).get("producedEndsAt");
    const prefillWorkType = new URLSearchParams(location.search).get("workType");
    const prefillDescription = new URLSearchParams(location.search).get("description");
    const productionRequestId = new URLSearchParams(location.search).get("productionRequestId");

    const [production, setProduction] = useState();
    const [job, setJob] = useState([]);
    const [workTypeList, setWorkTypeList] = useState([])
    const [computedHours, setComputedHours] = useState(0);

    const [producedDate, setProducedDate] = useState();
    const [producedTimeStart, setProducedTimeStart] = useState();
    const [producedTimeEnd, setProducedTimeEnd] = useState();

    const [workType, setWorkType] = useState({});
    const fetchJob = async () => {
        setLoading(true);
        await fetch(`/api/jobs/${prefillJobId}?includeCostCodes=true&includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}&includeEquipmentUtilization=true`)
            .then((response) => response.json())
            .then((jobResult) => {
                setJob(jobResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });

        setLoading(true)
        await fetch(`/api/work-types`)
            .then((response) => response.json())
            .then((workTypeResult) => {
                setWorkTypeList(workTypeResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });

    }

    const onValuesChange = (changedValues) => {
        console.log({ changedValues })
    }

    const onWorkTypeSelect = (workTypeId) => {
        const selectedWorkType = workTypeList.find(x => x.id == workTypeId)
        setWorkType(selectedWorkType)
    }

    const computeHours = () => {
        const values = form.getFieldsValue();
        if (values.producedDate && values.producedTimeStart && values.producedTimeEnd) {
            const start = dayjs(`${values.producedDate}T${values.producedTimeStart}`);
            const end = dayjs(`${values.producedDate}T${values.producedTimeEnd}`);
            setComputedHours(end.diff(start, "hours", true).toFixed(2));
        }
    }

    const onFinish = (values) => {
        const start = dayjs(`${producedDate}T${producedTimeStart}`).toISOString();
        const end = dayjs(`${producedDate}T${producedTimeEnd}`).toISOString();
        var newRecord = {
            type: "Time Record",
            quantity: computedHours,
            producedAt: start,
            producedEndsAt: end,
            userId: prefillUserId,
            jobId: prefillJobId,
            productionRequestId: productionRequestId,
            productionEquipments: values.productionEquipments,
            costCodeId: values.costCodeId,
            workTypeId: values.workTypeId,
            description: values.description,
        }

        fetch(`api/jobs/${prefillJobId}/productions`, {
            method: 'POST', body: JSON.stringify(newRecord), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Time Record has been added successfully.',
                    onOk: () => window.location.reload(),
                    onCancel: () => history.push("/app/my-records"),
                    okText: "Add More",
                    cancelText: "Leave"
                });

                form.resetFields()
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {
        fetchJob();
    }, [prefillJobId]);

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <ConfigProvider5 prefixCls="ant5">
            <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={24}>
                    <Text type="secondary">Time-in</Text>
                    <Title level={4} style={{ marginTop: 8 }}>Submit Time Record</Title>
                </Col>
            </Row>
            <Card
                style={{
                    marginTop: 16,
                    maxWidth: 800,
                    minWidth: "90vw",
                    minHeight: "100vh"
                }}>
                <Form
                    name='FormProduction'
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    onValuesChange={onValuesChange}
                >
                    {/* 
                        <Form.Item
                            name="producedAtRange"
                            label="Start & End Time"
                            required
                        >
                            <RangePicker
                                minDate={dayjs(prefillProducedAt).startOf("day")}
                                maxDate={dayjs(prefillProducedEndsAt).endOf("day")}
                                showTime={{
                                    format: 'HH:mm',
                                }}
                                onChange={(dates) => {
                                    const startAt = dayjs(dates[0]);
                                    const endAt = dayjs(dates[1]);
                                    setComputedHours(endAt.diff(startAt, 'hour', true).toFixed(2));
                                }}
                            />
                        </Form.Item>
                    </Badge> */}
                    <Form.Item
                        label="Start & End Time"
                        required
                        name="Time"
                        rules={[{
                            message: 'End time must be less than start time.',
                            validator: (_, value) => {
                                const values = form.getFieldsValue();
                                console.log({values})

                                if (!values.producedDate || !values.producedTimeStart || !values.producedTimeEnd) {
                                    return Promise.reject('Date/Time range is invalid.');
                                }


                                const start = dayjs(`${values.producedDate}T${values.producedTimeStart}`);
                                const end = dayjs(`${values.producedDate}T${values.producedTimeEnd}`);

                                if (start.isAfter(end)) {
                                    return Promise.reject('Date/Time range is invalid.');
                                }

                                return Promise.resolve();
                            }
                        }
                        ]}
                        onChange={computeHours}
                    >
                        <Badge
                            count={`${computedHours} hours`}
                            style={{
                                backgroundColor: '#52c41a',
                            }}
                            offset={[10, 0]}>
                            <Space.Compact>
                                <Form.Item
                                    style={{ display: 'inline-block', margin: 0 }}
                                    name="producedDate">
                                    <Input
                                        type="date" onChange={(e) => setProducedDate(e.target.value)}
                                        min={dayjs(prefillProducedAt).format("YYYY-MM-DD")}
                                        max={dayjs(prefillProducedEndsAt).format("YYYY-MM-DD")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'inline-block', margin: 0 }}
                                    name="producedTimeStart">
                                    <Input
                                        type="time"
                                        onChange={(e) => setProducedTimeStart(e.target.value)}
                                        required
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'inline-block', margin: 0 }}
                                    name="producedTimeEnd">
                                    <Input
                                        type="time"
                                        onChange={(e) => setProducedTimeEnd(e.target.value)}
                                        required
                                        min={producedTimeStart}
                                    />
                                </Form.Item>
                            </Space.Compact>
                        </Badge>
                    </Form.Item>
                    <Form.Item
                        label='Cost Code'
                        name='costCodeId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Cost Code"
                            optionFilterProp="children"
                        >
                            {
                                job?.costCodes?.filter(x => x.isToTrackEmployeeAndEquipmentTime).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>)
                            }

                        </Select>
                    </Form.Item>


                    <Form.Item
                        label='Type of Work'
                        name='workTypeId'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        initialValue={prefillWorkType}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Work Type"
                            optionFilterProp="children"
                            onChange={onWorkTypeSelect}
                        >
                            {workTypeList?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Comments'
                        name='description'
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        initialValue={prefillDescription}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    {(workType?.isEquipmentRequired || production?.productionEquipments?.length > 0) &&
                        <Form.List name="productionEquipments">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <Card key={field.key} style={{ marginBottom: 24 }}>
                                            <Form.Item
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                }
                                                style={{
                                                    margin: 0
                                                }}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Equipment"
                                                        name={[field.name, 'equipmentId']}
                                                        fieldKey={[field.fieldKey, 'equipmentId']}
                                                        rules={[{ required: true, message: 'Required' }]}
                                                    >
                                                        <Select>
                                                            {
                                                                job?.equipmentUtilization.map(x => x.equipment).map(item => (
                                                                    <Option key={item.name} value={item.id}>
                                                                        {item.name}
                                                                    </Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Hours"
                                                name={[field.name, 'hoursUsed']}
                                                fieldKey={[field.fieldKey, 'hoursUsed']}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                label="Fuel (L)"
                                                name={[field.name, 'litersOfFuel']}
                                                fieldKey={[field.fieldKey, 'litersOfFuel']}
                                                rules={[{ required: false, message: 'Required' }]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                label="Notes"
                                                name={[field.name, 'notes']}
                                                fieldKey={[field.fieldKey, 'notes']}
                                                rules={[{ required: false, message: 'Required' }]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>

                                            <Button block type="dashed" danger icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}>Remove</Button>

                                        </Card>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Equipment Usage
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    }
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit & Add New
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <MobileNav />
        </ConfigProvider5 >
    );
};
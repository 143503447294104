import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, InputNumber, Layout, Modal, Select, Card, Divider, Checkbox } from 'antd';
import styled from 'styled-components'

export const FormRole = ({ setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [role, setRole] = useState({});

    const isEditMode = uriParams?.id != null && uriParams.id > 0;

    const onFinish = (values) => {
        var p = selectedPermissions.map(x => { return { name: x } });
        var role = { ...values, permissions: p }

        if (isEditMode) {
            updateRole(role);
        }
        else {

            fetch('/api/roles?includePermissions=true', {
                method: 'POST', body: JSON.stringify(role), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'Role  has been added successfully.',
                    });

                    history.push('/roles');
                });
        }

    };

    const fetchRole = () => {
        const id = uriParams.id;
        fetch(`/api/roles/${id}`)
            .then(response => response.json())
            .then(role => {
                form.setFieldsValue(role);
                setRole(role);
                console.log(role)
                setSelectedPermissions(role.permissions.map(x => x.name));
            })
            .then(() => {
                //setLoading(false)
            })

    }

    const updateRole = (role) => {
        const id = uriParams.id;
        fetch(`/api/roles/${id}`, {
            method: 'PUT', body: JSON.stringify(role), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Role  has been updated successfully.',
                });

                history.push('/roles');
            });
    }

    const fetchDefinedPermissions = () => {

        fetch('/api/role-permissions')
            .then(response => response.json())
            .then(result => {
                setPermissions(result)
            })
            .then(() => {

            })
    }

    const onFinishFailed = (errorInfo) => {

        console.log('Failed:', errorInfo);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    const onChange = (checkedValues) => {
        setSelectedPermissions(checkedValues)
    }

    useEffect(() => {
        fetchDefinedPermissions();
        if (isEditMode) fetchRole();
    }, []);

    const ColumnCheckbox = styled(Checkbox.Group)`
        .ant-checkbox-group-item {
            margin-bottom: 6px;
            width: 100%
        }`

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Roles</Breadcrumb.Item>
                <Breadcrumb.Item>New</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }}>
                <Form
                    form={form}
                    name='FormRole'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'

                >
                    <Form.Item
                        label='Role Name'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Divider />
                    <ColumnCheckbox label="Permissions" name="permissions" style={{ marginBottom: '24px' }} options={permissions} onChange={onChange} defaultValue={selectedPermissions} value={selectedPermissions} />

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu, Select, Divider, notification, Result, Switch, List, Card, Form, DatePicker } from 'antd';
import { Typography } from 'antd';
import exportFromJSON from 'export-from-json';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import Auth from '../../Auth';

const { Text, Link } = Typography;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;

const EditableContext = React.createContext(null);

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        placement: 'topLeft'
    });
};

export class TimeForEmployeesReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        productionRecords: [],
        jobId: this.props.match.params.id,
        timeRecords: [],
        jobs: [],
        isLoaded: false,
        error: null,
        job: {},
        paginationSettings: { position: ["bottomLeft"] }
    };

    componentDidMount() {
        this.fetchProduction()
    }

    fetchProduction() {
        const setLoading = this.props.setLoading;
        setLoading(true);

        fetch(`api/jobs?includeJob=true&includeProductions=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then(response => response.json())
            .then(
                (jobs) => {
                    console.log("Jobs", jobs)
                    const result = jobs.map(x => { return x.productions.flat(1) })
                    console.log("Result", result.flat(1))
                    let production = result.flat(1).map(e => ({ ...e, key: e.id }));
                    console.log("Productions", production)
                    const query = new URLSearchParams(this.props.location.search);
                    // let date = moment(query.get('date', "YYYY-MM-DD"));

                    // if (date.isValid()) {
                    //     production = production.filter(x => moment(x.producedAt).isSame(date, 'day'));
                    // }
                    this.setState({
                        production: production,
                    });

                    let mappedTimeRecords = production.map(x => {
                        return {
                            job: x.costCode?.job?.name,
                            key: x.id,
                            costCode: x.costCode?.name,
                            costCodeDescription: x.costCode?.description,
                            producedAt: x.producedAt,
                            quantity: x.quantity,
                            description: x.description,
                            workType: x.workType?.name,
                            isApproved: x.isApproved,
                            productionEquipments: x.productionEquipments,
                            type: x.type,
                            name: `${x.user?.name} (${x.user?.employeeCode})`,
                            user: x.user,
                            costCodeObj: x.costCode,
                            jobObj: x.costCode?.job,
                            employeeName: x.user?.name,
                            employeeCode: x.user?.employeeCode,
                            isOnPayroll: x.user?.isOnPayroll,
                            exportedAt: x.exportedAt,
                            id: x.id,
                            equipmentHours: x.productionEquipments?.reduce((accum, item) => accum + item.hoursUsed, 0)
                        }
                    })

                    mappedTimeRecords = mappedTimeRecords.filter(x => x.type == "Time Record");

                    const productionRecords = production?.filter(x => x.costCode?.isToTrackProduction);
                    let mappedProductionRecords = productionRecords.map(x => {
                        return {
                            job: x.costCode?.job?.name,
                            key: x.id,
                            costCode: x.costCode.name,
                            producedAt: moment(x.producedAt),
                            quantity: x.quantity,
                            unit: x.costCode.unit,
                            description: x.description,
                            type: x.type,
                            name: x.user?.name
                        }
                    })

                    mappedProductionRecords = mappedProductionRecords.filter(x => x.type == null)

                    this.setState({
                        timeRecords: mappedTimeRecords,
                        productionRecords: mappedProductionRecords
                    })

                    setLoading(false)

                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex} `}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    handleDelete = record => {
        const self = this;

        Modal.confirm({
            title: 'Are you sure delete this record?',
            icon: <ExclamationCircleOutlined />,
            content: 'Click "Yes" to continue.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                fetch(`api/jobs/productions/${record.key}`, {
                    method: 'DELETE', mode: 'cors', headers: { 'Content-Type': 'application/json' }
                })
                    .then(response => response.json())
                    .then(operation => {
                        if (operation.isSuccessful) {
                            // self.fetchProduction();

                            Modal.success({
                                title: 'Success',
                                content: 'Record has been deleted.',
                                icon: <CheckOutlined />,
                                okText: 'Yes',
                            })

                            self.setState({
                                timeRecords: self.state.timeRecords.filter(x => x.key != record.key)
                            })
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        setSelectedKeys([e])
                        console.log("Selected Key", selectedKeys)
                    }
                    }
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    render() {
        let timeColumns = [
            {
                title: 'Employee Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => ('' + a.name).localeCompare(b.name),
                ...this.getColumnSearchProps('name'),

            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                sortDirections: ['descend', 'ascend'],
                ...this.getDateColumnSearchProps('producedAt'),
                render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
                width: 150,
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => ('' + a.producedAt).localeCompare(b.producedAt),
            },
            {
                title: 'Hours',
                dataIndex: 'quantity',
                key: 'quantity',
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => text > 0 ? <Text strong type="success">{text}</Text> : <Text strong type="secondary">{text}</Text>

                // editable: true
            },
            {
                title: 'Hours for Equipment',
                dataIndex: 'equipmentHours',
                key: 'equipmentHours',
                sortDirections: ['descend', 'ascend'],
                render: ((p, record) => {

                    return p > 0 ? <Text strong type="warning">{p}</Text> : ""
                })
            },
            {
                title: 'Type of Work',
                dataIndex: 'workType',
                ...this.getColumnSearchProps('workType'),
                key: 'workType',
                sorter: (a, b) => ('' + a.workType).localeCompare(b.workType),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Comments',
                dataIndex: 'description',
                ...this.getColumnSearchProps('description'),
                sorter: (a, b) => ('' + a.description).localeCompare(b.description),
                sortDirections: ['descend', 'ascend'],
                // editable: true,
                key: 'description',
                render: note => <span>{note?.length > 500 ? `${note.substr(0, 500)} ...` : note}</span>,
            },
            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                ...this.getColumnSearchProps('job'),
                sorter: (a, b) => ('' + a.job).localeCompare(b.job),
                sortDirections: ['descend', 'ascend']
                // editable: true,
                // render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                sorter: (a, b) => ('' + a.costCode).localeCompare(b.costCode),
                sortDirections: ['descend', 'ascend'],
                key: 'costCode',
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Cost Code Description',
                dataIndex: 'costCodeDescription',
                sorter: (a, b) => ('' + a.costCodeDescription).localeCompare(b.costCodeDescription),
                sortDirections: ['descend', 'ascend'],
                key: 'costCodeDescription',
                ...this.getColumnSearchProps('costCodeDescription'),
            },
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => ('' + a.id).localeCompare(b.id),
                sortDirections: ['descend', 'ascend'],
                key: 'id',
                ...this.getColumnSearchProps('id'),
            },
            {
                title: 'Approved',
                dataIndex: 'isApproved',
                // ...this.getColumnSearchProps('isApproved'),
                // editable: true,
                key: 'isApproved',
                filters: [
                    {
                        text: <span>Yes</span>,
                        value: true,
                    },
                    {
                        text: <span>N/P</span>,
                        value: null,
                    },
                    {
                        text: <span>No</span>,
                        value: false,
                    },
                ],
                onFilter: (value, record) => record.isApproved == value,
                filterSearch: (input, record) => record.isApproved.indexOf(input) > -1,
                render: e => <span>{e == null ? "N/P" : (e ? "Yes" : "No")}</span>,
            },
            {
                title: 'On Payroll',
                dataIndex: 'isOnPayroll',
                // ...this.getColumnSearchProps('isApproved'),
                // editable: true,
                key: 'isOnPayroll',
                filters: [
                    {
                        text: <span>Yes</span>,
                        value: true,
                    },
                    {
                        text: <span>No</span>,
                        value: false,
                    },
                ],
                onFilter: (value, record) => record.isOnPayroll == value,
                filterSearch: (input, record) => record.isOnPayroll.indexOf(input) > -1,
                render: e => <span>{e ? "Yes" : "No"}</span>,
            },
            {
                title: 'Exported',
                dataIndex: 'exportedAt',
                // ...this.getColumnSearchProps('description'),

                // editable: true,
                key: 'exportedAt',
                filters: [
                    {
                        text: <span>Exported</span>,
                        value: true,
                    },
                    {
                        text: <span>Pending</span>,
                        value: false,
                    },
                ],
                onFilter: (value, record) => !!record.exportedAt == value,
                filterSearch: (input, record) => record.isApproved.indexOf(input) > -1,
                // render: e => <span>{e == null ? "N/P" : (e ? "Yes" : "No")}</span>,
                render: e => <span>{e ? `Exported` : "Pending"}</span>,
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    return (
                        <Space size="small" wrap={true}>

                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                            <Button size="small" type="primary" onClick={() => window.open(`#/jobs/time-records/${record.key}`)}>
                                Edit
                            </Button>
                        </Space >

                    )
                },
            },
        ];


        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Timecards Report</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.job.name}</Breadcrumb.Item>
                </Breadcrumb>

                {this.state.production?.length ?
                    <>
                        <Card title="Time Records">
                            <Table
                                // components={timeRecordComponent}
                                // rowClassName={() => 'editable-row'}
                                rowKey={record => `production - ${record.key} `}
                                columns={timeColumns}
                                dataSource={this.state.timeRecords}
                                scroll={{ x: 1300 }}
                                pagination={{
                                    defaultPageSize: 100,
                                    pageSizeOptions: [50, 100, 500, 1000, 1500]
                                }}
                                summary={(currentPageData) => {
                                    console.log(currentPageData)
                                    return (
                                        <Table.Summary fixed>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}><strong>{currentPageData.reduce((a, b) => a + b.quantity, 0).toFixed(2)}</strong></Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    )
                                }
                                }
                                footer={currentPageData => {

                                    let data = this.state.timeRecords.map((x) => {
                                        return {
                                            "Employee Code": x.employeeCode,
                                            "Employee Name": x.employeeName,
                                            "Date": moment(x.producedAt).format("YYYY-MM-DD"),
                                            "Hours": x.quantity,
                                            "Hours for Equipment": x.equipmentHours,
                                            "Type of Work": x.workType,
                                            "Job Number": x.job,
                                            "Timecard_ID": x.id,
                                            "Comments": x.description ?? ""

                                        }
                                    })

                                    function handleMenuClick(e) {
                                        if (e.key.includes("filtered")) {
                                            data = currentPageData.map((x) => {
                                                return {
                                                    "Employee Code": x.employeeCode,
                                                    "Employee Name": x.employeeName,
                                                    "Date": moment(x.producedAt).format("YYYY-MM-DD"),
                                                    "Hours": x.quantity,
                                                    "Hours for Equipment": x.equipmentHours,
                                                    "Type of Work": x.workType,
                                                    "Job Number": x.job,
                                                    "Job Description": x.jobObj.description,
                                                    "Cost Code Number": x.costCode,
                                                    "Cost Code Name": x.costCodeDescription,
                                                    "Timecard_ID": x.id,
                                                    "Comments": x.description ?? ""

                                                }
                                            });
                                        }

                                        let fileType = exportFromJSON.types.txt;

                                        switch (e.key) {
                                            case "csv":
                                                fileType = exportFromJSON.types.csv;
                                                break;
                                            case "xls":
                                                fileType = exportFromJSON.types.xls;
                                                break;
                                            case "json":
                                                fileType = exportFromJSON.types.json;
                                                break;
                                            case "txt":
                                                fileType = exportFromJSON.types.txt;
                                                break;
                                            case "filtered-csv":
                                                fileType = exportFromJSON.types.csv;
                                                break;
                                            case "filtered-xls":
                                                fileType = exportFromJSON.types.xls;
                                                break;
                                            case "filtered-json":
                                                fileType = exportFromJSON.types.json;
                                                break;
                                            case "filtered-txt":
                                                fileType = exportFromJSON.types.txt;
                                                break;
                                        }

                                        exportFromJSON({
                                            data: data.map(({ job, key, ...items }) => items),
                                            fileName: "employee-timecards-report",
                                            exportType: fileType
                                        });
                                    }

                                    const menu = (
                                        <Menu onClick={handleMenuClick}>
                                            <Menu.Item key="csv">Export as CSV</Menu.Item>
                                            <Menu.Item key="xls">Export as XLS</Menu.Item>
                                            <Menu.Item key="json">Export as JSON</Menu.Item>
                                        </Menu>
                                    );

                                    const filteredMenu = (
                                        <Menu onClick={handleMenuClick}>
                                            <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                            <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                            <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                                        </Menu>
                                    );

                                    return (
                                        <Space size="small">

                                            <Dropdown overlay={menu}>
                                                <Button type="primary" ghost={true}>Download All</Button>
                                            </Dropdown>
                                            <Dropdown overlay={filteredMenu}>
                                                <Button type="primary" ghost={true}>Download Filtered</Button>
                                            </Dropdown>
                                        </Space>
                                    );

                                }}
                            />
                        </Card>


                    </>

                    :

                    <Card>

                        <Result
                            title="No Records Found"
                            extra={
                                <Button type="primary" href="#/reports/foreman-report">
                                    Go Back
                                </Button>
                            }
                            style={{ opacity: this.state.job?.name && this.state.production?.length > 0 ? 100 : 100 }}
                        />
                    </Card>
                }


            </div>
        );
    }
}
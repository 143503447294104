import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, Layout, Modal, InputNumber, Card, DatePicker, Switch, Select, Radio } from 'antd';
import moment from "moment"
import Auth from '../../Auth';
const { Option } = Select;
const { Content } = Layout;

const equipmentClasses = [
    "Attachment",
    "Backhoe",
    "Blower",
    "Compressor",
    "Conveyor",
    "Crusher",
    "Dozer",
    "Excavator",
    "Gensets/Fusing machine",
    "Grinder",
    "Job containers/Site office",
    "Light tower/Message board",
    "Loaders",
    "Other vehicle",
    "Pick-up",
    "Plate tamper",
    "Rock truck",
    "Roller",
    "Screener",
    "Skid Steer",
    "Surveyor equipment",
    "Trailer",
    "Truck"
];

export const FormEquipment = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [equipment, setEquipment] = useState({});
    const isEditMode = uriParams?.id != null && uriParams.id > 0;

    const [isActive, setIsActive] = useState(false)
    const [isRental, setIsRental] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [listEquipmentCategory, setListEquipmentCategory] = useState([]);

    const onFinish = (equipment) => {

        console.log(equipment)


        if (isEditMode) {
            return updateEquipment(equipment);
        }
        else {
            return createEquipment(equipment);
        }
    };

    const createEquipment = (values) => {
        values.isActive = isActive;
        values.isRental = isRental;
        setLoading(true);

        values.companyId = Auth.getCurrentUser()?.companyId

        fetch(`api/equipment`, {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Equipment has been added successfully.',
                });

                setLoading(false);
                history.push('/equipment');
            });
    }

    const fetchCategories = () => {
        setLoading(true)
        fetch(`/api/equipment/categories`)
            .then((response) => response.json())
            .then((categories) => {
                setListEquipmentCategory(categories)
                setLoading(false)
            });
    }

    const updateEquipment = (values) => {
        values.isActive = isActive;
        values.isRental = isRental;
        setLoading(true);

        fetch(`api/equipment/${equipment.id}`, {
            method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Equipment has been updated.',
                });

                setLoading(false);
                history.push('/equipment');
            });
    }

    // const listEquipmentCategory = ["Rock Truck 30t", "Rock breaker 350", "Rock breaker backhoe", "Rock breaker 200", "Backhoe", "Jaw Crusher", "Cone Crusher", "John Deere", "John Deere GPS", "Tandem truck", "Mini Excavator", "Excavator 135", "Excavator 170", "Excavator 200", "Excavator 200 GPS", "Excavator 350", "Excavator 350 GPS", "Excavator 450", "Fusing Equipment", "Genset", "WoodGrinder 4000 Diamond Z Horizontal", "Grader", "Light tower", "Loader 380", "Loader 500", "Loader volvo", "loader cat", "Mole", "Other - Vehicle - Pickup Truck 1/2 - 3/4tn (LO)", "Emergency Vehicle - Emergency Transport Vehicle (ETV)", "1 tonne pick up", "Welding Truck", "1 tonne roller", "5 tonne roller", "10 tonne roller", "Screener", "Star screener", "Skidsteer", "SUV", "Rover", "Total Station", "5 Tonne Flat deck", "Trailer 45FT", "Step deck", "Chip Trailer Northern", "End dump", "Trailer- 30Ton Felling EQP", "Lowbedding  - 7 Axel (AF)", "Pony", "Side dumps", "Trailer", "Transfer", "Tractor truck for lowbed", "Foreman van", "water truck"]

    const fetchEquipment = () => {
        if (!isEditMode) {

            return;
        }

        setLoading(true);

        fetch(`/api/equipment/${uriParams.id}`)
            .then((response) => response.json())
            .then((equipment) => {

                equipment.year = equipment.year ? moment(equipment.year) : moment()
                equipment.acquiredAt = equipment.acquiredAt ? moment(equipment.acquiredAt) : moment()
                equipment.endOfTerm = equipment.endOfTerm ? moment(equipment.endOfTerm) : moment()

                setIsActive(equipment.isActive)
                setIsRental(equipment.isRental);

                form.setFieldsValue(equipment);

                setEquipment(equipment);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const validateEquipmentName = (e) => {
        console.log(`Validating Name: ${e?.target?.value ?? e} | ${equipment.name}`)
        if ((e?.target?.value ?? e) == equipment.name && isEditMode) {
            setIsValidating('success');
            return;
        }
        setIsValidating('validating')
        fetch(`/api/equipment?name=${e.target.value}&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((existing) => {
                if (existing.length > 0) setIsValidating('error');
                else setIsValidating('success')
            })
            .catch(error => {
                setIsValidating('error');
            });;
    }

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    useEffect(() => {
        fetchCategories()
        fetchEquipment()

        if (isEditMode) {
            const name = equipment.name;
            console.log("Name", name)
            validateEquipmentName(name);
        }
    }, []);

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Equipment</Breadcrumb.Item>
                <Breadcrumb.Item>{isEditMode ? `Edit ${equipment?.name}` : "New"}</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ maxWidth: 500 }}>
                <Form
                    form={form}
                    name='EquipmentForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'

                >
                    <Form.Item
                        label='Equipment No.'
                        name='name'
                        validateStatus={isValidating}
                        help={isValidating == 'error' ? "Please enter a unique value." : null}
                        hasFeedback
                        onBlur={validateEquipmentName}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                            {
                                max: 9
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Description'
                        name='description'
                        rules={[
                            {
                                required: true,
                                message: 'Enter short description for the equipment.',
                            },
                            {
                                max: 30
                            }
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        label='Category'
                        name='equipmentCategoryId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a category"
                            optionFilterProp="children"
                        >
                            {listEquipmentCategory.map((x) => <Option value={x.id} key={x.id} >{x.name}</Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Class'
                        name='class'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a class"
                            optionFilterProp="children"
                        >
                            {equipmentClasses.map((x) => <Option value={x} key={x} >{x}</Option>)}

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Make'
                        name='make'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Year'
                        name='year'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <DatePicker picker="year" format="YYYY" />
                    </Form.Item>

                    <Form.Item
                        label='Model #'
                        name='modelNumber'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label='Serial #'
                        name='serialNumber'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='License Plate #'
                        name='licensePlate'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Rental'
                        name='isRental'
                    >
                        <Switch
                            checked={isRental}
                            onChange={() => {
                                setIsRental(!isRental);
                            }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>


                    <Form.Item
                        label='Status'
                        name='isActive'
                    >
                        <Switch
                            checked={isActive}
                            onChange={() => {
                                setIsActive(!isActive);
                            }}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                        />
                    </Form.Item>


                    <Form.Item
                        label='Value'
                        name='value'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    {/* <Form.Item
                        label='Installed To (Parent Equipment)'
                        name='parentEquipmentId'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item> */}

                    <Form.Item
                        label='Acquisition Year'
                        name='acquiredAt'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <DatePicker picker="year" format="YYYY" />
                    </Form.Item>

                    <Form.Item
                        label='Ownership Type'
                        name='ownershipType'
                        initialValue='Owned'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="Owned" defaultChecked>Owned</Radio.Button>
                            <Radio.Button value="Leased">Leased</Radio.Button>
                            <Radio.Button value="Loaned">Loaned</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label='Starting Hours'
                        name='startingHours'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='End of Term'
                        name='endOfTerm'
                        rules={[
                            {
                                required: false,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <DatePicker picker="month" format="YYYY-MM" />
                    </Form.Item>


                    <Form.Item>
                        <Button type='primary' htmlType='submit' disabled={isValidating != 'success'}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import { Breadcrumb, Button, Card, Form, Input, InputNumber, Modal, Select, DatePicker, Badge, Tag, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment';
import Auth from '../../Auth';
const { Option } = Select;

export const FormTimeRecord = ({ setLoading, location }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');
    const [equipment, setEquipment] = useState([])

    const jobIdQuery = new URLSearchParams(location.search).get('job_id');
    const prefillJobId = new URLSearchParams(location.search).get("jobId") ?? jobIdQuery;
    const prefillCostCodeId = new URLSearchParams(location.search).get("costCodeId");
    const prefillUserId = new URLSearchParams(location.search).get("userId");
    const prefillProducedAt = new URLSearchParams(location.search).get("producedAt");
    const prefillQuantity = new URLSearchParams(location.search).get("quantity");
    const prefillWorkType = new URLSearchParams(location.search).get("workType");
    const prefillDescription = new URLSearchParams(location.search).get("description");

    const isEditMode = (uriParams?.id != null && uriParams.id > 0);
    const productionId = uriParams?.id;
    const [production, setProduction] = useState();

    const [jobList, setJobList] = useState([]);
    const [costCodeList, setCostCodeList] = useState([]);
    const [userList, setUsersList] = useState([]);
    const [workTypeList, setWorkTypeList] = useState([])

    const [jobId, setJobId] = useState(prefillJobId);
    const [costCodeId, setCostCodeId] = useState(prefillCostCodeId);
    const [userId, setUserId] = useState(prefillUserId);

    const [unit, setUnit] = useState('N/A');
    const [workType, setWorkType] = useState({});

    // const workTypes = [
    //     "(S) Buckerman",
    //     "(S) CUA Faller",
    //     "(S) Danger Tree Assesor",
    //     "(S) Faller Apprentice",
    //     "(S) Faller-Production",
    //     "(S) Lead Hand",
    //     "Admin-Office",
    //     "Carpenter",
    //     "Clearing-First Aid OFA Level 3",
    //     "Clearing-Foreman",
    //     "Clearing-Labour",
    //     "Clearing-Mechanic",
    //     "Clearing-PM",
    //     "Clearing-QC manager",
    //     "Clearing-Safety Supervisor",
    //     "Clearing-Site Super",
    //     "Driver",
    //     "First Aid",
    //     "Foreman",
    //     "Fusing Technician",
    //     "Grademan",
    //     "Labour",
    //     "Mechanic Major Equipment",
    //     "Mechanic Small tools",
    //     "Operator",
    //     "Pipe Layer",
    //     "Project Management",
    //     "Quality Control",
    //     "Safety",
    //     "Site Superintendent",
    //     "Surveyor Field",
    //     "Surveyor Office",
    //     "Traffic Control",
    //     "Welder Major Equipment",
    //     "Welder Various"
    // ]

    const fetchJobs = async () => {
        setLoading(true);
        await fetch(`/api/jobs?includeCostCodes=true&includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((jobResult) => {
                setJobList(jobResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });

        setLoading(true)
        await fetch(`/api/work-types`)
            .then((response) => response.json())
            .then((workTypeResult) => {
                setWorkTypeList(workTypeResult);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });

    }

    const onValuesChange = (changedValues) => {
        console.log("Changed Values", changedValues);
    }

    const onJobSelect = (jobId) => {
        if (!productionId) form.resetFields();
        form.setFieldsValue({ jobId });

        const selectedJob = jobList.find(x => x.id == jobId);

        setCostCodeList(selectedJob?.costCodes);
        setUsersList(selectedJob?.userAssignments);
        setEquipment(selectedJob?.equipmentUtilization.map(x => x.equipment));
        setWorkType({})
        console.log("On Job Select")
    }

    const onUserSelect = (value) => {
        const selectedUser = userList.find(x => x.user.id == value);
        form.setFieldsValue({ workType: selectedUser?.user?.workType?.name })
    }

    const onWorkTypeSelect = (workTypeId) => {
        const selectedWorkType = workTypeList.find(x => x.id == workTypeId)
        setWorkType(selectedWorkType)
    }

    const onFinish = (values) => {
        values.type = "Time Record";

        if (isEditMode) {
            // values.costCodeId = costCodeId;
            values.userId = userId;

            fetch(`api/jobs/productions/${productionId}`, {
                method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'Time Record has been updated successfully.',
                    });

                    setLoading(false);
                });
            return;
        }


        fetch(`api/jobs/${values.jobId}/productions`, {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Time Record has been added successfully.',
                });

                history.push("/jobs/time-records/add");
                form.resetFields()
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const fetchProductionById = (id) => {
        setLoading(true);
        fetch(`/api/jobs/productions/${id}`)
            .then((response) => response.json())
            .then((prod) => {
                setLoading(false)
                return prod
            })
            .then((prod) => {
                setProduction(prod)
                onJobSelect(prod.jobId)
                prod.producedAt = moment(prod.producedAt)

                form.setFieldsValue({
                    jobId: prod.jobId.toString(),
                    costCodeId: prod.costCodeId.toString(),
                    producedAt: prod.producedAt,
                    quantity: prod.quantity,
                    workTypeId: prod.workTypeId,
                    description: prod.description,
                    productionEquipments: prod.productionEquipments,
                    userId: `${prod.user.name}`
                })

                setCostCodeId(prod.costCodeId)
                setUserId(prod.userId)
                onWorkTypeSelect(prod.workTypeId)


                // form.setFields()
            })
            .catch(error => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchJobs();

        if (jobId && costCodeId) {
            form.setFieldsValue({ jobId: jobIdQuery })
            onJobSelect(jobIdQuery)
            console.log(equipment)
        }
    }, [jobId, costCodeId]);

    useEffect(() => {
        if (productionId && jobList.length > 0 && workTypeList.length > 0) {
            fetchProductionById(productionId)
        }
    }, [productionId, jobList, costCodeList, workTypeList])


    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>{isEditMode ? "Edit" : "Add"} Time Record</Breadcrumb.Item>
                <Breadcrumb.Item>{productionId}</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 700 }}>
                <Form
                    name='FormProduction'
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    onValuesChange={onValuesChange}
                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        // initialValue={prefillJobId}
                        initialValue={jobId}
                        value={jobId}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            shouldUpdate
                            optionFilterProp="children"
                            onSelect={onJobSelect}
                            value={jobId}
                        >
                            {jobList?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Cost Code'
                        name='costCodeId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        dependencies={["jobId"]}
                        initialValue={prefillCostCodeId}
                        shouldUpdate
                    >
                        <Select
                            showSearch
                            placeholder="Select a Cost Code"
                            optionFilterProp="children"
                        >
                            {
                                costCodeList?.length > 0 ?
                                    costCodeList?.filter(x => x.isToTrackEmployeeAndEquipmentTime).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>) :
                                    jobList.find(x => x.id == jobId)?.costCodes.filter(x => x.isToTrackEmployeeAndEquipmentTime).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>)
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Employee'
                        name='userId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        initialValue={prefillUserId}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Employee"
                            optionFilterProp="children"
                            onSelect={onUserSelect}
                        >
                            {
                                userList?.length > 0 ?
                                    userList?.map((u) => <Option value={u.user.id.toString()} key={u.user.id} > {u.user.name} {u.assignment ? `(${u.assignment})` : ''}</Option>) :
                                    jobList.find(x => x.id == jobId)?.userAssignments?.map((u) => <Option value={u.user.id.toString()} key={u.user.id} > {u.user.name}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Date'
                        name='producedAt'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        initialValue={prefillProducedAt ? moment(prefillProducedAt) : moment()}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={d => !d || d.isAfter(moment())}
                        />

                    </Form.Item>
                    <Form.Item
                        label={`Hours`}
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        initialValue={prefillQuantity}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='Type of Work'
                        name='workTypeId'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        initialValue={prefillWorkType}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Work Type"
                            optionFilterProp="children"
                            onChange={onWorkTypeSelect}
                        >
                            {workTypeList?.map((c) => <Option value={c.id} key={c.id} > {c.name} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Comments'
                        name='description'
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        initialValue={prefillDescription}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    {(workType?.isEquipmentRequired || production?.productionEquipments?.length > 0) &&
                        <Form.List name="productionEquipments">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <Space key={field.key} align="baseline">
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Equipment"
                                                        name={[field.name, 'equipmentId']}
                                                        fieldKey={[field.fieldKey, 'equipmentId']}
                                                        rules={[{ required: true, message: 'Required' }]}
                                                    >
                                                        <Select style={{ width: 250 }}>
                                                            {
                                                                equipment.length > 0 ?
                                                                    equipment.map(item => (
                                                                        <Option key={item.name} value={item.id}>
                                                                            {item.name}
                                                                        </Option>
                                                                    )) :

                                                                    jobList.find(x => x.id == jobId)?.equipmentUtilization.map(x => x.equipment).map(item => (
                                                                        <Option key={item.name} value={item.id}>
                                                                            {item.name}
                                                                        </Option>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Hours"
                                                name={[field.name, 'hoursUsed']}
                                                fieldKey={[field.fieldKey, 'hoursUsed']}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <InputNumber style={{ width: 75 }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                label="Fuel (L)"
                                                name={[field.name, 'litersOfFuel']}
                                                fieldKey={[field.fieldKey, 'litersOfFuel']}
                                                rules={[{ required: false, message: 'Required' }]}
                                            >
                                                <InputNumber style={{ width: 75 }} />
                                            </Form.Item>

                                            <Form.Item
                                                {...field}
                                                label="Notes"
                                                name={[field.name, 'notes']}
                                                fieldKey={[field.fieldKey, 'notes']}
                                                rules={[{ required: false, message: 'Required' }]}
                                            >
                                                <Input.TextArea style={{ width: 190 }} />
                                            </Form.Item>


                                            <MinusCircleOutlined style={{ color: "red" }} onClick={() => remove(field.name)} />
                                        </Space>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Equipment Usage
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    }
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Badge, Breadcrumb, Button, Card, Descriptions, Divider, List, Table, Modal, Space, Tabs, Typography } from 'antd';

import moment from 'moment'

const { Title } = Typography;
const { TabPane } = Tabs;


export class ViewEquipment extends React.Component {

    state = {
        equipment: {},
        isLoaded: false,
        visible: false,
        mapPin: {}
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.setLoading(true);

        fetch(`/api/equipment/${id}?includeJobUtilization=true`)
            .then((response) => response.json())
            .then((equipment) => {
                this.setState({
                    equipment: equipment
                });
                this.props.setLoading(false);
            })
            .catch(error => {
                this.props.setLoading(false);
            });
    }



    render() {

        const columns = [
            {
                title: 'Job',
                dataIndex: 'name',
                key: 'name',
                render: (name, u) => u?.job.name
            },
            {
                title: 'Desc.',
                dataIndex: 'name',
                key: 'name',
                render: (name, u) => u?.job.description
            },
        ];

        const equipment = this.state.equipment;
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Equipment</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.equipment.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Space direction="vertical">

                    <Card>
                        <Title level={5}>{equipment.name}</Title>
                        <Divider />
                        <Descriptions
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                            bordered>
                            <Descriptions.Item label="Ownership">{equipment.ownershipType || "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="Description">{equipment.description || "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="Category">{equipment.equipmentCategory?.name || "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="Make">{equipment.make || "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Model #">{equipment.modelNumber || "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="License Plate #">{equipment.licensePlate || "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Year">{equipment.year ? moment(equipment.year).format("YYYY") : "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Serial #">{equipment.serialNumber || "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Ownership">{equipment.ownershipType ? equipment.ownershipType : "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Acquisition Year">{equipment.acquiredAt ? moment(equipment.acquiredAt).format("YYYY") : "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="End of Term">{equipment.endOfterm ? moment(equipment.endOfTerm).format("YYYY") : "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="Value">{equipment.value ? equipment.value : 0}</Descriptions.Item>
                            <Descriptions.Item label="End of Term">{equipment.endOfterm ? moment(equipment.endOfTerm).format("YYYY") : "N/A"}</Descriptions.Item>
                            <Descriptions.Item label="Starting Hours">{equipment.startingHours ? equipment.startingHours : 0}</Descriptions.Item>
                            <Descriptions.Item label="Class">{equipment.class ? equipment.class : "N/A"}</Descriptions.Item>

                            <Descriptions.Item label="Rental?">{equipment.isRental ? "Yes" : "No"}</Descriptions.Item>
                        </Descriptions>

                        <Divider />
                    </Card>
                    <Card title="Job Assignments">
                        <Table dataSource={equipment?.jobUtilization} columns={columns} />
                    </Card>
                </Space>

            </>
        );
    }
}
import { Breadcrumb, Button, Card, Form, Layout, Select, DatePicker, Alert, List, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment'
import Auth from '../../Auth';
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const ManagerReportCheck = ({ location, setLoading }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();

    const [formLayout, setFormLayout] = useState('vertical');
    const [jobs, setJobs] = useState([]);

    const [dateQuery, setDateQuery] = useState([moment().add(-7, 'days').startOf('day'), moment().endOf('day')]);

    useEffect(() => {
    }, [dateQuery]);

    const onFilterChange = () => {

        const values = form.getFieldsValue();
        console.log(form.getFieldsValue())
        setDateQuery(values.queryDate ?? dateQuery)


    }

    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {

    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Manager Report</Breadcrumb.Item>
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }} bordered={false}>

                <Form
                    form={form}
                    name='ManagerReportCheckForm'
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    style={{ maxWidth: 500 }}
                    onFieldsChange={onFilterChange}
                >
                    <Form.Item
                        label='Date Range'
                        name='queryDate'
                        value={dateQuery}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>
                        <RangePicker
                            value={dateQuery}
                            format="YYYY-MM-DD"
                            defaultValue={[moment().add(-7, 'days').startOf('day'), moment().endOf('day')]}
                            disabledDate={d => !d || d.isAfter(moment())}
                        />
                    </Form.Item>
                </Form>

                <Button size="" disabled={!(dateQuery)} type="primary" href={`#/reports/manager-report?start=${moment(dateQuery[0]).utc().format()}&end=${moment(dateQuery[1]).utc().format()}`}>View Report</Button>
            </Card>


        </>)
}
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, Image, Button, Space, Breadcrumb, Card, Menu, Dropdown, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import exportFromJSON from 'export-from-json';
import Auth from '../../../Auth'
import moment from 'moment';

const { RangePicker } = DatePicker;

export class ActiveEmployeesReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        users: [],
        error: null
    };

    componentDidMount() {
        const setLoading = this.props.setLoading;
        setLoading(true);

        fetch(`api/users?includeRole=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then(response => response.json())
            .then((result) => {
                const mappedUsers = result.map(u => ({ ...u, key: u.id, role: u.role?.name }));
                this.setState({
                    users: mappedUsers.filter(x => x.isActive && x.isOnPayroll).map(x => {
                        return {
                            ...x,
                            birthdayAt: x.birthdayAt ? moment(x.birthdayAt).format("YYYY-MM-DD") : null,
                            hiredAt: x.hiredAt ? moment(x.hiredAt).format("YYYY-MM-DD") : null,
                            isBirthday: moment(x.birthdayAt).format("MM-DD") == moment().format("MM-DD")
                        }
                    }),
                });

                console.log(this.state.users)

                setLoading(false);
            },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                });
    }

    getDateColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        console.log("Selected Key", selectedKeys)
                        setSelectedKeys([e]);
                    }

                    }
                    value={this.state.searchDate}
                    defaultValue={this.state.searchDate}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();

        this.setState({ searchText: '' });
        this.setState({ searchDate: [null, null] })
    };

    render() {
        const columns = [
            // {
            //     title: 'Username',
            //     dataIndex: 'username',
            //     key: 'username',
            //     // width: '20%',
            //     ...this.getColumnSearchProps('username'),
            // },
            {
                title: 'Employee Code',
                dataIndex: 'employeeCode',
                key: 'employeeCode',
                // width: '20%',
                ...this.getColumnSearchProps('employeeCode'),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // width: '30%',
                // fixed: 'left',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Work Class',
                dataIndex: 'workClass',
                key: 'workClass',
                // width: '20%',
                ...this.getColumnSearchProps('workClass'),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                // width: '20%',
                ...this.getColumnSearchProps('email'),
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                // width: '20%',
                ...this.getColumnSearchProps('mobile'),
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                // width: '20%',
                ...this.getColumnSearchProps('address'),
            },
            {
                title: 'Emergency Contact',
                dataIndex: 'emergencyContact',
                key: 'emergencyContact',
                // width: '20%',
                ...this.getColumnSearchProps('emergencyContact'),
            },
            // {
            //     title: 'Employment Type',
            //     dataIndex: 'employmentType',
            //     key: 'employmentType',
            //     // width: '20%',
            //     // fixed: 'left',
            //     ...this.getColumnSearchProps('employmentType'),
            // },
            // {
            //     title: 'Pay Type',
            //     dataIndex: 'payType',
            //     key: 'payType',
            //     // width: '15%',
            //     // fixed: 'left',
            //     ...this.getColumnSearchProps('payType'),
            // },
            // {
            //     title: 'Car Type',
            //     dataIndex: 'carType',
            //     key: 'carType',
            //     // width: '15%',
            //     // fixed: 'left',
            //     ...this.getColumnSearchProps('carType'),
            // },
            {
                title: 'Date Hired',
                dataIndex: 'hiredAt',
                key: 'hiredAt',
                // width: '10%',
                render: (hiredAt) => `${hiredAt}`,
                // fixed: 'left',
                ...this.getDateColumnSearchProps('hiredAt'),
            },
            {
                title: 'Birthday',
                dataIndex: 'birthdayAt',
                key: 'birthdayAt',
                // width: '30%',
                filters: [
                    {
                        text: 'January',
                        value: 1,
                    },
                    {
                        text: 'February',
                        value: 2,
                    },
                    {
                        text: 'March',
                        value: 3,
                    },
                    {
                        text: 'April',
                        value: 4,
                    },
                    {
                        text: 'May',
                        value: 5,
                    },
                    {
                        text: 'June',
                        value: 6,
                    },
                    {
                        text: 'July',
                        value: 7,
                    },
                    {
                        text: 'August',
                        value: 8,
                    },
                    {
                        text: 'September',
                        value: 9,
                    },
                    {
                        text: 'October',
                        value: 10,
                    },
                    {
                        text: 'November',
                        value: 11,
                    },
                    {
                        text: 'December',
                        value: 12,
                    },
                ],

                onFilter: (value, record) => {
                    console.log(value)
                    console.log(record.birthdayAt)
                    return moment(record.birthdayAt).format("MM") == value

                },
                sortDirections: ['descend', 'ascend'],

                // fixed: 'left',
                render: (birthdayAt, row) => <>{birthdayAt}
                    {row.isBirthday && <Image
                        width={20}
                        src="206542.png"
                        preview={false}
                    />}</>,
            },
            {
                title: 'Health',
                dataIndex: 'health',
                key: 'health',
                // width: '20%',
                ...this.getColumnSearchProps('health'),
            },

            // {
            //     title: 'Action',
            //     key: 'operation',
            //     // fixed: 'right',
            //     render: (user) => {
            //         return (
            //             <Space size="small" wrap={true}>

            //                 <Button size="small" type="primary" href={`#/users/${user.id}/edit`}>
            //                     Edit
            //                 </Button>
            //             </Space >

            //         )
            //     },
            // },
        ];
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Active Employees Report</Breadcrumb.Item>
                </Breadcrumb>
                <Table columns={columns} dataSource={this.state.users} scroll={{ x: 1300 }} pagination={{ position: ["bottomLeft"] }}
                    footer={currentPageData => {

                        let data = this.state.users;

                        function handleMenuClick(e) {
                            if (e.key.includes("filtered")) {
                                data = currentPageData;
                            }

                            let fileType = exportFromJSON.types.txt;

                            switch (e.key) {
                                case "csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                                case "filtered-csv":
                                    fileType = exportFromJSON.types.csv;
                                    break;
                                case "filtered-xls":
                                    fileType = exportFromJSON.types.xls;
                                    break;
                                case "filtered-json":
                                    fileType = exportFromJSON.types.json;
                                    break;
                                case "filtered-txt":
                                    fileType = exportFromJSON.types.txt;
                                    break;
                            }

                            exportFromJSON({
                                data: data.map(x => {
                                    return {
                                        // username: x.username,
                                        employeeCode: x.employeeCode,
                                        name: x.name,
                                        workClass: x.workClass ?? "",
                                        email: x.email ?? "",
                                        mobile: x.mobile ?? "",
                                        address: x.address ?? "",
                                        emergencyContact: x.emergencyContact ?? "",
                                        // employmentType: x.employmentType ?? "",
                                        // payType: x.payType ?? "",
                                        // carType: x.carType ?? "",
                                        hiringDate: x.hiredAt ?? "",
                                        birthday: x.birthdayAt ?? "",
                                        health: x.health ?? "",

                                    }
                                }),
                                fileName: "active_employees",
                                exportType: fileType
                            });
                        }
                        const menu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                <Menu.Item key="json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        const filteredMenu = (
                            <Menu onClick={handleMenuClick}>
                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                            </Menu>
                        );

                        return (
                            <Space size="small">
                                <Dropdown overlay={menu}>
                                    <Button type="primary" ghost={true}>Download All</Button>
                                </Dropdown>

                            </Space>
                        );

                    }} />
            </>
        );
    }
}
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu, Select, Divider, notification, Result, Switch, List, Card, Form, DatePicker } from 'antd';
import exportFromJSON from 'export-from-json';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import Auth from '../../Auth';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;

const EditableContext = React.createContext(null);

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        placement: 'topLeft'
    });
};

export class MissingTimeCardsReport extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        productionRecords: [],
        jobId: this.props.match.params.id,
        timeRecords: [],
        missingTimeRecords: [],
        jobs: [],
        isLoaded: false,
        error: null,
        job: {},
        paginationSettings: { position: ["bottomLeft"] }
    };

    componentDidMount() {
        this.fetchProduction()
    }

    fetchProduction() {
        const setLoading = this.props.setLoading;
        setLoading(true);

        fetch(`api/jobs/production-requests?includeJob=true&includeProductions=true&companyId=${Auth.getCurrentUser().companyId}&isPending=true`)
            .then(response => response.json())
            .then(
                (missingRecords) => {
                    this.setState({
                        missingTimeRecords: missingRecords
                            .filter(x => x.productionId == null || x.productionId == 0)
                            .map(x => {
                                return {
                                    id: x.id,
                                    key: x.id,
                                    name: x.user?.name ?? x.user,
                                    job: x.job?.name,
                                    requestAt: x.requestAt,
                                    requestedBy: x.requestedById == null ? "Could Not Find Data" : `${x.requestedBy?.name} (${x.requestedBy?.username})`,
                                }
                            })
                    })

                    setLoading(false)
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex} `}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.setState({ searchDate: [null, null] });
    };


    getDateColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    style={{ marginRight: "10px" }}
                    format="YYYY-MM-DD"
                    onChange={e => {
                        //const stringifiedDates = `${e[0].format("YYYY-MM-DD")}|${e[1].format("YYYY-MM-DD")}`;
                        //console.log(stringifiedDates)
                        console.log("Selected Key", selectedKeys)
                        setSelectedKeys([e]);
                    }
                    }
                    value={this.state.searchDate}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 100 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            //const parsedDates = value.split('|');
            console.log(value);
            const stringifiedDates = value.map(x => moment(x).format("YYYY-MM-DD"))
            return moment(record[dataIndex]).isBetween(...stringifiedDates, 'days', '[]')
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    render() {
        let timeColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Date',
                dataIndex: 'requestAt',
                key: 'requestAt',
                ...this.getDateColumnSearchProps('requestAt'),
                render: requestAt => <>{moment(requestAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Job',
                dataIndex: 'job',
                key: 'job',
                ...this.getColumnSearchProps('job'),

                // editable: true,
                // render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Requested by',
                dataIndex: 'requestedBy',
                key: 'requestedBy',
                // editable: true,
                ...this.getColumnSearchProps('requestedBy'),

            },
        ];


        return (
            <Space direction="vertical">
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Reports</Breadcrumb.Item>
                    <Breadcrumb.Item>Missing Timecards Report</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.job.name}</Breadcrumb.Item>
                </Breadcrumb>

                {this.state.missingTimeRecords?.length ?
                    <>
                        <Card title="Time Records">
                            <Table
                                // components={timeRecordComponent}
                                rowClassName={() => 'editable-row'}
                                rowKey={record => `production-${record.id} `}
                                columns={timeColumns}
                                dataSource={this.state.missingTimeRecords}
                                scroll={{ x: 1300 }}
                                pagination={false}
                            />
                        </Card>


                    </>

                    :

                    <Card>

                        <Result
                            title="No Records Found"
                            extra={
                                <Button type="primary" href="#/reports/foreman-report">
                                    Go Back
                                </Button>
                            }
                            style={{ opacity: this.state.job?.name && this.state.production?.length > 0 ? 100 : 100 }}
                        />
                    </Card>
                }


            </Space>
        );
    }
}
import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom'
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';

import Auth from './Auth'

const Login = ({ setToken }) => {
    const history = useHistory();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        await authenticate(values);
    };

    const onSubmitFailed = async (errorInfo) => {
        setError("Incorrect username and/or password")
    };

    const authenticate = async (values) => {
        setToken(null)
        setError(null)
        setLoading(true);

        const credentials = {
            username: values.username,
            password: values.password,
            clientKey: "Web-Dashboard",
        }

        const options = {
            method: "POST",
            body: JSON.stringify(credentials),
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        }

        try {
            const response = await fetch("/auth/login", options)

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            const authentication = await response.json()
            setToken(authentication.token)

            window.location.reload();
            history.push("/home");
        }
        catch (error) {
            setToken(null);
            setError("Incorrect username and/or password")
        }

        setLoading(false)
    }

    useEffect(() => {
        setLoading(false)
        history.push("/")
    }, []);

    console.log(error)

    return (
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '80vh' }}>
            <Col>
                {error &&
                    <Alert
                        message="Login Failed"
                        description={error}
                        type="error"
                        banner
                    />
                }

                <Card bordered={false} style={{ width: 500 }}>
                    <Form
                        layout="vertical"
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onSubmit}
                        onFinishFailed={onSubmitFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {loading ? "Logging in ..." : "Login"}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Login;
import { CheckOutlined, ExclamationCircleOutlined, SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Alert, Drawer, Breadcrumb, Button, Input, Modal, Space, Table, Dropdown, Menu, Select, Divider, notification, Result, Switch, List, Card, Form, DatePicker, Badge, Typography } from 'antd';
import exportFromJSON from 'export-from-json';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { FormProduction } from '../production/FormProduction';
import "antd/dist/antd.css";
const { confirm } = Modal;
const { Option } = Select;
const { Text, Link } = Typography;

const EditableContext = React.createContext(null);

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        placement: 'topLeft'
    });
};

export class SubcontractorReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            searchedColumn: '',
            productionRecords: [],
            production: [],
            timeRecords: [],
            jobs: [],
            isLoaded: false,
            error: null,
            job: {},
            paginationSettings: { position: ["bottomLeft"] },
            workTypes: {},
            equipments: [],
            editProductionId: null,
            selectedRowKeys: [],
            drawerOpen: false,
            batchInvoiceStatus: "N/A",
        };

        console.log("drawerOpen", this.state.drawerOpen)
    }

    componentDidMount() {
        this.fetchProduction()

    }

    componentDidUpdate() {
        console.log("drawerOpen", this.state.drawerOpen)
    }

    openDrawer = () => {
        this.setState({ drawerOpen: true });
    };

    closeDrawer = () => {
        this.setState({ drawerOpen: false });
    };

    submitDrawer = () => {
        this.props.setLoading(true)
        const productionIds = this.state.selectedRowKeys.map(x => parseInt(x.split("production - ").pop()))
        let productionItems = this.state.production.filter(p => productionIds.includes(p.id));

        productionItems.forEach(x => x.invoiceStatus = this.state.batchInvoiceStatus)

        console.log("PIT", productionItems);
        console.log("PID", productionIds);

        productionItems = productionItems.map(x =>
            fetch(`api/jobs/productions/${x.id}`, {
                method: 'PUT',
                body: JSON.stringify(x),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' }
            })
        )

        this.closeDrawer()
        Promise.all(productionItems)
            .then((results) => {
                const failingItems = results.filter(x => !x.ok);
                console.log("FI", failingItems)
                if (failingItems.length == 0) {
                    openNotificationWithIcon('success', 'Success', 'Records has been updated!');
                }
                else {
                    openNotificationWithIcon('warning', 'Error', 'Some records failed to update!');
                }
                this.fetchProduction()
            })
        this.props.setLoading(false)

    };

    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        });
    }

    fetchProduction() {
        const setLoading = this.props.setLoading;
        setLoading(true);
        const query = new URLSearchParams(this.props.location.search);
        let start = moment(query.get('start', "YYYY-MM-DD"));
        let end = moment(query.get('end', "YYYY-MM-DD"));

        fetch(`api/jobs/productions?includeJob=true&includeCostCodes=true&producedStartsAt=${query.get('start', "YYYY-MM-DD")}&producedEndsAt=${query.get('end', "YYYY-MM-DD")}&isForSubcontractor=true`)
            .then(response => response.json())
            .then(
                (result) => {
                    let production = result.map(e => ({ ...e, key: e.id }));

                    if (start.isValid()) {
                        production = production.filter(x => moment(x.producedAt).isBetween(start, end, 'days', '[]'));
                    }


                    this.setState({
                        production: production,
                    });


                    const timeRecords = production?.filter(x => x.costCode?.isToTrackEmployeeAndEquipmentTime);
                    let eq = [];
                    let mappedTimeRecords = timeRecords.map(x => {
                        eq = x.job?.equipmentUtilization.map(x => x.equipment)
                        return {
                            job: x.job?.name,
                            key: x.id,
                            invoiceStatus: x.invoiceStatus,
                            costCode: x.costCode.name,
                            costCodeDescription: x.costCode.description,
                            producedAt: x.producedAt,
                            quantity: x.quantity,
                            description: x.description,
                            workType: x.workType?.name,
                            workTypeObj: x.workType,
                            costCodeId: x.costCodeId,
                            isApproved: x.isApproved,
                            productionEquipments: x.productionEquipments,
                            type: x.type,
                            name: x.user?.name,
                            user: x.user,
                            costCodeObj: x.costCode,
                            jobObj: x.job,
                        }
                    })
                    console.log("Mapped Time Records", mappedTimeRecords)

                    this.setState({
                        equipments: eq
                    })

                    mappedTimeRecords = mappedTimeRecords.filter(x => x.type == "Time Record");

                    const productionRecords = production?.filter(x => x.costCode?.isToTrackProduction);
                    let mappedProductionRecords = productionRecords.map(x => {
                        return {
                            job: x.job.name,
                            key: x.id,
                            costCode: `${x.costCode.name}`,
                            costCodeDescription: `${x.costCode.description}`,
                            producedAt: x.producedAt,
                            quantity: x.quantity,
                            unit: x.costCode.unit,
                            description: x.description,
                            workType: x.workType?.name,
                            type: x.type,
                            name: x.user?.name,
                            costCodeObj: x.costCode,
                            jobObj: x.job
                        }
                    })


                    mappedProductionRecords = mappedProductionRecords.filter(x => x.type == null)

                    this.setState({
                        timeRecords: mappedTimeRecords,
                        productionRecords: mappedProductionRecords
                    })

                    console.log("TR", mappedTimeRecords)
                    console.log("PR", mappedProductionRecords)

                    fetch('/api/work-types')
                        .then((response) => response.json())
                        .then((workTypesResult) => this.setState({ workTypes: workTypesResult }))

                    setLoading(false)
                },
                (error) => {
                    this.setState({
                        error
                    });
                    setLoading(false);
                }
            );
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSave = (row) => {
        const isProductionEquipment = row.isProductionEquipment;

        const isProduction = row.unit != undefined;

        if (isProductionEquipment) {
            row.key = row.productionId;
        }

        const newData = !isProduction ? [...this.state.timeRecords] : [...this.state.productionRecords];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        if (!isProduction) {
            this.setState({
                timeRecords: newData,
            });
        }
        else {
            this.setState({
                productionRecords: newData,
            });
        }
        this.props.setLoading(true);

        if (isProductionEquipment) {

            console.log("EQUIPMENT", {
                id: item.key,
                equipmentId: row.equipmentId,
                litersOfFuel: row.litersOfFuel,
                hoursUsed: row.hoursUsed,
                notes: row.notes
            })

            fetch(`api/jobs/production-equipments/${row.id}`, {
                method: 'PUT', body: JSON.stringify({
                    equipmentId: row.equipmentId,
                    litersOfFuel: row.litersOfFuel,
                    hoursUsed: row.hoursUsed,
                    notes: row.notes
                }), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {
                    openNotificationWithIcon('success', 'Success', 'Record has been updated!');
                    window.location.reload()
                    this.props.setLoading(false);
                })
                .catch(result => {
                    openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                    this.props.setLoading(false);
                });
            return
        }


        fetch(`api/jobs/productions/${item.key}`, {
            method: 'PUT',
            body: JSON.stringify(row),
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                openNotificationWithIcon('success', 'Success', 'Record has been updated!');

                this.props.setLoading(false);
            })
            .catch(result => {
                openNotificationWithIcon('error', 'Error', 'Something went wrong!');
                this.props.setLoading(false);
            });;
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex} `}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    render() {
        const self = this;
        const EditableTimeRecordRow = ({ index, ...props }) => {
            const [form] = Form.useForm();
            return (
                <Form form={form} component={false}>
                    <EditableContext.Provider value={form}>
                        <tr {...props} />
                    </EditableContext.Provider>
                </Form>
            );
        };

        const EditableTimeRecordCell = ({
            title,
            editable,
            children,
            dataIndex,
            record,
            handleSave,
            ...restProps
        }) => {
            const [editing, setEditing] = useState(false);
            const inputRef = useRef(null);
            const form = useContext(EditableContext);
            useEffect(() => {
                if (editing) {
                    inputRef.current.focus();
                }
            }, [editing]);

            const toggleEdit = () => {
                setEditing(!editing);
                form.setFieldsValue({
                    [dataIndex]: title == "Date" ? moment(record[dataIndex]) : record[dataIndex],
                });
            };

            const save = async () => {
                try {
                    console.log("RRRR", record)
                    const values = await form.validateFields();
                    toggleEdit();

                    handleSave({ ...record, ...values });
                } catch (errInfo) {
                    console.log('Save failed:', errInfo);
                }
            };

            let childNode = children;


            if (editable) {
                childNode = editing ? (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >

                        {
                            (() => {
                                if (title == "Type of Work") {
                                    const workType = record.workTypeObj;

                                    return (<Select
                                        ref={inputRef}
                                        onSelect={(value, option) => {
                                            console.log(option)
                                            record.workType = option.children;
                                            record.workTypeId = value;

                                            console.log(record)
                                            handleSave(record);
                                        }}
                                        showSearch
                                        placeholder="Select Type of Work"
                                        optionFilterProp="children"
                                    >
                                        {self.state.workTypes?.map((c) => <Option value={c.id.toString()} key={c.id}>{c.name}</Option>)}
                                    </Select>)
                                }

                                if (title == "Equipment") {
                                    const equipmentUtilization = this.state.equipments;

                                    return (
                                        <Select
                                            ref={inputRef}
                                            showSearch
                                            onSelect={(value, option) => {
                                                console.log(option)
                                                record.equipment = option.children[0];
                                                record.equipmentId = value;
                                                handleSave(record);
                                            }}

                                            style={{ width: 120 }}
                                        >
                                            {equipmentUtilization?.map(x => <Option value={x.id.toString()} key={x.id}>{x.name} - {x.description}</Option>)}
                                        </Select>
                                    )
                                }

                                if (title == "Cost Code") {
                                    const costCode = record.costCodeObj;

                                    return (
                                        <Select
                                            ref={inputRef}
                                            showSearch
                                            onSelect={(value, option) => {
                                                console.log(option)
                                                record.costCode = option.children[0];
                                                record.costCodeId = value;
                                                handleSave(record);
                                            }}

                                            style={{ width: 120 }}
                                        >
                                            {record.jobObj?.costCodes?.map(x => <Option value={x.id.toString()}>{x.name} - {x.description}</Option>)}
                                        </Select>
                                    )
                                }

                                if (title == "Date") {
                                    return (
                                        <DatePicker ref={inputRef} onBlur={save} onChange={save} />
                                    )
                                }

                                if (title == "Invoice") {
                                    return (
                                        <Select
                                            ref={inputRef}
                                            placeholder="N/A"
                                            defaultValue="N/A"
                                            onSelect={(value, option) => {
                                                console.log(option)
                                                record.invoiceStatus = value;
                                                handleSave(record);
                                            }}
                                        >
                                            <Option value="N/A">N/A</Option>
                                            <Option value="Not to be Invoiced">Not to be Invoiced</Option>
                                            <Option value="Ready for Invoicing">Ready for Invoicing</Option>
                                            <Option value="Invoiced">Invoiced</Option>
                                        </Select>
                                    )
                                }
                                else {
                                    return (
                                        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                                    )
                                }
                            })()
                        }


                    </Form.Item>
                ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{
                            paddingRight: 24,

                        }}
                        onClick={toggleEdit}
                    >
                        {children}
                    </div>
                );
            }

            return <td {...restProps}>{childNode}</td>;
        };

        let timeColumns = [
            {
                title: 'Invoice',
                dataIndex: 'invoiceStatus',
                key: 'invoiceStatus',
                editable: true,
                filters: [...new Set(this.state.timeRecords.map(x => x.invoiceStatus))]
                    .map(x => {
                        return {
                            text: <span>{x ?? "Empty"}</span>,
                            value: x
                        }
                    }),
                onFilter: (value, record) => record.invoiceStatus == value,
                // filterSearch: (input, record) => record.invoiceStatus.indexOf(input) > -1,
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                // editable: true,
                sorter: (a, b) => ('' + a.producedAt).localeCompare(b.producedAt),
                sortDirections: ['descend', 'ascend'],
                render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                ...this.getColumnSearchProps('costCode'),
                key: 'costCode',
                sorter: (a, b) => ('' + a.costCode).localeCompare(b.costCode),
                sortDirections: ['descend', 'ascend'],
                // editable: true,
            },
            {
                title: 'Cost Code Name',
                dataIndex: 'costCodeDescription',
                ...this.getColumnSearchProps('costCodeDescription'),
                key: 'costCodeDescription',
                sorter: (a, b) => ('' + a.costCodeDescription).localeCompare(b.costCodeDescription),
                sortDirections: ['descend', 'ascend'],
                // editable: true,
            },
            {
                title: 'Work Type',
                dataIndex: 'workType',
                ...this.getColumnSearchProps('workType'),
                key: 'workType',
                // editable: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                sorter: (a, b) => ('' + a.name).localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Hours',
                dataIndex: 'quantity',
                key: 'quantity',
                sortDirections: ['descend', 'ascend'],
                // editable: true
            },
            {
                title: 'Comments',
                dataIndex: 'description',
                ...this.getColumnSearchProps('description'),

                // editable: true,
                key: 'description',
                render: note => <span>{note?.length > 500 ? `${note.substr(0, 500)} ...` : note}</span>,
            },
        ];

        const timeRecordComponent = {
            body: {
                row: EditableTimeRecordRow,
                cell: EditableTimeRecordCell,
            },
        };

        timeColumns = timeColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        let productionColumns = [
            {
                title: 'Cost Code',
                dataIndex: 'costCode',
                key: 'costCode',
                width: '10%',
                // editable: true,
                ...this.getColumnSearchProps('costCode'),
            },
            {
                title: 'Cost Code Name',
                dataIndex: 'costCodeDescription',
                key: 'costCodeDescription',
                ...this.getColumnSearchProps('costCode'),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Production',
                dataIndex: 'quantity',
                key: 'quantity',
                // editable: true,
                width: '30%',
            },
            {
                title: 'Unit of Measure',
                dataIndex: 'unit',
                key: 'unit',
                width: '20%',
                ...this.getColumnSearchProps('unit'),
            },
            {
                title: 'Date',
                dataIndex: 'producedAt',
                key: 'producedAt',
                // editable: true,
                render: producedAt => <>{moment(producedAt).format("YYYY-MM-DD")}</>,
            },
            {
                title: 'Notes',
                dataIndex: 'description',
                key: 'description',
                // editable: true,
                ...this.getColumnSearchProps('description'),
            },
            {
                title: 'Action',
                key: 'operation',
                // fixed: 'right',
                render: (record) => {
                    const self = this;

                    return (
                        <Space size="small" wrap={true}>
                            <Button size="small" type="primary" danger onClick={() => this.handleDelete(record)}>
                                Delete
                            </Button>
                            <Button size="small" type="primary" onClick={() => window.open(`#/jobs/productions/${record.key}`)}>
                                Edit
                            </Button>
                        </Space >

                    )
                },
            },
        ];

        let equipmentColumns = [
            {
                title: 'Equipment No.',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                // editable: true
            },
            {
                title: 'Equipment Name',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                // editable: true
            },
            {
                title: 'Equipment Category',
                dataIndex: 'category',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                // editable: true
            },
            {
                title: 'Hours Used',
                dataIndex: 'hoursUsed',
                key: 'hoursUsed',
                // editable: true,
            },
            {
                title: 'Liters of Fuel',
                dataIndex: 'litersOfFuel',
                key: 'litersOfFuel',
                // editable: true,
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                // editable: true,
            }
        ]

        equipmentColumns = equipmentColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        productionColumns = productionColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        return (
            <>


                <div>

                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Reports</Breadcrumb.Item>
                        <Breadcrumb.Item>Subcontractor Report</Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.job.name}</Breadcrumb.Item>
                    </Breadcrumb>


                    {this.state.production?.length ?
                        <>
                            <Card title="Time Records" extra={<Button type="primary" disabled={this.state.selectedRowKeys.length == 0} onClick={this.openDrawer}>
                                Update {this.state.selectedRowKeys.length} Record(s)
                            </Button>}>


                                <Drawer title="Batch Update" description="Test" placement="right" open={this.state.drawerOpen} onClose={this.closeDrawer} extra={
                                    <Space>
                                        <Button onClick={this.closeDrawer}>Cancel</Button>
                                        <Button onClick={this.submitDrawer} type="primary">
                                            Submit
                                        </Button>
                                    </Space>
                                }>
                                    <Alert message={`You are about to update ${this.state.selectedRowKeys.length} record(s).`} />
                                    <Card style={{ marginTop: 20 }}>
                                        <Text>* Invoice Status</Text>

                                        <Select
                                            style={{ width: '100%', marginTop: 18 }}
                                            placeholder="N/A"
                                            defaultValue="N/A"
                                            onSelect={(value, option) => {
                                                this.setState({
                                                    batchInvoiceStatus: value
                                                })
                                            }}
                                        >
                                            <Option value="N/A">N/A</Option>
                                            <Option value="Not to be Invoiced">Not to be Invoiced</Option>
                                            <Option value="Ready for Invoicing">Ready for Invoicing</Option>
                                            <Option value="Invoiced">Invoiced</Option>
                                        </Select>
                                    </Card>

                                </Drawer>
                                <Table
                                    components={timeRecordComponent}
                                    rowClassName={() => 'editable-row'}
                                    rowSelection={{
                                        selectedRowKeys: this.state.selectedRowKeys,
                                        onChange: this.onSelectChange
                                    }}
                                    rowKey={record => `production - ${record.key} `}
                                    columns={timeColumns}
                                    expandable={{
                                        expandedRowRender: record => {
                                            return (<>
                                                <Table
                                                    components={timeRecordComponent}
                                                    rowClassName={() => 'editable-row'}
                                                    rowKey={record => `equipment - ${record.id} `}
                                                    dataSource={record.productionEquipments?.map(x => { return { key: x.id, name: x.equipment.name, description: x.equipment.description, category: x.equipment.equipmentCategory.name, ...x, isProductionEquipment: true } })}
                                                    columns={equipmentColumns}>
                                                </Table>
                                            </>)
                                        }

                                        ,
                                        rowExpandable: record => record.productionEquipments?.length > 0
                                    }}
                                    dataSource={this.state.timeRecords}
                                    scroll={{ x: 1300 }}
                                    pagination={{
                                        defaultPageSize: 50,
                                        pageSizeOptions: [50, 100, 500]
                                    }}
                                    footer={currentPageData => {

                                        let data = this.state.timeRecords;

                                        function handleMenuClick(e) {
                                            if (e.key.includes("filtered")) {
                                                data = currentPageData;
                                            }

                                            let fileType = exportFromJSON.types.txt;

                                            switch (e.key) {
                                                case "csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                                case "filtered-csv":
                                                    fileType = exportFromJSON.types.csv;
                                                    break;
                                                case "filtered-xls":
                                                    fileType = exportFromJSON.types.xls;
                                                    break;
                                                case "filtered-json":
                                                    fileType = exportFromJSON.types.json;
                                                    break;
                                                case "filtered-txt":
                                                    fileType = exportFromJSON.types.txt;
                                                    break;
                                            }

                                            const getOptionName = (value) => {
                                                let name = "N/P";
                                                if (value == null) name = "N/P";
                                                if (value == true) name = "Approved";
                                                if (value == false) name = "Declined";
                                                return name;
                                            }

                                            // let mappedTimeRecords = timeRecords.map(x => {
                                            //     return {
                                            //         job: x.job?.name,
                                            //         key: x.id,
                                            //         costCode: x.costCode.name,
                                            //         producedAt: x.producedAt,
                                            //         quantity: x.quantity,
                                            //         description: x.description,
                                            //         workType: x.workType?.name,
                                            //         workTypeObj: x.workType,
                                            //         costCodeId: x.costCodeId,
                                            //         isApproved: x.isApproved,
                                            //         productionEquipments: x.productionEquipments,
                                            //         type: x.type,
                                            //         name: x.user?.name,
                                            //         user: x.user,
                                            //         costCodeObj: x.costCode,
                                            //         jobObj: x.job,
                                            //     }
                                            // })

                                            const output = data.map(x => {
                                                return {
                                                    InvoiceStatus: x.invoiceStatus,
                                                    Approved: getOptionName(x.isApproved),
                                                    Date: moment(x.producedAt).format("YYYY-MM-DD"),
                                                    Job: `${x.jobObj.name} - ${x.jobObj.description}`,
                                                    CostCode: `${x.costCodeObj.name} - ${x.costCodeObj.description}`,
                                                    Name: x.name,
                                                    Hours: x.quantity,
                                                    Comments: x.description,
                                                    Equipment: x.productionEquipments?.map(x => x.equipment.name).join('|'),
                                                    EquipmentHours: x.productionEquipments?.map(x => x.hoursUsed).join('|'),
                                                    EquipmentFuel: x.productionEquipments?.map(x => x.litersOfFuel).join('|'),
                                                    EquipmentNotes: x.productionEquipments?.map(x => `"${x.notes}"`).join('|'),
                                                    EquipmentCategory: x.productionEquipments?.map(x => x.equipment.equipmentCategory.name).join('|')
                                                }
                                            })

                                            exportFromJSON({
                                                data: output,
                                                fileName: "subcontractor-report",
                                                exportType: fileType
                                            });
                                        }

                                        const menu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        const filteredMenu = (
                                            <Menu onClick={handleMenuClick}>
                                                <Menu.Item key="filtered-csv">Export as CSV</Menu.Item>
                                                <Menu.Item key="filtered-xls">Export as XLS</Menu.Item>
                                                <Menu.Item key="filtered-json">Export as JSON</Menu.Item>
                                            </Menu>
                                        );

                                        return (
                                            <Space size="small">
                                                <Dropdown overlay={menu}>
                                                    <Button type="primary" ghost={true}>Download All</Button>
                                                </Dropdown>
                                                <Dropdown overlay={filteredMenu}>
                                                    <Button type="primary" ghost={true}>Download Filtered</Button>
                                                </Dropdown>
                                            </Space>
                                        );

                                    }}
                                />
                            </Card>



                        </>

                        :

                        <Card>

                            <Result
                                title="No Records Found"
                                extra={
                                    <Button type="primary" href="#/reports/subcontractor-report">
                                        Go Back
                                    </Button>
                                }
                                style={{ opacity: this.state.job?.name && this.state.production?.length > 0 ? 100 : 100 }}
                            />
                        </Card>
                    }


                </div>
            </>
        );
    }
}
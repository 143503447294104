import { Breadcrumb, Button, Card, Form, Input, InputNumber, Modal, Select, DatePicker, Badge, Tag, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Auth from '../../Auth';
import moment from 'moment';
const { Option } = Select;

export const FormProduction = ({ setLoading, location, prodId }) => {
    const uriParams = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [formLayout, setFormLayout] = useState('vertical');

    let jobIdQuery = new URLSearchParams(location.search).get('job_id');
    let prefillJobId = new URLSearchParams(location.search).get("jobId") ?? jobIdQuery;
    let prefillCostCodeId = new URLSearchParams(location.search).get("costCodeId");
    const prefillUserId = new URLSearchParams(location.search).get("userId");
    const prefillProducedAt = new URLSearchParams(location.search).get("producedAt");
    const prefillQuantity = new URLSearchParams(location.search).get("quantity");
    const prefillWorkType = new URLSearchParams(location.search).get("workType");
    const prefillDescription = new URLSearchParams(location.search).get("description");

    const [jobs, setJobs] = useState([]);
    const [jobId, setJobId] = useState();

    const isEditMode = (uriParams?.id != null && uriParams.id > 0) || (prodId != null && prodId > 0);
    const productionId = uriParams?.id ?? prodId;
    const [production, setProduction] = useState();

    const [costCodes, setCostCodes] = useState([]);
    const [costCodeId, setCostCodeId] = useState();

    const [userId, setUserId] = useState();
    const [users, setUsers] = useState([]);
    const [unit, setUnit] = useState("N/A");

    const onValuesChange = (changedValues) => {
        console.log(form.getFieldsValue())
    }

    const onJobSelect = (value) => {
        console.log("SELECTED JOB   ")
        const selectedJob = jobs.find(x => x.id == value)
        const costCodes = selectedJob?.costCodes?.filter(x => x.isToTrackProduction);
        const assignedUsers = selectedJob?.userAssignments?.map(x => { return { ...x } });
        setCostCodes(costCodes)
        setUsers(assignedUsers);

        // if (productionId) {
        //     // form.setFieldsValue({ costCodeId: prefillCostCodeId })
        //     return;
        // }

        form.setFieldsValue({ costCodeId: null })
        form.setFieldsValue({ userId: null })
    }

    const onUserSelect = (value) => {
        setUserId(value)
        form.setFieldsValue({ userId: value })
    }

    const onCostCodeSelect = (value) => {
        const selectedCostCode = costCodes.find(x => x.id == value)
        // console.log(selectedCostCode)
        setUnit(selectedCostCode?.unit)
        setCostCodeId(value)
        form.setFieldsValue({ costCodeId: value })
    }

    const onFinish = (values) => {
        values.userId = Auth.getCurrentUser().id;
        // values.costCodeId = costCodeId

        console.log(values)

        if (isEditMode) {
            fetch(`api/jobs/productions/${productionId}`, {
                method: 'PUT', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {

                    Modal.success({
                        content: 'Production has been updated successfully.',
                    });

                    // form.resetFields()
                    setLoading(false);
                });
            return;
        }

        // return
        fetch(`api/jobs/${values.jobId}/productions`, {
            method: 'POST', body: JSON.stringify(values), mode: 'cors', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {

                Modal.success({
                    content: 'Production has been added successfully.',
                });

                form.resetFields()
                setLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const fetchJobs = () => {
        setLoading(true)

        fetch(`/api/jobs?includeCostCodes=true&includeAssignedUsers=true&companyId=${Auth.getCurrentUser()?.companyId}`)
            .then((response) => response.json())
            .then((jobsList) => {
                setLoading(false)
                return jobsList
            })
            .then((jobsList) => {
                setJobs(jobsList)
            })
            .then((jobsList) => {

            })
            .catch(error => {
                setLoading(false);
            });

    }

    const fetchProductionById = (id) => {
        setLoading(true);
        fetch(`/api/jobs/productions/${id}`)
            .then((response) => response.json())
            .then((prod) => {
                setLoading(false)
                return prod
            })
            .then((prod) => {
                setProduction(prod)
                prod.producedAt = moment(prod.producedAt)

                form.setFieldsValue({
                    jobId: prod.jobId.toString(),
                    costCodeId: prod.costCodeId.toString(),
                    producedAt: prod.producedAt,
                    quantity: prod.quantity,
                    description: prod.description
                })

                setCostCodeId(prod.costCodeId)


                // form.setFields()
            })
            .catch(error => {
                setLoading(false);
            });
    }


    useEffect(() => {
        fetchJobs();


        if (jobIdQuery) {
            form.setFieldsValue({ jobId: jobIdQuery })
        }
    }, []);

    useEffect(() => {
        if (productionId && jobs.length > 0) {
            fetchProductionById(productionId)
        }
    }, [productionId, jobs, costCodes])



    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    return (
        <div>

            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>{isEditMode ? `Edit Production` : `Add Production Record`}</Breadcrumb.Item>
                {isEditMode && <Breadcrumb.Item>{productionId}</Breadcrumb.Item>}
            </Breadcrumb>

            <Card style={{ maxWidth: 500 }}>
                <Form
                    name='FormProduction'
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='on'
                    onValuesChange={onValuesChange}

                >
                    <Form.Item
                        label='Job'
                        name='jobId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        initialValue={jobId}
                        value={jobId}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Job"
                            optionFilterProp="children"
                            onChange={onJobSelect}
                            value={jobId}
                        >
                            {jobs?.map((job) => <Option value={job.id.toString()} key={job.id} > {job.name} - {job.description} </Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Cost Code'
                        name='costCodeId'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                        dependencies={["jobId"]}
                        shouldUpdate
                    // initialValue={costCodeId}
                    >
                        <Select
                            showSearch
                            placeholder="Select a Cost Code"
                            optionFilterProp="children"
                            onFocus={() => {
                                onJobSelect(form.getFieldValue("jobId"))
                            }}
                            onChange={onCostCodeSelect}
                            value={costCodeId}
                        >
                            {
                                costCodes?.length > 0 ?
                                    costCodes?.filter(x => x.isToTrackProduction).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>) :
                                    jobs.find(x => x.id == jobId)?.costCodes.filter(x => x.isToTrackProduction).map((c) => <Option value={c.id.toString()} key={c.id.toString()}> {c.name} - {c.description} </Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Production Date'
                        name='producedAt'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}>

                        <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={d => !d || d.isAfter(moment())}
                        />

                    </Form.Item>
                    <Form.Item
                        label={`Quantity (${unit})`}
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label='Comments'
                        name='description'
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div >
    );
};
import React from 'react';
import { NavLink } from "react-router-dom";
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Input, Space, Table, Descriptions, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import moment from 'moment'

export class ListJobCostCodes extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        costCodes: [],
        isLoaded: false,
        error: null
    };

    componentDidMount() {

    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="medium"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="medium" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        var jobCostCodeTotals = [];

        const columns = [
            {
                title: 'Cost Code',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                render: description => <span>{description?.length > 120 ? `${description.substr(0, 120)} ...` : description}</span>,
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                ...this.getColumnSearchProps('quantity'),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Unit',
                dataIndex: 'unit',
                key: 'unit',
                ...this.getColumnSearchProps('unit'),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: 'Is to track production?',
                dataIndex: 'isToTrackProduction',
                key: 'isToTrackProduction',
                ...this.getColumnSearchProps('isToTrackProduction'),
                sortDirections: ['descend', 'ascend'],
                render: i => i ? <CheckOutlined /> : null
            },
            {
                title: 'Is to track employee and equipment time?',
                dataIndex: 'isToTrackEmployeeAndEquipmentTime',
                key: 'isToTrackEmployeeAndEquipmentTime',
                ...this.getColumnSearchProps('isToTrackEmployeeAndEquipmentTime'),
                sortDirections: ['descend', 'ascend'],
                render: i => i ? <CheckOutlined /> : null

            },
            {
                title: 'Invoicing Information',
                dataIndex: 'invoicingInformation',
                key: 'invoicingInformation',
                ...this.getColumnSearchProps('invoicingInformation'),
                sortDirections: ['descend', 'ascend']
            },
        ];

        var result = [];
        var costCodes = this.props.costCodes?.length > 0 ? this.props.costCodes : [];
        costCodes.reduce(function (res, value) {
            if (!res[value.unit]) {
                res[value.unit] = { unit: value.unit, quantity: 0 };
                result.push(res[value.unit])
            }
            res[value.unit].quantity += value.quantity;
            return res;
        }, {});

        return (
            <>
                <Table rowKey={record => `job-${record.id}`} columns={columns} dataSource={this.props.costCodes} pagination={{ position: ["bottomLeft"] }} />
            </>
        );
    }
}
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom'
import { Affix, Button, Space, Dropdown, Menu, notification, Modal } from 'antd';
import { SwapOutlined, DeploymentUnitOutlined } from '@ant-design/icons';

const countDown = (secondsToGo, company) => {
    const modal = Modal.info({
        title: `Redirecting to ${company}`,
        content: `The page will reload after ${secondsToGo} second.`,
        maskClosable: false,
        closable: false,
        footer: null,
        keyboard: false,
        okButtonProps: { style: { display: 'none' } },
        footer: null
    });
    const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
            content: `The page will reload after ${secondsToGo} second.`,

        });
    }, 1000);
    setTimeout(() => {
        clearInterval(timer);
        window.location.reload();
        modal.destroy();
    }, secondsToGo * 1000);
}

const wait = async (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        title: "Success",
        message: message,
        description: description,
        placement: 'topRight',
        top: 50,
    });
};

export const ToggleCompany = (props) => {
    const history = useHistory();
    const [top, setTop] = useState(10);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(props.currentCompany);
    const [isLoading, setIsLoading] = useState(false);
    const [isToggling, setIsToggling] = useState(false);

    const fetchCompanies = () => {
        setIsLoading(true);
        fetch("api/companies")
            .then(response => response.json())
            .then((result) => {
                setCompanies(result.map(c => ({ ...c, key: c.id })));

                // console.log("Companies", result)
                const resetCompany = result.find(x => x.name == props.currentCompany);
                // console.log("Current Company", resetCompany)
                props.setCompany(resetCompany.name, resetCompany.id, resetCompany.logo);

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    const handleSwitchCompany = async (item) => {
        const company = companies.find(x => x.id == item.key)
        setIsLoading(true);
        setIsToggling(true);

        await wait(500)
        countDown(3, company.name)
        await wait(3000)
        props.setCompany(company.name, company.id, company.logo)
        setCompany(item.item.props.title)
        setIsToggling(false);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchCompanies();
        setCompany(props.currentCompany);
        console.log(props)
    }, []);

    const menu = () => {
        return (
            <Menu onClick={handleSwitchCompany}>
                {companies?.map(x => <Menu.Item key={x.id} title={x.name} disabled={x.name == props.currentCompany}> {x.name} </Menu.Item>)}
            </Menu >
        )
    };

    return (
        <Affix style={{ position: "absolute", right: 15 }} offsetTop={top}>
            <Dropdown placement="bottomLeft" arrow overlay={menu} trigger={['click']} onClick={fetchCompanies}>
                <Button type="warning" size="" loading={isLoading} shape="round" icon={<SwapOutlined />} >
                    {isToggling ? "Switching ..." : company}
                </Button>
            </Dropdown>
        </Affix>
    );
};


import React, { Component } from 'react';
import { Dashboard } from './components/Dashboard';
import { HashRouter } from 'react-router-dom'
import Auth from './components/Auth'
import 'antd/dist/antd.css';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {

        return (
            <HashRouter>
                <Dashboard />
            </HashRouter>
        );
    }
}
